﻿// RJ.OBJECT.MOBILE-TABS

.o-mobile-tabs {
	position: relative;
	background-color: $charcoal--dark;

	&::before {
		@include font-icon;
		content: "\e907";
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		color: $white;
	}
}

.o-mobile-tabs__select {
	width: 100%;
	padding: 15px;
	border: 0;
	border-radius: 0;
	outline: none;
	font-size: 15px;
	font-weight: 700;
	color: $white;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: transparent;
}

.o-mobile-tabs__option {
	color: $charcoal--dark;
}
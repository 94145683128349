@mixin experience-bg-object ($size, $color, $direction) {
	position: absolute;
	width: 0;
	height: 0;
	@if($direction == 'up') {
		border-bottom: $size solid $color;
	}
	@if($direction == 'down') {
		border-top: $size solid $color;
	}
	border-left: $size solid transparent;
	border-right: $size solid transparent;
}

.experience-icons {
	padding-top: 10px;
	display: block;
	@media ($tablet-plus) {
		position: relative;
		top: 4px;
		display: inline-block;
		padding-top: 0;
		margin-left: 25px;
	}
}

.experience {
	
	strong {
		font-weight: 600;
	}
	.experience--option {
		
		.experience--option-content {
			position: relative;
			z-index: 1;
			max-width: 100%;
			width: 100%;
		}
		.experience-date-form {
			border-top: 0px solid white;
		}
		.experience--date-form--show {
			display: inline-block;
			margin-top: 10px;
			@media ($all-mobile) {
				width: 100%;
			}
			.iconf {
				margin-left: 0;
				margin-right: 10px;
			}
		}
		.experience--date-form--hide {
			display: none;
			@media ($all-mobile) {
				width: 100%;
			}
		}
		&.is-closed {
			.experience--date-form--show {
				display: inline-block;
			}
			.experience--date-form--hide {
				display: none;
			}
			.experience-date-form {
				border-top: 0px solid white;
				height: 0;
				overflow: hidden;
			}
		}
		&.is-open {
			.experience--date-form--show {
				display: none;
			}
			.experience--date-form--hide {
				display: inline-block;
				margin-top: 10px;
			}
			.experience-date-form {
				border-top: 1px solid white;
			}
		}
	}
	.experience__detail-item {
		@extend .cf;
	}
	.experience__detail-label {
		float: left;
		width: 80px;
		overflow: hidden;
	}
	.experience__detail-value {
		float: left;
		width: calc(100% - 80px);
		padding-left: 5px;
		overflow: hidden;
	}
	.experience--notes {
		border-top: 2px solid white;
		padding: 10px 5px;
	}
	.experience-date-form {
		height: 0;
		overflow: hidden;
		.datepicker-wrapper {
			padding: 40px 0 20px;
		}
		.datepicker-confirm {
			position: relative;
			display: inline-block;
			margin: 0 auto 0 0;
			width: 100%;

			@media (min-width: 1400px) {
				width: calc(100% - 570px);
				min-width: 272px;
			}

			.datepicker-confirm__label {
				margin: 10px 0 10px;
				font-weight: 400;
			}

			.stepper {
				margin-top: 2px;
			}
		}
		.datepicker-wrapper {
			position: relative;
			.datepicker__calendar {
				@media ($small-mobile) {
					margin-right: 20px;
				}
				margin-bottom: 20px;
			}
		}
		.datepicker-input, .btn.btn-booking, .cancellable-input {
			width: 272px;
			max-width: 100%;
			@media ($all-mobile) {
				width: 100%;
			}
			@media ($desktop) {
				width: 100%;
				max-width: 300px;
			}
		}
		.datepicker-input {
			position: relative;
		}
		.btn.btn-booking {
			margin-top: 15px;
			display: block;
		}
	}
}
// RJ.OBJECT.SOCIAL-LINKS

.o-social-links {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 15px;

	@media ($tablet-plus) {
		justify-content: flex-start;
		margin-bottom: 22px;
	}
}

.o-social-links__item {
	display: block;
	padding: 8px;
}

.o-social-links__link {
	position: relative;
	display: block;
	width: 50px;
	height: 50px;
	border: 1px solid $charcoal--dark;
	border-radius: 50px;
	color: $charcoal--dark !important;
	background-color: $white !important;
	transition-property: background-color, color;
	transition-duration: 0.3s;
	transition-timing-function: ease;

	&:hover,
	&:focus-visible {
		color: $white !important;
		background-color: $charcoal--dark !important;
		text-decoration: none !important;
	}

	.o-social-links--dark & {
		border: 1px solid $white;
		color: $white;
		background-color: $charcoal--dark;

		&:hover,
		&:focus-visible {
			color: $charcoal--dark;
			background-color: $white;
		}
	}
}

.o-social-links__icon {
	position: absolute;
	left: 50%;
	top: 50%;
	display: block;
	font-size: 20px;
	transform: translate(-50%, -50%);

	&.iconf-line_xiaohongshu,
	&.iconf-line_youku,
	&.iconf-line_wechat,
	&.iconf-line_weibo {
		font-size: 32px;
	}
}

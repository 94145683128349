* {
	box-sizing: border-box;
}

@import 'base-min';

.pure-container {
	position: relative;
	width: 100%;
	margin: 0 auto;
	// 1310 - 50 == 1260px
	max-width: 1310px;
	padding: 0 15px;
	@media ($desktop) {
		max-width: 1400px;
		padding: 0 60px;
	}
}

.pure-flexi-container {
	max-width: 100%;
	position: relative;
	margin: 0 auto;
	&.c-experiences-details__promo-pods{
		margin-top: 20px;
	}
}

@import 'grids';
@import 'grids-responsive';
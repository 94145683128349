// RJ.CONTROL.REVIEW-CAROUSEL

.c-review-carousel {
	background: $grey--ultralight;
	border-top: 1px solid $grey--light;

	margin: 40px 0;
	@media ($desktop) {
		margin: 60px 0;
	}
}

.c-review-carousel__slides {
	.slick-arrow {
		bottom: 50%;
		transform: translateY(50%);

		background: $charcoal--dark;
		color: $white;
		box-shadow: inset 0 0 0 1px $charcoal--dark;

		&::before {
			@include font-icon;
			color: inherit;
		}

		&:hover,
		&:focus-visible {
			background: $white;
			color: $charcoal--dark;

			&::before {
				color: inherit;
			}
		}
	}
	.slick-prev:before {
		content: $iconf-arrows_left;
	}

	.slick-next:before {
		content: $iconf-arrows_right;
	}

	.slick-track {
		display: flex;
		align-items: center;
	}
}

.c-review-carousel__slide-wrapper {
	// Used to prevent Slick interfering directly with slide styles
}

.c-review-carousel__slide {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	padding: 40px 20px;
	@media($tablet) {
		padding: 40px 80px;
	}
	@media($desktop) {
		padding: 40px 160px;
	}

	a {
		color: $charcoal--dark;
		&:hover,
		&:focus-visible {
			&, .c-review-carousel__slide-title {
				color: $blue--lake;
			}
		}
	}
}

.c-review-carousel__slide-title {
	display: block;
	margin-bottom: 10px;

	font-size: 28px;
	line-height: 36px;
	font-weight: bold;
	color: $charcoal--dark;

	&::before,
	&::after {
		content: "";
	}
}

.c-review-carousel__slide-date {
	display: block;
	margin-bottom: 20px;

	font-size: 14px;
	line-height: 16px;
}

.c-review-carousel__slide-text {
	margin: 0;
}

.c-review-carousel__slide-cite {
	display: block;
	margin-top: 30px;

	font-size: 14px;
	line-height: 16px;
	text-style: normal;
	color: $charcoal--dark;

	a:hover &,
	a:focus-visible & {
		color: $blue--lake;
	}
}

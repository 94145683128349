﻿// RJ.COMPONENT.HOMEPAGE

.c-homepage__intro {
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
	padding-bottom: 40px;

	@media ($tablet-plus) {
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
	}
}
﻿// RJ.COMPONENT.MEGAMENU

$promo-width: 380px;

.c-megamenu {
	position: fixed;
	left: 100%;
	top: $rj-header-height--mobile;
	display: block;
	visibility: hidden;
	width: 100%;
	height: calc(100% - #{$rj-header-height--mobile});
	background-color: $charcoal--dark;
	transition: left 0.3s ease;

	@media ($desktop) {
		left: 0;
		top: $rj-header-height--desktop;
		width: 100%;
		height: 0;
		overflow-y: auto;
		background-color: $charcoal--dark;

		.is-animating & {
			overflow-y: hidden;
		}
	}

	@media ('min-width: 1500px') {
		max-width: 1460px;
		left: 50%;
		transform: translateX(-50%);
	}

	.c-navigation__item.active & {
		left: 0;
		z-index: 1;
		visibility: visible;

		@media ($desktop) {
			@include dropdown-shadow;
			height: auto;
		}

		@media ('min-width: 1500px') {
			left: 50%;
		}
	}
}

.c-megamenu__backdrop {
	display: none;
}

.c-megamenu__wrapper {
	height: 100%;

	@media($desktop) {
		display: flex;
	}
}

.c-megamenu__content {
	height: 100%;
	padding-left: 45px;
	overflow: auto;

	@media ($desktop) {
		flex: 1 1 auto;
		height: auto;
		overflow: unset;
		padding: 30px 66px 0;
	}
}

.c-megamenu__promo {
	display: none;

	@media ($desktop) {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 0 0 $promo-width;
	}
}

.c-megamenu__promo-figure {
	display: block;
}

.c-megamenu__promo-body {
	flex: 1 0 auto;
	padding: 20px 20px 30px;
}

.c-megamenu__promo-heading {
	margin: 0 0 10px;
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
}

.c-megamenu__promo-link {
	color: $white;
	transition: color 0.3s ease;
	&:hover,
	&:focus {
		color: $grey--dark;
	}
}

.c-megamenu__promo-text {
	margin: 0;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	color: $white;
}

.c-megamenu__close {
	@include btn-reset;
	position: absolute;
	top: 0;
	left: 0;
	width: 45px;
	height: 100%;
	background-color: $black;
	color: $white;
	cursor: pointer;

	@media ($desktop) {
		left: auto;
		right: 66px - 12px;
		top: 30px;
		width: 40px;
		height: 40px;
		color: $grey--dark;
		background-color: transparent;
		transition: transform 0.3s ease, color 0.3s ease;
		backface-visibility: hidden;

		&:hover {
			transform: scale(1.2);
			color: $white;
		}

		.c-megamenu__promo + & {
			right: $promo-width + 66px - 12px;
		}
	}
}

.c-megamenu__close-icon--mobile {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	@media ($desktop) {
		display: none;
	}
}

.c-megamenu__close-icon--desktop {
	display: none;

	@media ($desktop) {
		display: block;
	}
}

.c-megamenu__header {

	@media ($desktop) {
		padding-bottom: 15px;
		border-bottom: 1px solid $grey--dark;
		margin-bottom: 15px;
	}
}

.c-megamenu__title {
	padding: 20px 25px;
	margin: 0;
	font-size: 28px;
	font-weight: 600;
	line-height: 30px;
	background-color: $charcoal--dark;
	color: $white;

	@media ($desktop) {
		display: inline-block;
		padding: 0 30px 0 0;
		background-color: transparent;
	}
}

.c-megamenu__title-link {
	color: $white;
	transition: color 0.3s ease;

	@media ($desktop) {
		color: $white;
		&:hover,
		&:focus {
			color: $grey--dark;
		}
	}
}

.c-megamenu__view-all {
	display: none;

	@media ($desktop) {
		display: inline-block;
		font-size: 15px;
		font-weight: 400;
		color: $grey--dark;
		transition: color 0.3s ease;
		&:hover,
		&:focus {
			color: $white;
		}
	}
}

.c-megamenu__items {
	padding: 0;
	margin: 0;
	list-style: none;

	@media ($desktop) {
		margin-bottom: 15px;
		column-count: 3;
		column-gap: 40px;
		column-break-inside: avoid;
	}

	@media ($desktop-large) {
		column-count: 3;
	}
}

.c-megamenu__item {
	border-bottom: 1px solid $black;

	@media ($desktop) {
		display: inline-block;
		border: 0;
		width: 100%;
	}
}

.c-megamenu__link {
	position: relative;
	padding: 12px 25px;
	display: block;
	font-size: 20px;
	font-weight: 600;
	text-decoration: none;
	color: $white;
	transition: 0.5s ease;

	@media ($desktop) {
		position: relative;
		padding: 15px;
		padding-left: 0;
		font-size: 16px;
		font-weight: 700;
		color: $white;

		&:hover {
			padding-left: 15px;
			background-color: $white;
			color: $charcoal--dark;
		}
	}
}

.c-megamenu__link-icon {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 30px;

	@media ($desktop) {
		display: none;
	}
}


// MEGAMENU GROUPED

.c-megamenu-item-group {
	display: block;
	-webkit-column-break-inside: avoid;
	margin-bottom: 0;
	padding: 20px 25px;

	@media ($desktop) {
		padding: 20px 0 0;
	}

	&__heading {
		display: block;
		font-size: 20px;
		font-weight: 600;
		color: $white;
		margin-top: 0;

		a {
			color: inherit;
			transition: color 0.3s ease;

			&:hover,
			&:focus {
				color: $grey--dark;
			}
		}
	}


	&__link {
		padding: 0;
		color: $grey;
		font-weight: 400;
		padding-bottom: 10px;

		&:hover {
			padding-left: 0px;
			background-color: transparent;
			/*color: $charcoal--dark;*/
			color: $white;
		}
	}
}

@font-face{
    font-family:"Foundry Sterling";
    src:url("/Content/fonts/foundry-sterling/835a52cb-78e2-498c-bcc1-78b89c38fe9a.eot") format("eot"),url("/Content/fonts/foundry-sterling/63f537a7-b187-45bf-a51d-d8b5404db4f5.woff2") format("woff2"),url("/Content/fonts/foundry-sterling/a48c29e8-8de1-4e90-82a1-5e025a284ae5.woff") format("woff"),url("/Content/fonts/foundry-sterling/cde1f0bd-b628-489b-a95d-2048acf1460a.ttf") format("truetype"),url("/Content/fonts/foundry-sterling/c5b9070a-700b-425a-84a5-9fd846ba5455.svg#c5b9070a-700b-425a-84a5-9fd846ba5455") format("svg");
    font-weight: 300;
}
@font-face{
    font-family:"Foundry Sterling";
    src:url("/Content/fonts/foundry-sterling/0fa8c670-f412-45ca-849d-1aded30eb86a.eot") format("eot"),url("/Content/fonts/foundry-sterling/ddd70fca-9ea3-4a83-b6b1-127c7bd05a51.woff2") format("woff2"),url("/Content/fonts/foundry-sterling/1c874867-515c-4fdd-9fae-7c37237fba27.woff") format("woff"),url("/Content/fonts/foundry-sterling/a5c117b0-930c-40ac-b4b7-1fac7ea445f7.ttf") format("truetype"),url("/Content/fonts/foundry-sterling/7c3bed3a-232e-4f9b-9013-16f12661a829.svg#7c3bed3a-232e-4f9b-9013-16f12661a829") format("svg");
    font-weight: 400;
}
@font-face{
    font-family:"Foundry Sterling";
    src:url("/Content/fonts/foundry-sterling/5a9062d2-7473-4f10-98b2-f25e6a172b42.eot") format("eot"),url("/Content/fonts/foundry-sterling/eb632440-5644-4897-95ad-587df074297a.woff2") format("woff2"),url("/Content/fonts/foundry-sterling/c0f75adc-d683-440e-b3eb-639cbff32b68.woff") format("woff"),url("/Content/fonts/foundry-sterling/cc35a4ac-d402-4bc7-a6fb-425d5cd2d88b.ttf") format("truetype"),url("/Content/fonts/foundry-sterling/c007631e-0e12-4f23-8088-a7e5b0eee390.svg#c007631e-0e12-4f23-8088-a7e5b0eee390") format("svg");
    font-weight: 400;
    font-style: italic;
}
@font-face{
    font-family:"Foundry Sterling";
    src:url("/Content/fonts/foundry-sterling/8af41afe-9f54-4583-9674-bc1303e3ff13.eot") format("eot"),url("/Content/fonts/foundry-sterling/61a7e935-0abf-4866-8091-34c8aec18f22.woff2") format("woff2"),url("/Content/fonts/foundry-sterling/13371dca-3ac8-4bab-b52d-4e1d2efa82f9.woff") format("woff"),url("/Content/fonts/foundry-sterling/0d4f9295-fafe-4a02-ab19-b161494f81e2.ttf") format("truetype"),url("/Content/fonts/foundry-sterling/db2dcdc1-bbb3-414f-b352-c38fff8db0d7.svg#db2dcdc1-bbb3-414f-b352-c38fff8db0d7") format("svg");
    font-weight: 500;
}
@font-face{
    font-family:"Foundry Sterling";
    src:url("/Content/fonts/foundry-sterling/e01f138a-1763-438c-84cf-d2a76e04d585.eot") format("eot"),url("/Content/fonts/foundry-sterling/9c648acb-9d6f-40e8-a99a-70870397db1b.woff2") format("woff2"),url("/Content/fonts/foundry-sterling/6bfb67a6-ef4e-4695-bc49-ca3a1a18b123.woff") format("woff"),url("/Content/fonts/foundry-sterling/8f96c346-20cd-42fc-986c-6b18442fbacd.ttf") format("truetype"),url("/Content/fonts/foundry-sterling/3b4db725-1c63-4308-a090-dbb3a4d67d88.svg#3b4db725-1c63-4308-a090-dbb3a4d67d88") format("svg");
    font-weight: 600;
}
@font-face{
    font-family:"Foundry Sterling";
    src:url("/Content/fonts/foundry-sterling/c38e285c-f21e-4adc-92bd-8b51ea465101.eot") format("eot"),url("/Content/fonts/foundry-sterling/953e5222-1fc2-46ee-8323-b0284b544a94.woff2") format("woff2"),url("/Content/fonts/foundry-sterling/811fbecc-62c1-4b3d-8542-db71a661e012.woff") format("woff"),url("/Content/fonts/foundry-sterling/70189f1b-eed0-4625-992e-f4867c80eb5c.ttf") format("truetype"),url("/Content/fonts/foundry-sterling/babf4e66-b5fc-48af-a276-77c327d9ac9a.svg#babf4e66-b5fc-48af-a276-77c327d9ac9a") format("svg");
    font-weight: 700;
}
@font-face{
    font-family:"Foundry Sterling";
    src:url("/Content/fonts/foundry-sterling/d1b6ef84-2086-48ff-89e9-6bc45068b1b9.eot") format("eot"),url("/Content/fonts/foundry-sterling/48865427-8009-45d6-91a2-4b4549f8a43c.woff2") format("woff2"),url("/Content/fonts/foundry-sterling/6a5656a6-73b7-4f20-9315-fe9667ae30eb.woff") format("woff"),url("/Content/fonts/foundry-sterling/840c1ca0-935a-450c-832a-201acf85968b.ttf") format("truetype"),url("/Content/fonts/foundry-sterling/bf628534-499c-42d0-97cb-79cca30df525.svg#bf628534-499c-42d0-97cb-79cca30df525") format("svg");
    font-weight: 800;
}



// SITE-SPECIFIC TYPOGRAPHY

html {
	font-family: $font-family-sans;
	font-weight: 500;
	text-rendering: optimizeLegibility;
}

h1 {
	font-size: 52px;
	font-weight: 800;
	line-height: 107%;
	margin-top: 10px;
	margin-bottom: $base-spacing-unit;
}

h2 {
	@media ($all-mobile) {
		font-size: 24px;
	}

	font-size: 28px;
	line-height: 115%;
	margin-top: 20px;
	margin-bottom: $base-spacing-unit;
}

h3 {
	font-size: 22px;
	line-height: 26px;
	margin-top: 20px;
	margin-bottom: $base-spacing-unit;
}

h4 {
	font-size: 18px;
	line-height: 24px;
	margin-top: 20px;
	margin-bottom: $base-spacing-unit;
}

h5 {
	font-size: 16px;
	line-height: 21px;
	margin-top: 20px;
	margin-bottom: $base-spacing-unit;
}

h6 {
	font-size: 14px;
	line-height: 19px;
	margin-top: 10px;
	margin-bottom: $base-spacing-unit;
}

h2, h3, h4, h5, h6 {
	font-weight: 700;
}

.abstract,
%abstract {
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;

	p {
		font-size: 16px;
		line-height: 22px;
		font-weight: 500;
	}

	.c-rte-toggle__trigger {
		color: #356ea0;
	}
}

.c-rte-toggle__trigger {
	color: $blue--lake !important;
}

p {
	font-size: 16px;
	line-height: 21px;
	font-weight: 500;
	margin: 10px 0;
}

a {
	color: $blue--lake;
	text-decoration: none;
}

.pod .overlay {
	font-family: $font-family-sans;
}

.conservation {
	padding: 25px 0;

	@media ($large-mobile) {
		padding: 35px 0;
	}

	h2 {
		@media ($all-mobile) {
			font-size: 24px;
		}

		font-size: 28px;
		text-align: center;
		font-weight: 300;
		margin: 0;

		@media ($tablet-plus) {
			font-size: 40px;
			margin: 20px 0;
		}
	}
}

cite, var, address, dfn {
	font-style: normal;
}

// RTE Styles
.u-green,
.u-grey {
	font-weight: bold;
	font-size: 24px;
}

.u-grey {
	color: $grey--dark;
}

// RJ.CONTROL.FAREHARBOR-CTA

.c-fareharbor-cta {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 101;
	width: 100%;

	@media ($desktop) {
		position: static;
		padding-top: 20px;
	}
}

.c-fareharbor-cta__btn {
	display: block;
	max-width: 300px;
	padding: 16px 2em;
	border-radius: 3px 3px 0 0;
	margin: 0 auto;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	line-height: 1.2;
	background-color: $charcoal--dark;
	box-shadow: inset 0 0 0 2px $charcoal--dark;
	transition-duration: 0.3s;
	transition-timing-function: ease-in;
	transition-property: color background-color;

	&,
	.body--content & {
		color: $white;

		&:focus-visible,
		&:active,
		&:hover {
			color: $charcoal--dark;
			background-color: $white;
		}
	}

	@media ($desktop) {
		border-radius: 3px;
	}
}

.c-fareharbor-cta__icon {
	padding-right: 5px;
}
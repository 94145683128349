.c-modal {
	display: none;

	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: rgba($black, 0.6);
}

.c-modal__body-open {
	// To be placed on the <body> tag when a modal is open

	// Uncomment this rule to prevent background scrolling
	// while a modal window is open:
	// overflow: hidden;
}

.c-modal__body {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%) scaleZ(1);

	overflow: auto;

	width: 100%;
	height: 100%;
	padding: 40px;
	@media ($desktop) {
		width: auto;
		max-width: 80%;
		height: auto;
		max-height: 80%;
		padding: 40px 80px;
	}
	background: $white;
	box-shadow: 0 0 20px rgba($black, 0.4);
}

.c-modal__close {
	position: absolute;
	top: 5px;
	right: 5px;

	background: transparent;
	border: none;
	border-radius: 0;
	cursor: pointer;

	display: block;
	padding: 10px;
	font-size: 15px;
	line-height: 1;

	color: $black;
	&:hover,
	&:focus {
		color: $brand-primary;
	}

	&:before {
		@include font-icon;
		content: $iconf-close;
	}
}

.c-modal__dummy {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}

﻿// RJ.COMPONENT.NAVIGATION

.c-navigation {
	position: absolute;
	right: 25px;

	@media ($desktop) {
		position: static;
	}
}

.c-navigation__menu {
	position: fixed;
	left: 0;
	top: $rj-header-height--mobile;
	display: block;
	overflow: hidden;
	width: 100%;
	height: 0;
	background-color: $charcoal--dark;
	transition: height 0.3s ease;

	@media ($desktop) {
		position: relative;
		top: 0;
		overflow: visible;
		width: auto;
		height: auto;
		background-color: transparent;
	}

	&.active {
		height: calc(100% - #{$rj-header-height--mobile});
		padding-bottom: 50px;

		@media ($desktop) {
			height: auto;
			padding-bottom: 0;
		}
	}
}

.c-navigation__items {
	padding: 0;
	border-top: 1px solid $black;
	margin: 0;
	list-style: none;
	opacity: 0;
	transition: opacity 0.3s 0.2s ease;

	.c-navigation__menu.active & {
		opacity: 1;
	}

	@media ($desktop) {
		display: flex;
		border: none;
		opacity: 1;
		transition: none;
	}
}

.c-navigation__item {
	position: relative;
	display: block;
	padding: 0 0 0 30px;
	border-bottom: 1px solid $black;
	

	@media ($desktop) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 65px;
		padding: 0 20px 0;
		border: none;
		margin: 0;

		&::after {
			content: '';
			position: absolute;
			left: 20px;
			bottom: 0;
			width: calc(100% - 40px);
			height: 4px;
			background-color: transparent;
			transition: background-color 0.3s ease;
		}

		&.active::after {
			background-color: $grey--dark;
		}
	}
}

.c-navigation__link {
	position: relative;
	display: block;
	padding: 9px 54px 11px 0;
	font-size: 20px;
	font-weight: 800;
	color: $white;
	transition: color 0.3s ease;

	@media ($desktop) {
		padding: 0;
		font-size: 16px;
		font-weight: 900;
		line-height: 20px;
	}

	@media ('min-width: 1220px') {
		font-size: 16px;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus-visible {
		color: $grey--dark;

		@media ($desktop) {
			//color: $sie-brand-dark;
		}
	}
}

.c-navigation__link-icon {
	position: absolute;
	top: 50%;
	right: 17px;
	display: block;
	font-size: 20px;
	transform: translateY(-50%);

	@media ($desktop) {
		display: none;
	}

	.c-navigation__link:hover &,
	.c-navigation__link:active &,
	.c-navigation__link.active & {
		color: inherit;
	}
}

.c-navigation__footer-links {
	margin: 0;
	list-style: none;
	padding: 0;
	padding-top: 10px;

	@media ($desktop) {
		display: none;
	}
}

.c-navigation__footer-link {
	display: block;
	padding: 8px 20px 10px 30px;
	font-size: 16px;
	font-weight: 600;

	color: $white;
	transition: color 0.3s ease;
	&:hover,
	&:focus-visible {
		color: $grey--dark;
	}
}

.c-navigation__footer-link-icon {
	position: relative;
	top: -2px;
	margin-left: 8px;
	font-size: 9px;
}
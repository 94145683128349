// Utility class to show only in print document
.print-only { display: none; }

@media print {
	* {
		color: black !important;
		box-shadow:none !important;
		text-shadow: none !important;
		filter:none !important;
		-ms-filter: none !important;
	}
	.print-hide {
		display: none;
	}
	a,
	a:visited {
		text-decoration: underline;
	}
	a[href]:after {
		content: " (" attr(href) ")";
		color: Blue;
		font-size: 14px;
		font-weight: normal !important;
	}
	abbr[title]:after {
		content: " (" attr(title) ")";
	}
	// Don't show links for images, mailto, tel, or javascript/internal links
	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after,
	a[href^="/"]:after,
	a[href^="mailto"]:after,
	a[href^="tel"]:after {
		display: none !important;
	}
	a[href^="//"]:after {
		display: block;
	}
	pre {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	// h5bp.com/t
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	img {
		max-width: 100% !important;
	}
	@page {
		width: auto;
		margin: 0.5cm;
	}
	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}
	h2, h3 {

	}
	.print-only, {
		display: block !important;
	}

	.content {
		padding-top: 0 !important;
	}

	.header {
		position: relative;
		background: none !important;
		border: none !important;
		&.nav-up {
			margin-top: 70px;
			~ .content {
				margin-top: -70px;
			}
		}
		.brand a * {
			display: none !important;
		}
		.core {
			display: none !important;
		}
		.brand {
			background: none;
			width: 100%;
			&:before {
				display: none;
			}
			a {
				display: block;
				.print-logo {
					display: block !important;
					max-width: 400px !important;
					width: 400px !important;
					height: 70px;
				}
			}
			&:after {
				display: none;
			}
		}
	}

	.sidebar,
	.sidebar-promo-pods,
	.rotator,
	.rotator-large,
	.promo-pods,
	.tabs,
	.sub-navigation,
	.social-icons,
	.search__form,
	.share-wrapper,
	.filters,
	.social,
	.pagination {
		display: none !important;
	}

	.footer {
		.back-to-top {
			display: none !important;
		}
		.social-prompt {
			display: none !important;
		}
		.social {
			display: none !important;
		}
		.footer-core {
			display: none !important;
		}
	}

	.pods {
		.see-all {
			display: none;
		}
		.pod-small {
			width: 50%;
		}
	}
	.listing-image {
		width: 33.3333%;
	}
	.listing-content {
		width: 66.6665%;
		.btn {
			display: none;
		}
	}

	.title__rating {
		display: none;
	}
	.pod-widget {
		display: none;
	}

	// RJ-639 override
	#content {
		.pure-u-0,
		.pure-u-1,
		.pure-u-1-1,
		.pure-u-1-2,
		.pure-u-1-3,
		.pure-u-2-3,
		.pure-u-1-4,
		.pure-u-3-4,
		.pure-u-1-5,
		.pure-u-2-5,
		.pure-u-3-5,
		.pure-u-4-5,
		.pure-u-5-5,
		.pure-u-1-6,
		.pure-u-5-6,
		.pure-u-1-8,
		.pure-u-3-8,
		.pure-u-5-8,
		.pure-u-7-8,
		.pure-u-1-12,
		.pure-u-5-12,
		.pure-u-7-12,
		.pure-u-11-12,
		.pure-u-1-24,
		.pure-u-2-24,
		.pure-u-3-24,
		.pure-u-4-24,
		.pure-u-5-24,
		.pure-u-6-24,
		.pure-u-7-24,
		.pure-u-8-24,
		.pure-u-9-24,
		.pure-u-10-24,
		.pure-u-11-24,
		.pure-u-12-24,
		.pure-u-13-24,
		.pure-u-14-24,
		.pure-u-15-24,
		.pure-u-16-24,
		.pure-u-17-24,
		.pure-u-18-24,
		.pure-u-19-24,
		.pure-u-20-24,
		.pure-u-21-24,
		.pure-u-22-24,
		.pure-u-23-24,
		.pure-u-24-24 {
			display: block;
		}
	}
}

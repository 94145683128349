﻿// CAMPAIGN
.c-campaign {

	.body--container {
		padding-top: 40px;
	}

	a:hover {
		text-decoration: none;
	}

	.call-to-action__link {

		&,
		&:hover {
			color: $black;
		}
	}

	.content {
		padding-top: 0;
	}

	.footer {
		margin-top: 50px;
	}

	.c-campaign__header {
		width: 100%;
		padding: 10px 0;
		text-align: center;
		position: absolute;
		left: 0;
		top: 20px;
		z-index: 2;
		padding: 0;
		background-color: transparent;

		@media ($hero-large) {
			top: 0;
		}
	}

	.c-campaign__header-link {
		display: block;
		width: 80px;
		height: 80px;
		margin: 0;
		background: url('/Content/images/interface/logo/real-nz-circle-black.svg') no-repeat 0 0;
		background-size: contain;

		@media ($hero-large) {
			width: 160px;
			height: 160px;
			margin: 40px 0 0;
		}
	}
	// RJ.COMPONENT.HERO
	.c-hero__body {
		// Campaign override
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
	}

	.c-hero__subheading {
		// Campaign override
		padding-left: 0 !important;
	}

	.c-hero__heading {
		// Campaign override
		line-height: 1;
	}

	.c-hero__text {
		// Campaign override
		padding-left: 0 !important;
	}
	// CONTROL.EXPERIENCE-PODS
	.c-experience-pods {
		// Campaign override
		margin-bottom: 80px;
	}
	// RJ.CONTROL.CROWDRIFF
	.c-crowdriff__icon-wrap {
		display: none;
	}
	// CALL TO ACTION OVERRIDES
	.c-call-to-action {
		margin: 0;
	}
	// CAMPAIGN FOOTER
	.bottom-banner {
		width: 100%;
		height: 50px;
		background-color: $charcoal--dark;
		font-size: 14px;
		color: white;
		line-height: 50px;
		letter-spacing: normal;

		a {
			color: white;

			&:hover {
				text-decoration: underline;
			}
		}

		#copyright {
			padding-right: 15px;

			@media ($tablet-plus) {
				padding-right: 20px;
			}
		}

		.pure-container {
			padding: 0 0 0 15px;

			@media ($tablet-plus) {
				padding: 0 0 0 25px;
			}
		}
	}
}

.c-hero__campaign {
	.c-hero__video--mobile {
		display: none;
	}

	.c-hero__video--large {
		display: block;
	}
}
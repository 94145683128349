// RJ.OBJECT.FILTER

.o-filter__field {
	position: relative;
}

.o-filter__field-title {
	position: relative;
	display: block;
	width: 100%;
	padding: 0 5px;
	border-bottom: 1px solid $grey--ultralight;
	font-size: 20px;
	font-weight: 600;
	text-transform: none;
	line-height: 28px;
	color: $black;

	&:hover {
		background-color: transparent;
	}

	&::after {
		position: absolute;
		right: 10px;
		top: 50%;
		font-family: "font-icons" !important;
		font-size: 12px;
		content: '\e907';
		line-height: 1;
		transition: transform 0.4s ease;
		transform: translateY(-50%);

		.o-filter__field.active & {
			transform: translateY(-50%) rotate(180deg);
		}
	}
}

.o-filter__items {
	position: absolute;
	left: 20px;
	margin: 0;
	height: 0;
	overflow: hidden;
	display: block;

	.o-filter__field.active & {
		max-height: 450px;
		height: auto;
		width: calc(100% - 40px);
		display: block;
		z-index: 75;
		background-color: $white;
		box-shadow: 0px 4px 6px rgba(0,0,0,0.35);
		padding: 5px 0;
	}
}

.o-filter__item {
	position: relative;
	padding: 8px 5px 8px 46px;
	list-style: none;
	line-height: 18px;
}

.o-filter__checkbox {
	display: none;
}

.o-filter__item-label {
	display: block;
	vertical-align: middle;
	cursor: pointer;

	.o-filter__item:hover & {
		font-weight: 700;
	}

	.o-filter__checkbox:checked + * + & {
		font-weight: 700;
	}
}

.o-filter__fake-checkbox {
	position: absolute;
	left: 20px;
	top: 9px;
	width: 16px;
	height: 16px;
	display: inline-block;
	background-color: white;
	border-radius: 2px;
	border: 1px solid grey;
	cursor: pointer;

	.o-filter__item:hover & {
		border: 1px solid $blue--lake;
	}

	.o-filter__checkbox:checked + & {
		background-color: $blue--lake;
	}
}


$filter-bg: #f1f1f1;

.filters {
	position: relative;
	z-index: 2;

	.filter--field-checkboxes {

		@media ($tablet-plus) {
			border-right: 1px solid $grey;
		}

		ul {
			position: absolute;
			margin: 0;
			height: 0;
			overflow: hidden;
			display: block;

			li {
				position: relative;
				padding: 8px 5px 8px 46px;
				list-style: none;
				line-height: 18px;

				&:hover {

					.fake--checkbox {
						border: 1px solid $blue--lake;
					}
				}
			}
		}

		label {
			cursor: pointer;
		}

		.fake--checkbox {
			position: absolute;
			left: 20px;
			top: 9px;
			width: 16px;
			height: 16px;
			display: inline-block;
			background-color: white;
			border-radius: 2px;
			border: 1px solid grey;
		}

		.real--label {
			display: block;
			vertical-align: middle;

			&:hover {
				font-weight: 700;
			}
		}

		input {
			display: none;
		}

		input[type="checkbox"]:checked + .fake--checkbox {
			background-color: $blue--lake;
		}

		input[type="checkbox"]:checked + * + .real--label {
			font-weight: 700;
		}

		&.active {
			ul {
				position: absolute;
				max-height: 450px;
				height: auto;
				width: 100%;
				display: block;
				z-index: 75;
				background-color: #f1f1f1;
				box-shadow: 0px 4px 6px rgba(0,0,0,0.35);
				padding: 5px 0;
			}

			.filter--title {
				&:after {
					transform: rotate(180deg);
				}

				&:hover {
					background: transparent;
				}
			}
		}
	}

	.filter--status {
		margin: 0 0 15px;
		font-size: 14px;
	}

	.filter--status-list {
		.filter--list-item {
			position: relative;
			padding: 4px 20px 4px 8px;
			margin-left: 10px;
			margin-bottom: 10px;
			font-size: 12px;
			font-weight: 700;
			line-height: 16px;
			background-color: #f2f2f2;
			text-transform: capitalize;
			border-radius: 3px;
			white-space: nowrap;
			display: inline-block;

			&:hover {
				background-color: #d2d2d2;
				cursor: pointer;
			}

			.iconf {
				position: absolute;
				top: 5px;
				right: 5px;
				font-size: 6px;
				color: $blue--lake;
				font-weight: 700;
			}
		}
	}
}


// RJ.COMPONENT.FILTER

.c-filter__form-title {
	position: relative;
	width: 100%;
	padding: 17px 15px;
	border: 1px solid $charcoal--dark;
	margin: 15px 0;
	font-size: 15px;
	font-weight: 700;
	color: $white;
	background-color: $charcoal--dark;
	cursor: pointer;

	@media ($tablet-plus) {
		padding: 17px 15px;
		margin-bottom: 0;

		&:before{
			display: none;
		}
	}

	&:before{
		@include font-icon;
		content: $iconf-plus;
		position: absolute;
		right: 10px;
		top: 50%;
		font-size: 12px;
		transform: translateY(-50%);

		.c-filter.is-expanded &:before {
			content: $iconf-minus;
		}
	}

	.c-filter.is-expanded & {
		margin-bottom: 0;
		color: $charcoal--dark;
		background-color: $white;

		@media ($tablet-plus) {
			color: $white;
			background-color: $charcoal--dark;
		}
	}
}

.c-filter__form {
	overflow: hidden;
	width: 100%;
	height: 0;
	margin: 0;

	@media ($tablet-plus) {
		height: auto;
		overflow: visible;
		margin-bottom: 15px;
	}

	.c-filter.is-expanded & {
		overflow: visible;
		height: auto;
		margin-top: 0;
		margin-bottom: 15px;
	}
}

.c-filter__field {
	position: relative;
	//display: inline-block;
	padding: 13px 15px;
	border: 1px solid #8FA0A3;
	border-top: none;
	line-height: 50px;
	background-color: $white;
}

.c-filter__field-title {
	position: relative;
	display: block;
	width: 100%;
	padding: 0 5px;
	border-bottom: 1px solid $grey--ultralight;
	font-size: 20px;
	font-weight: 600;
	text-transform: none;
	line-height: 28px;
	color: $black;

	&:hover {
		background-color: transparent;
	}

	&::after {
		position: absolute;
		right: 10px;
		top: 50%;
		font-family: "font-icons" !important;
		font-size: 12px;
		content: '\e907';
		line-height: 1;
		transition: transform 0.4s ease;
		transform: translateY(-50%);

		.c-filter__field.active & {
			transform: translateY(-50%) rotate(180deg);
		}
	}
}

// Nesting required to override styles for existing filter which are remaining
.o-layout__filter {

	.c-filter__form-title {
		margin-top: 0;

		@media ($tablet-plus) {
			pointer-events: none;
		}
	}

	.c-filter__field {

		@media ($tablet-plus) {
			display: block;
			width: 100%;
		}
	}

	.c-filter__checkbox-list {
		position: absolute;
		left: 20px;
		margin: 0;
		height: 0;
		overflow: hidden;
		display: block;
	}

	.c-filter__field.active .c-filter__checkbox-list {
		max-height: 450px;
		height: auto;
		width: calc(100% - 40px);
		display: block;
		z-index: 75;
		background-color: $white;
		box-shadow: 0px 4px 4px rgba(0,0,0,0.35);
		padding: 5px 0;
	}
}

//this is a overwrite of the old filters(Destination Detail/GenericLanding)
.rj-filters {

	.c-filter__form-title {

		@media ($tablet-plus) {
			display: none;
		}
	}

	.c-filter.is-expanded .c-filter__form,
	.c-filter__form {

		@media ($tablet-plus) {
			margin-top: 15px;
		}
	}

	.c-filter__field {

		&:last-of-type {
			border-bottom: 1px solid #8FA0A3;
		}

		@media ($tablet-plus) {
			border: 1px solid #8FA0A3;
			border-right: none;

			&:nth-child(n+4) {
				border-top: none;
			}

			&:last-of-type,
			&:nth-of-type(3n) {
				border-right: 1px solid #8FA0A3;
			}
		}
	}

	.c-filter__checkbox-list {
		position: absolute;
		left: 20px;
		margin: 0;
		height: 0;
		overflow: hidden;
		display: block;
	}

	.c-filter__field.active .c-filter__checkbox-list {
		position: absolute;
		max-height: 450px;
		height: auto;
		width: calc(100% - 40px);
		display: block;
		z-index: 75;
		background-color: $white;
		box-shadow: 0px 4px 4px rgba(0,0,0,0.35);
		padding: 5px 0;
	}
}
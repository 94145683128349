.macro--align-left {
	float: left;
	margin: 0 35px 20px 0;

	@media ($tablet-plus) {
		margin-right: 35px;
	}
}

.macro--align-center {
	display: block;
	margin: 20px auto;
}

.macro--align-right {
	float: right;
	margin: 0 0 20px 35px;

	@media ($tablet-plus) {
		margin-left: 35px;
	}
}

%quote-generic {
	position: relative;
	padding: 30px;
	background: $charcoal--dark;
	color: $white;
	overflow: hidden;

	a {
		color: $grey--dark;
		position: relative;
		z-index: 1;

		&:hover .iconf {
			text-decoration: none;
		}
	}

	.quote__text {
		position: relative;
		z-index: 1;
		margin-top: 0;

		&:last-of-type {
			margin-bottom: 0;
		}

		font-size: 22px;
		font-weight: 800;
		line-height: 28px;

		&:before, &:after {
			content: '"';
		}
	}

	.iconf {
		font-size: 14px;
		margin-left: 5px;
		vertical-align: middle;
	}

	.quote__citation {
		font-size: 14px;
		font-weight: 700;
		font-style: normal;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
}

%caption-generic {
	margin: 0;
	font-size: 14px;
	text-align: left;
}

.macro--quote.quote-full-width {
	@extend %quote-generic;
	width: 100%;
}

.macro--quote.quote-half-width {
	@extend %quote-generic;
	width: 100%;

	@media ($tablet-plus) {
		width: 50%;
	}
}

.macro--map {
	display: block;

	iframe {
		border: 0;
		height: 100%;
		min-height: 300px;
		width: 100%;
	}
}

.macro--expandable {
	margin-top: 35px;

	&.macro--expandable__rte {
		margin-top: 20px;

		.expandable--toggle-more, &.is-expanded .expandable--toggle-less {
			margin-bottom: 0;
		}
	}

	.expandable--content {
		display: none;

		p:first-child {
			margin-top: 0;
		}
	}

	&.is-expanded {
		.expandable--content {
			display: block;
		}

		.expandable--toggle-more {
			display: none;
		}

		.expandable--toggle-less {
			margin: 0 0 10px;
			display: inline-block;
		}
	}

	.expandable--toggle {
		color: $blue--lake;
		font-weight: 700;
		cursor: pointer;
	}

	.expandable--toggle-more {
		margin: 0 0 10px;
		display: inline-block;
	}

	.expandable--toggle-less {
		display: none;
	}
}

.macro--related-links {
	margin: 35px 0;

	.related-content {
		list-style: none;
		margin: 0;
		padding: 0;

		.related-content--item {
			padding: 10px 20px;
			list-style: none;
			background-color: $charcoal--dark;
			box-shadow: inset 0 0 0 2px $charcoal--dark;
			color: $white;
			font-size: 16px;
			font-weight: 700;
			margin-top: 2px;
			text-decoration: none;
			transition: 0.3s ease;

			&:hover,
			&:focus-visible {
				background-color: $white;
				color: $charcoal--dark;

				a {
					color: $charcoal--dark;
				}
			}

			a {
				text-decoration: none;
				color: $white;
			}

			.iconf {
				font-size: 24px;
				vertical-align: middle;
				margin-right: 10px;
			}
		}

		.related-links--meta {
			font-weight: 400;
		}
	}

	.related-links--abstract {
		font-size: 16px;
		font-weight: 600;
	}

	.related-content--item {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
	}

	.related-links--icon-wrapper {
		display: inline-block;
		width: 40px;
		vertical-align: middle;

		span:before {
			vertical-align: middle;
		}
	}

	.related-content--item-wrapper {
		padding: 0;
	}

	.related-links--content-wrapper {
		display: inline-block;
		width: 80%;
		vertical-align: middle;
	}
}

.macro--image {
	text-align: center;

	&:hover {
		.lightbox-image {
			img {
				transform: scale(1.05);
			}
		}
	}

	.figure__container {
		position: relative;
		display: block;
		overflow: hidden;
		//padding: 0 14px;
	}

	.lightbox-image img {
		transition: 0.2s ease;
	}

	img {
		text-align: center;
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}

	.macro--caption {
		@extend %caption-generic;
	}

	.zoom {
		position: absolute;
		bottom: 20px;
		left: 20px;
		color: white;
		opacity: 0.75;
		transition: 0.3s ease;
	}

	&:hover {
		.zoom {
			opacity: 1;
		}
	}

	&.macro--image_mugshot {
		display: inline-block;
		width: 49%;
		vertical-align: top;
		padding-right: 20px;
		margin-bottom: 20px;

		@media ($tablet) {
			width: 32%;
		}

		@media ($desktop) {
			width: 19%;
		}
	}
}

.macro--video {
	position: relative;
	margin-bottom: 35px;

	a {
		height: 56.25%;
		display: block;
	}

	img {
		display: block;
		width: 100%;
	}

	.macro--container {
		position: relative;
	}

	.macro--video-icon {

		&:before,
		&:after {
			transition: 0.5s ease;
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			z-index: 1;
			opacity: 0.6;
		}

		&:before {
			width: 100px;
			height: 100px;
			background-color: rgba(0,0,0,0.75);
			border-radius: 100px;
		}

		&:after {
			margin-left: 5px;
			width: 0;
			height: 0;
			border-left: 40px solid rgba(255,255,255,1);
			border-bottom: 25px solid transparent;
			border-top: 25px solid transparent;
		}
	}

	&:hover, &:active, &:focus {
		.macro--video-icon:before,
		.macro--video-icon:after {
			opacity: 1;
		}
	}

	.macro--caption {
		@extend %caption-generic;
	}
}

.macro--gallery {
	margin-bottom: 35px;

	a {
		text-decoration: none;
	}

	img {
		width: 100%;
		display: inline-block;
	}

	.lightbox-image {
		position: relative;
		display: block;
		overflow: hidden;

		img {
			transition: 0.2s ease;
			-webkit-filter: blur(0px);
			-moz-filter: blur(0px);
			-ms-filter: blur(0px);
			filter: none;
		}
		// &:hover {
		// 	img {
		// 		transform: scale(1.05);
		// 	}
		// }
	}

	.first-image {
		position: relative;
		height: 0;
		padding-bottom: 56.25%;
		margin-bottom: 3px;

		.zoom {
			position: absolute;
			bottom: 20px;
			left: 20px;
			color: white;
			opacity: 0.75;
			transition: 0.3s ease;
			outline: 2px solid white;
			padding: 7px 15px;
		}

		&:hover {
			.zoom {
				opacity: 1;
				background-color: white;
				color: $black;
			}
		}
	}

	.macro--caption {
		@extend %caption-generic;
	}

	.gallery--thumbnails {
		padding: 0;
		margin: -2px;
	}

	.gallery-thumb {
		position: relative;
		list-style: none;
		overflow: hidden;
		padding: 3px 2px 2px;

		img {
			height: 100%;
			display: block;
			width: 100%;
		}
	}

	.visuallyhidden {
		display: none;
	}
}
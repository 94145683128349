.pagination--container {
	margin: 15px 0;
}

.results--showing {
	display: inline-block;
	padding: 0 0 20px;
	margin: 0;
	font-size: 14px;
	color: $grey--ultradark;

	p {
		@extend .results--showing;
	}

	&:last-child {
		border-bottom: 0;
	}
	&.no-results {
		margin-top: 15px;
		display: inline-block;
		p {
			display: inline-block;
		}
	}
}

.results--bottom {
	display: none;

	@media ($tablet-plus) {
		display: inline-block;
	}
}

.pagination {
	text-align: center;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	list-style: none;
	padding: 0;
	margin: 15px;
	@media ($tablet-plus) {
		margin: 0;
		float: right;
	}
	.pagination--previous {
		width: unset;
		@media ($all-mobile) {
			float: left;
			width: 50%;
		}
	}
	.pagination--next {
		padding-left: 3px;
		@media ($tablet-plus) {
			padding-left: 0;
		}
	}
	.pagination--previous, .pagination--next {
		a {
			padding: 0 15px; 
		}
	}
	li {
		margin-right: 0px;
		margin-bottom: 3px;
		padding: 0;
		display: inline-block;
		transition: 0.3s ease;
		height: 40px;
		line-height: 40px;
		a {
			width: 100%;
			color: $charcoal--dark;
			display: inline-block;
			text-decoration: none;
			transition: 0.3s ease;
			height: 40px;
			&:visited { 
				color: $charcoal--dark;
			}
		}
		&.is-selected, &:hover {
			a {
				background-color: $charcoal--dark;
				color: white;
			}
		}
	}
	.pagination--previous {
		background-color: $grey--ultralight;
	}
	.pagination--next {
		background-color: $grey--ultralight;
	}
	.pagination--number {
		width: 40px;
		height: 40px;
		line-height: 40px;
		background-color: $grey--ultralight;
	}
}

.desktop--pagination {
	display: none;
	@media ($tablet-plus) {
		display: block;
		width: 100%;
	}
}

.mobile--pagination {
	display: block;
	margin: 0 auto;
	text-align: center;
	@media ($tablet-plus) {
		display: none;
	}
	.pagination--select {
		padding: 10px 25px;
		background-color: $grey--ultralight;
		margin-left: 5px;
		margin-right: 5px;
		margin-bottom: 15px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 14px;
		color: $black;
	}
	.pagination--previous,
	.pagination--next {
		padding: 10px;
		width: 45px;
		line-height: 25px;
		text-align: center;
		background-color: $grey--ultralight;
		display: inline-block;
		text-decoration: none;
		transition: 0.3s ease;
		color: $charcoal--dark;
		.iconf {
			transition: 0.3s ease;
		}
		&:hover {
			background-color: $charcoal--dark;
			text-decoration: none;
			.iconf {
				color: white;
			}
		}
	}
	.pagination--select select {
		padding: 0 5px;
	}
}
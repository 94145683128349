﻿// Default Variables
// '\2022' outputs ascii character '•'

$z-slick-arrows: 2;
$slick-arrow-size: 30px;
$slick-arrow-color: $charcoal--dark;
$slick-arrow-color-on-hover: $white;
$slick-dot-size: 50px !default;
$slick-dot-character: '\2022';
$slick-dot-color: $grey--dark;
$slick-dot-color-active: $charcoal--dark;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 1;
$slick-opacity-disabled: 0;

// Slider
.slick-list {
	.slick-loading & {
		background: #fff;
	}
}

// Arrows
// These utilise the icon font
.slick-prev,
.slick-next {
	position: absolute;
	bottom: 0;
	z-index: $z-slick-arrows;
	display: block;
	padding: 10px;
	font-size: 0;
	border: none;
	outline: none;
	font-family: 'font-icons';
	color: transparent;
	cursor: pointer;

	background: $grey--ultralight;

	transition: all 0.3s;

	&:hover,
	&:focus {
		outline: none;
		color: transparent;
		background: $charcoal--dark;

		&:before {
			opacity: $slick-opacity-on-hover;
			color: $slick-arrow-color-on-hover;
		}
	}

	&.slick-disabled:before {
		opacity: $slick-opacity-disabled;
	}

	&:before {
		opacity: $slick-opacity-default;
		font-size: $slick-arrow-size;
		line-height: 1;
		color: $slick-arrow-color;
		transition: opacity 0.2s linear;
	}
}

.slick-prev {
	content: $iconf-arrows_left;
	left: 0;

	[dir="rtl"] & {
		content: $iconf-arrows_right;
		left: auto;
		right: 0;
	}
}

.slick-next {
	content: $iconf-arrows_right;
	right: 0;

	[dir="rtl"] & {
		content: $iconf-arrows_left;
		left: 0;
		right: auto;
	}
}

// Dots
.slick-dots {
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;

	li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: ($slick-arrow-size + 20px);
		padding: 0;
		margin: 0;
		cursor: pointer;

		button {
			display: block;
			height: ($slick-arrow-size + 20px);
			width: 20px;
			padding: 5px;
			border: 0;
			outline: none;
			font-size: 0px;
			line-height: 0px;
			color: transparent;
			cursor: pointer;
			background: transparent;

			&:hover,
			&:focus {
				outline: none;

				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}

			&:before {
				content: $slick-dot-character;
				position: absolute;
				top: 6px;
				left: 0;
				opacity: $slick-opacity-not-active;
				width: 20px;
				height: 20px;
				font-size: $slick-dot-size;
				text-align: center;
				line-height: 20px;
				color: $slick-dot-color;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transition: opacity 0.2s linear;
			}
		}

		&.slick-active button:before {
			opacity: $slick-opacity-default;
			color: $slick-dot-color-active;
		}
	}
}
// RJ.OBJECT.CAROUSEL

.o-carousel {

	.slick-arrow {
		box-shadow: inset 0 0 0 1px $charcoal--dark;
		background: $charcoal--dark;
		color: $white;
		&::before {
			@include font-icon;
			color: inherit;
		}

		&:hover,
		&:focus-visible {
			background: $white;
			color: $charcoal--dark;
			&::before {
				color: inherit;
			}
		}
	}

	.slick-prev:before {
		content: $iconf-arrows_left;
	}

	.slick-next:before {
		content: $iconf-arrows_right;
	}
}

// Pod Layout
.pods {
	padding-top: 10px;
	padding-bottom: 35px;

	@media ($desktop) {
		padding-bottom: 70px;
	}
}
.js-tab {

	.pods {
		padding: 20px 0 20px 0;
	}
}
.pod-row {
	width: 100%;
	height: auto;
	padding-top: 0;
}
.pods.filtered {
	padding-top: 0;
}
.pods.filtered .pod-wrapper {
	display: none;
}
.pod-wrapper.hide-pod {
	overflow: hidden;
	width: 0;
	max-height: 0;
	visibility: hidden;
	padding: 0;
	opacity: 0;
	transition: 1s ease opacity, 0.5s ease max-height;
}
.pod-wrapper.hide-pod.unhidden {
	max-height: none;
	visibility: visible;
	padding: 5px;
	opacity: 1;
}
.pod-row.pod-title {
	padding: 0 5px 5px;
	margin: 40px 0 0;

	.o-layout__pods-wrapper & {
		padding-top: 40px;
		margin-top: 0;

		.pod-title__text {
			margin-bottom: 0;
		}
	}

	.pod-btn {
		margin-top: 20px;

		@media ($large-mobile) {
			margin-top: 0;
			text-align: right;
		}

		.btn {
			background-color: transparent;
			border: 2px solid $blue;
			color: $blue;
			width: 100%;

			@media ($large-mobile) {
				width: auto;
			}

			&:hover, &:active {
				color: white;
				background-color: $blue;
			}
		}
	}
}
.pod-wrapper {
	padding: 5px;
	z-index: 1;
}

.pod {
	position: relative;
	vertical-align: middle;
	width: 100%;
	height: auto;
	margin: 0 auto;
	text-align: center;
	display: block;
	overflow: hidden;

	.price {
		display: inline-block;
		font-size: 14px;
		font-weight: 400;

		.amount {
			font-size: 18px;
			font-weight: 700;
			margin-left: 5px;
			line-height: 1;
		}
	}

	figure {
		position: relative;
		z-index: 20;
		margin: 0;
		height: 0;
		padding-bottom: 56.25%;
		overflow: hidden;
		box-shadow: 0px 2px 25px rgba(0,0,0,0.25);
		@media ($desktop) {
			z-index: 0;
			overflow: unset;
		}
	}

	.pod-img {
		text-align: center;
		width: 100%;
		transition: 0.35s ease;
	}

	.rte-content {
		padding: 30px;
		@media ($desktop) {
			padding: 20px;
		}
		@media ($desktop-large) {
			padding: 30px;
		}

		.btn {
			margin-top: 8px;
		}
	}

	.overlay {
		text-align: left;
		color: white;
		position: relative;
		width: 100%;
		min-height: 10%;
		max-height: 100%;
		background: rgba(55,55,55,1);
		transition: 0.5s ease;
		padding: 0 10px 0 25px;
		z-index: 99;
		text-decoration: none;
		display: block;

		@media ($large-mobile) {
			position: absolute;
			bottom: 0;
			left: 0;
			background: rgba(0,0,0,0.6);

			.description {
				max-height: 0;
				height: 0;
			}
		}

		@media($desktop) {

		}

		h3 {
			color: white;
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 700;
			line-height: 16px;
			margin: 0;

			@media($desktop) {
				font-size: 14px;
			}
		}
		h2 {
			color: white;
			font-weight: 400;
			font-size: 18px;
			margin: 0;

			@media ($desktop) {
				font-size: 22px;
			}
		}

		.info {
			width: 100%;
			margin: 10px 0;
			display: inline-block;
			vertical-align: middle;

			@media ($desktop) {
				margin: 15px 0;
			}
		}

		p {
			margin-top: 0;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: -95px;
			border-bottom: 100px solid rgba(255,255,255,0.035);
			border-left: 100px solid transparent;
			border-right: 100px solid transparent;
			@media ($desktop) {
				display: none;
			}
		}
		.titles, .pricing {
			padding: 0;
			transition: 0.5s ease;
		}
		.headers.has-icon {
			position: relative;
			padding-left: 35px;
		}
		.headers__icon {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			display: block;
			width: 25px;
			height: 25px;
			border: 1px solid white;
			border-radius: 50px;
			font-size: 18px;
			text-align: center;
			line-height: 25px;
			color: white;
			background-color: transparent;
		}
		.titles {
			display: inline-block;
			vertical-align: middle;
			text-shadow: 0 2px 3px rgba(0,0,0,0.5);
			@media ($desktop) {
				padding-right: 10px;
			}
			.headers {
				display: inline-block;
			}

		}
		.pricing {
			text-align: left;
			padding-top: 0;

			@media ($large-mobile) {
				display: none;
			}

			@media ($desktop) {
				text-align: right;
				padding-left: 10px;
			}
		}
		.description {
			display: none;
			@media ($desktop) {
				display: block;
				transition: 0.5s ease;
				font-size: 16px;
				line-height: 24px;
				font-weight: 400;
			}
		}
	}
	&:hover, &:active, &:focus {
		.pod-img {
			transform: scale(1.05);
		}
		.has-overlay & .pod-img {
			transform: none;
		}
		&.has-overlay .pod-img {
			transform: none;
		}

		.overlay {
			@media ($desktop) {
				min-height: 100%;
				.titles {
					padding-right: 10px;
				}
			}
		}
		&.no-hover {
			cursor: default;
			.overlay {
				min-height: 0;
			}
			.pod-img {
				transform: scale(1.0);
			}
		}

		&.social-pod {
			cursor: pointer;
			.overlay {
				min-height: 0;
			}
		}
	}
}

.pod-large {
	.pod {
		@media ($tablet-plus) {
			height: 0;
			padding-bottom: 56.25%;
		}
	}

	.overlay .pricing {
		@media ($desktop) {
			display: inline-block;
		}
	}
}

.pod-small {
	.pod {

		@media ($large-mobile) {
			figure {
				padding-bottom: 114.7%;
				.pod-img {
					transform: translateY(0);
				}
			}
		}
		.pod-img {
			@media ($desktop) {
				transform: translateY(0);
			}
		}
		.titles, .pricing {
			padding: 0;
		}
		.pricing {
			padding: 5px 0 0;
			text-align: left;
		}
		&:hover, &:active, &:focus {
			.titles {
				padding: 0;
			}
		}
		.description {
			line-height: 22px;
		}
	}

	.overlay .pricing {
		@media ($desktop-large) {
			display: inline-block;
		}
	}
}

.pod-rte .pod {

	@media($large-mobile) {
		padding-bottom: 114.7%;

		.rte-content {
			position: absolute;

			.sidebar-promo-pods & {
				position: static;
			}
		}
	}

	.rotator__promos &,
	.rotator--promos & {
		padding-bottom: 100%;

		.rte-content {
			position: absolute;
		}
	}
}

.pod__figure {

	.rotator__promos &,
	.rotator--promos & {
		padding-bottom: 100%;
	}
}

.pod-img--small {
	display: inline-block !important;

	@media($large-mobile) {
		display: none !important;
	}
}

.pod-img--large {
	display: none !important;

	@media($large-mobile) {
		display: inline-block !important;
	}
}

.pod__overlay {

	.rotator__promos &,
	.rotator--promos & {
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,0.6);
	}
}

.promo-pods__wrapper {
	width: 100%;

	.c-filtered-pods {
		margin: 40px 0 !important;
	}
}

.promo-pods__wrapper--light {
	padding-bottom: 30px;
	background: $white;

	@media ($desktop) {
		padding-bottom: 40px;
	}

	&:first-child {
		padding-top: 0;
	}

	.promo-pods__wrapper + & {
		margin-top: 30px;

		@media ($desktop) {
			margin-top: 40px;
		}
	}
}

.homepage-section .promo-pods__wrapper--light.last-control {
	padding-bottom: 0;
}

.promo-pods .pod-title {
	padding: 0;
	text-align: center;

	h2, h4 {
		margin: 0;
	}

	h2 {

		+ h4 {
			margin-top: 15px;
		}
	}
}


.pod-promo {
	&.pod-wrapper {
		padding: 0;
		z-index: 1;
		@media ($tablet-plus) {
			position: relative;
			transform: unset;
		}
		&.in-rotator {
			position: absolute;
		}
	}
	.pod {
		background-color: $white;
		color: white;
		width: 100%;
		height: auto;
		border: 0;
		position: relative;

		&.rte {
			height: auto;
			padding-bottom: 0;
			text-align: left;

			.rotator__promos &,
			.rotator--promos & {
				height: 0;
				padding-bottom: 100%;
			}

			.rte-content {
				.titles {
					padding-top: 0;
					margin-top: 0;
					margin-bottom: 0;
					h3 {
						font-size: 16px;
						line-height: 20px;
					}
					h2, h3 {
						margin: 0;
					}
					h2 {
						font-size: 24px;
						font-weight: 400;
						line-height: 22px;
						margin-bottom: 10px;
					}
				}
				ul {
					font-weight: 600;
					font-size: 16px;
					padding-left: 30px;
					@media ($desktop-large) {
						font-size: 18px;
					}
					li {
						position: relative;
						list-style: none;
						margin: 7px 0;
						line-height: 21px;
						&:before {
							// Checkmark
							content: '';
							position: absolute;
							left: -23px;
							top: 3px;
							width: 3px;
							height: 8px;
							border-bottom: 2px solid white;
							border-right: 2px solid white;
							transform: rotate(40deg);
						}
					}
				}
				.btn {
					// background-color: transparent;
					// border: 1px solid white;
					color: $white;
					&:hover,
					&:focus-visible {
					// 	background-color: white;
						color: $charcoal--dark;
					}
				}
			}
			.description {
				display: block;
			}
		}
		.pod-img {
			width: 100%;
		}
		.titles {
			margin-top: 10px;
			margin-bottom: 15px;
			font-size: 24px;
		}
		.description {
			ul {
				padding-left: 10px;
			}
		}
	}
	.pod-icon {
		position: absolute;
		top: 0;
		right: 0;
		border-top: 60px solid transparent;
		border-left: 60px solid transparent;
		color: $white;
		font-size: 15px;
		z-index: 30;
		font-family: "font-icons";
		&::before {
			position: absolute;
			top: -50px;
			right: 8px;
		}
	}
	.overlay {
		/*position: absolute;
		bottom: 0;
		z-index: 21;*/
		.overlay-wrapper {
			width: 100%;
		}
	}
}
.pod-social {
	.social-meta {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.social-body *:last-child {
		margin-bottom: 0;
	}

	.social-date {
		display: inline-block;
		font-size: 14px;
		font-weight: 700;
		margin-right: 20px;
	}

	.social-author {
		font-size: 14px;
		font-weight: 400;
	}

	.social-source {
		@extend .social-author;
		color: $green;

		&:hover {
			text-decoration: underline;
		}
	}

	.social-title {
		display: block;
	}
}

.pod-row {
	position: relative;
}

.pod-small.pod-promo {
	&.pod-wrapper {
		padding: 5px;
		&.hide-pod {
			padding: 0;
		}
	}
	.pod, figure {
		&.rte {
			@media ($desktop) {
				padding-bottom: 114.5%;
			}
		}
	}
}

.pod-widget.pod-promo {
	.pod {
		display: block;
		height: auto;
		padding-bottom: 0;
		cursor: auto;
		background-color: transparent;
		&:hover {
			height: auto;
			padding-bottom: 0;
		}
	}
}

// Pod themes
.pod-promo {
	.pod.rte {
		.pod-description a {
			color: $white !important;
			text-decoration: underline;
		}
		&.pod-theme-white {
			border: 3px solid $charcoal--dark;
			background-color: white;
			&, a {
				color: $charcoal--dark;
			}
			.pod-icon {
				color: inherit;
			}
			.rte-content {
				.btn {
					color: $charcoal--dark;
					border: 1px solid $charcoal--dark;
					&:hover {
						background-color: $charcoal--dark;
						color: white;
					}
				}
			}
		}
		&.pod-theme-charcoal-dark {
			background-color: $charcoal--dark;
		}
		&.pod-theme-green-glowworm {
			background-color: $green--glowworm;
		}
		&.pod-theme-blue-mountain {
			background-color: $blue--mountain;
		}
		&.pod-theme-blue-lake {
			background-color: $blue--lake;
		}
		&.pod-theme-blue-ocean {
			background-color: $blue--ocean;
		}
		&.pod-theme-blue {
			background-color: #01a9ce;
			.rte-content {
				.btn {
					&:hover {
						color: #01a9ce;
					}
				}
			}
		}
		&.pod-theme-blue-dark {
			background-color: #7a97b9;
			.rte-content {
				.btn {
					&:hover {
						color: #7a97b9;
					}
				}
			}
		}
		&.pod-theme-orange {
			background-color: #eb690b;
			.rte-content {
				.btn {
					&:hover {
						color: #eb690b;
					}
				}
			}
		}
		&.pod-theme-orange-light {
			background-color: #f6a800;
			&, a {
				color: $charcoal--dark;
			}
			.rte-content {
				.btn {
					border: 1px solid $black;
					color: $black;
					&:hover {
						background-color: $black;
						color: #f6a800;
					}
				}
			}
		}
		&.pod-theme-red-dark {
			background-color: #a8151f;
			.rte-content {
				.btn {
					&:hover {
						color: #a8151f;
					}
				}
			}
		}
		&.pod-theme-red {
			background-color: #dd2525;
			.rte-content {
				.btn {
					&:hover {
						color: #dd2525;
					}
				}
			}
		}
		&.pod-theme-cyan {
			background-color: #63b99e;
			.rte-content {
				.btn {
					&:hover {
						color: #63b99e;
					}
				}
			}
		}
		&.pod-theme-green {
			background-color: #c3d600;
			color: #333;
			.rte-content {
				.btn {
					border: 1px solid $black;
					color: $black;
					&:hover {
						background-color: $black;
						color: #c3d600;
					}
				}
			}
		}
	}
}

//Pod video popup
.mfp-video-pod{
	.mfp-iframe-scaler{
		position:relative;
		overflow: visible;
	}
}

//RJ-961 - ensure promos work at double width in pathway rows
.handle__wide-promos {
	.pod-promo.pod-wrapper {
		padding: 5px;
	}

	.pod-large .pod {

		@media ($tablet-plus) {
			height: 0;
		}
	}

	.pod-promo .pod.rte {
		height: 100%;
	}

	@media (min-width: 35.5em) {
		.pod-rte .pod .rte-content {
			position: static;
		}
	}

	.pod-small.pod-promo {
		.pod, figure {
			&.rte {
				@media ($desktop) {
					padding-bottom: 0;
				}
			}
		}
	}

	.pod-small {
		&:hover, &:active, &:focus {
			.pod-img {
				transform: scale(1.05);
			}

			.has-overlay & .pod-img {
				transform: none;
			}

			&.has-overlay .pod-img {
				transform: none;
			}
		}
	}
}

// RJ.OBJECT.PATHWAY-ROW
@use "sass:math";

.o-pathway-row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -20px;

	.c-experience-pods .o-control__header + & {
		margin-top: 0;
	}

	.c-experiences-details__promo-pods & {
		margin-top: 40px;
	}
}

.o-pathway-row__item {
	width: 100%;
	padding-bottom: 40px;
	padding-left: 20px;

	letter-spacing: normal;
	word-spacing: normal;
	text-rendering: normal;

	@media ('min-width: 560px') {
		width: 50%;
	}

	@media ($desktop) {
		width: percentage(math.div(1, 3));
	}
}

.filtered .o-pathway-row__item {
	@media ($desktop) {
		width: 50%;
	}
	@media ($desktop-large) {
		width: percentage(math.div(1, 3));
	}
}

.filtered > .o-pathway-row__item {
	display: none;
}

﻿// RJ.COMPONENT.HERO-ROTATOR

.c-hero-rotator {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: $white;
		display: block;
	}
}

.c-hero-rotator__item {
	position: relative;

	&:nth-child(n+2) {
		display: none;
	}
}

.c-hero-rotator__image {
	display: block;
	width: 100%;
	max-width: 100%;
}

// Important has to be used to override the JS rotator libray (Slick)
.c-hero-rotator__image--small {

	@media ($large-mobile) {
		display: none !important;
	}
}

.c-hero-rotator__image--large {
	display: none !important;

	@media ($large-mobile) {
		display: block !important;
	}
}

.c-hero-rotator__body {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	padding: 20px 10px;

	@media ($desktop) {
		padding-bottom: 40px;
	}

	.c-hero-rotator__item--right & {
		text-align: right;
	}
}

.c-hero-rotator__tag {
	position: static;
	margin-bottom: 10px;
}

.c-hero-rotator__subtitle,
.c-hero-rotator__title {
	width: 100%;

	@media ($desktop) {
		padding-right: 250px;

		.c-hero-rotator__item--right & {
			padding-right: 0;
			padding-left: 250px;
		}
	}
}

.c-hero-rotator__subtitle {
	display: inline-block;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	color: $white;
	text-shadow: 0 2px 4px rgba($black, 0.5);
}

.c-hero-rotator__title {
	display: block;
	font-size: 30px;
	font-weight: bold;
	text-shadow: 0 2px 4px rgba($black, 0.5);
	line-height: 32px;
	color: $white;

	@media ($desktop) {
		font-size: 68px;
		line-height: 68px;
	}
}

.c-hero-rotator__button {
	display: none;

	@media ($desktop) {
		display: inline-block;
		margin-top: 25px;
	}
}

.c-hero-rotator__caption {
	display: none;

	@media ($desktop) {
		position: absolute;
		right: 60px;
		bottom: 6px;
		display: block;
		max-width: 240px;
		padding-left: 20px;
		color: $white;
		font-size: 12px;
		text-shadow: 0 2px 4px rgba($black, 0.5);
		line-height: 14px;

		.c-hero-rotator__item--right & {
			text-align: left;
			left: 60px;
			right: auto;
		}
	}
}

.c-hero-rotator__caption-icon {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	display: block;
	font-size: 12px;
}

.c-hero-rotator__caption-link {
	color: $white;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-hero-rotator__items.slick-initialized {
	.c-hero-rotator__item {
		&:nth-child(n+2) {
			display: block;
		}
	}

	.slick-arrow {
		bottom: auto;
		top: 50%;
		transform: translateY(-50%);
		background: $charcoal--dark;
		box-shadow: inset 0 0 0 1px $charcoal--dark;
		color: $white;

		&::before {
			color: inherit;
		}

		&:hover,
		&:focus-visible {
			background: $white;
			color: $charcoal--dark;
		}
	}

	.slick-prev:before {
		@include font-icon;
		content: $iconf-arrows_left;
	}

	.slick-next:before {
		@include font-icon;
		content: $iconf-arrows_right;
	}
}

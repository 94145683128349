//BODY-TEXT SPECIFIC STYLES

.content.rotator-large--is-first {
	padding-top: 0;
}

section.title {
	h1 {
		.icon {
			width: 40px;
			height: 40px;
			border-radius: 50px;
			background-color: $brand-primary;
			text-align: center;
			line-height: 40px;
			font-size: 30px;
			display: inline-block;

			@media ($desktop) {
				width: 50px;
				height: 50px;
				line-height: 50px;
				font-size: 36px;
			}
		}
	}

	@media ($tablet-plus) {
		&.has-rating {
			position: relative;
			padding-right: 130px;
		}
	}

	form & {
		padding-bottom: 15px;
	}
}

.title__rating {
	padding-top: 10px;

	@media ($tablet-plus) {
		position: absolute;
		right: 0;
		top: 26px;
		padding: 0;
	}

	@media ($desktop) {
		top: 61px;
	}

	ul {
		display: none;
		margin: 0;
		padding: 0;
	}
}

.body--container {
	padding-top:20px;
	@media ($desktop) {
		padding-top: 20px;
	}
	&.has-sidebar {
		@media ($desktop) {
			padding-right: 50px;
		}
	}

}

hr {
	margin-top: 25px;
	margin-bottom: 35px;
	border: 0;
	border-bottom: 1px solid #999;
}

.homepage-section .abstract {
	font-size: 18px;
	line-height: 1.2;
	color: $black;

	@media ($desktop) {
		font-size: 28px;
	}
}

.body--content {
	> :first-child {
		margin-top: 0;
	}

	a {
		@include brand-link;
	}

	ul, ol {
		padding: 0;
		padding-left: 20px;

		li {
			line-height: 22px;
			padding: 3px 0 0;
			padding-left: 6px;
		}
	}

	ul.shuttlerock-gallery {
		margin-left: -6px;
		padding-left: 0;

		li {
			padding: 3px 0 0 6px;
		}
	}

	ol {
		padding-left: 10px;

		> li {
			margin-left: 8px;
		}

		> li > ol {
			list-style: lower-alpha;
		}
	}

	ol li:before {
		position: relative;
		font-weight: 700;
	}

	table {
		width: 100% !important;
		text-align: left;
		margin-bottom: 25px;

		thead {
			font-weight: 700;

			tr td {
				// vertical-align: top;
			}

			tr:first-child {
				background-color: $grey--ultradark;
				color: white;

				td {
					padding: 15px;
					text-align: left;
				}
			}
		}

		tbody {
			> tr:first-child {
				border-top: 5px solid $grey--ultradark;
			}

			> tr {
				border-top: 1px solid $grey--dark;

				td {
					padding: 15px;
					vertical-align: top;
				}
			}

			tr:last-child {
				border-bottom: 5px solid $grey--ultradark;
			}
		}
	}

	.social ul li {
		padding: 0;

		a:hover {
			background-color: $charcoal--dark;
		}
	}

	.body-text__large {
		font-weight: 700;
		font-size: 21px;
		line-height: 24px;
	}
}

.body--content.body--content__embedded > div.pure-u {
	display: flex;
	justify-content: center;
}

//.body--content.body--content__embedded iframe, // youtube iframe needs to be full width
.body--content.body--content__embedded form {
	max-width: 400px !important;
}

.sidebar {
	padding-top: 30px;

	@media($desktop) {
		padding-top: 0;
	}
}

.sub-navigation {
	.sidebar-controls {
		padding-left: 5px;
		font-size: 16px;
		line-height: 30px;
		text-align: center;
		a {
			color: $blue--lake;
			cursor: pointer;
			font-size: 14px;
			font-weight: 700;
		}
	}
}

.sidebar-promo-pods {
	max-width: 300px;
	margin: 0 auto;
	@media ($desktop) {
		max-width: 100%;
		margin-top: 45px;
		margin-bottom: 20px;
		.sidebar--booking & {
			margin-top: 45px;
		}
	}
	.pod-wrapper:first-child {
		margin-top: 0;
	}
	.pod-wrapper {
		padding: 0;
		margin-top: 35px;
	}
	.pod-promo {
		.pod {
			height: 0;
			padding-bottom: 100%;
			figure {
				padding-bottom: 100%;
			}
			.overlay {
				position: absolute;
				bottom: 0;
			}
			&:hover {
			}
		}
	}
}

.mfp-wrap {
	.mfp-content {
		position: unset;
		padding: 0 80px;
		@media ($tablet-plus) {
			padding: 0 80px;
		}
		@media ($desktop) {
			padding: 0 120px;
		}
		.mfp-figure {
			box-shadow: none;
			img.mfp-img {
				&:after {
					position: relative;
					display: inline-block;
					content: '';
					width: 100%;
					height: 100%;
					box-shadow: 0 0 10px rgba(0,0,0,0.7);
				}
			}
			&:after {
				background: none;
				box-shadow: none;
			}
		}
	}
	.mfp-image-holder {
		.mfp-close {
			right: 35px;
			top: 25px;
			&:hover {
				color: $white;
			}
			&::after {
				@include font-icon;
				content: $iconf-close;
				margin-left: 10px;
				font-size: 18px;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
	.mfp-bottom-bar {
		.mfp-title {
			position: relative;
			top: -60px;
			text-align: center;
			padding-right: 0;
			margin: 0 auto;
			width: 75%;
			@media ($large-mobile) {
				top: -30px;
			}
		}
		.mfp-counter {
			top: 0;
			right: 30px;
		}
	}
	.mfp-arrow-left {
		position: absolute;
		left: 10px;
		&:before {
			@include font-icon;
			content: $iconf-arrows_left;
		}
	}
	.mfp-arrow-right {
		position: absolute;
		right: 10px;
		&:before {
			@include font-icon;
			content: $iconf-arrows_right;
		}
	}
	.mfp-arrow {
		position: absolute;
		top: 10%;
		width: 60px;
		height: 80%;
		text-align: center;
		transform: none;
	}
	.mfp-arrow:before {
		position: absolute;
		top: 50%;
		left: 0;
		text-align: center;
		width: 100%;
		font-size: 38px;
		opacity: 0.65;
		transition: 0.2s ease;
	}
	.mfp-arrow:hover:before {
		color: $green;
		opacity: 1;
	}
	.mfp-img {
		padding: 90px 0 90px;
		@media ($large-mobile) {
			padding: 60px 0 60px;
		}
	}
}
.mfp-close-btn-in {
	.mfp-content {
		padding: 0;
	}
}

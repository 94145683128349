// CONTROL.EXPERIENCE-PODS

.c-experience-pods {

	@media ($tablet-plus) {
		margin-bottom: 60px;

		&.has-link {
			margin-bottom: 40px;
		}
	}
}

.c-experience-pods__link-wrapper {
	padding-top: 80px;
	text-align: center;
}

.c-experience-pods__link {
	font-size: 16px;
	font-weight: 600;

	color: $charcoal--dark;
	transition: color 0.3s ease;
	&:focus-visible,
	&:hover {
		color: $blue--lake;
	}
}

.c-experience-pods__link-icon {
	padding-left: 5px;
	font-size: 12px;
}

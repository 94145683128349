// RJ.CONTROL.AWARD

.c-awards {
	margin-bottom: 0;
}

.c-awards__list {
	@include list-reset;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.c-awards__item {
	flex-shrink: 0;

	padding: 10px;
	@media ($tablet-plus) {
		padding: 20px;
	}
}

.c-awards__link {
	display: block;
}

.c-awards__image {
	display: block;
	max-width: 100%;
}

//SITE-SPECIFIC HELPER CLASSES

.cf {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.clear {
	clear: both;
}

.visuallyhidden {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

.display-table {
	display: table;
	width: 100%;
	height: 100%;
}

.rel {
	position: relative;
}

.inline-block {
	display: inline-block !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.float--right {
	float: right;
}

// Javascript helpers
.js-hot {
	cursor: pointer;
}

.js .js-hidden {
	display: none;
}

.flush--top {
	margin-top: 0 !important;
}

.flush--bottom {
	margin-bottom: 0 !important;
}

.hard--top {
	padding-top: 0 !important;
}

.hard--left {
	padding-left: 0 !important;
}

.push--top {
	margin-top: 20px !important;
}

.push--bottom {
	margin-bottom: 20px !important;
}

.pure-u-md-plus-1-4 {
	@media screen and (min-width: 48.01em) {
		width: 25%;
	}
}

.border-bot-white {
	border-bottom: 1px solid #fff;
}

// Show and hide
.show {
	display: block !important;
}

.hide {
	display: none !important;
}

.c-rte-toggle__trigger {
	color: #356ea0 !important;
}

@media (min-width: 24em) {
	.show--xs {
		display: block !important;
	}

	.hide--xs {
		display: none !important;
	}
}

@media (min-width: 35.5em) {
	.show--sm {
		display: block !important;
	}

	.hide--sm {
		display: none !important;
	}
}

@media (min-width: 48em) {
	.show--md {
		display: block !important;
	}

	.hide--md {
		display: none !important;
	}
}

@media (min-width: 64em) {
	.show--lg {
		display: block !important;
	}

	.hide--lg {
		display: none !important;
	}
}

@media (min-width: 80em) {
	.show--xl {
		display: block !important;
	}

	.hide--xl {
		display: none !important;
	}
}

@media (min-width: 48em) {
	.show-inline-block--md {
		display: inline-block !important;
	}
}

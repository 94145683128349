﻿// RJ.COMPONENT.NOTICE

.c-notice {
	padding: 20px 0;
	background-color: $red;
	text-align: center;
}

.c-notice__text {
	color: $white;

	p,
	ol,
	ul {
		margin-top: 0;

		@media screen and (min-width: 768px) {
			font-size: 18px;
		}
	}

	ol,
	ul {
		list-style-position: inside;
	}

	a {
		color: inherit;
		text-decoration: underline;

		&:focus,
		&:hover {
			text-decoration: none;
		}
	}

	*:last-child {
		margin-bottom: 0;
	}
}
﻿// RJ.COMPONENT.HERO
@use "sass:math";

$hero-large: 'min-width: 700px';

.c-hero {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: percentage(math.div(250, 320));

		@media($hero-large) {
			padding-bottom: percentage(math.div(9, 16));
		}
	}
}

.c-hero-gallery__body {

	@media (-webkit-min-device-pixel-ratio: 1.5) {
		padding-bottom: 90px !important;
	}
}

.c-hero__video {
	position: absolute;
	left: 50%;
	top: 50%;
	display: block;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	transform: translate(-50%, -50%);
}

.c-hero__video--mobile {

	@media ($hero-large) {
		display: none;
	}
}

.c-hero__video--large {
	display: none;

	@media ($hero-large) {
		display: block;
	}
}

.c-hero__body {
	position: absolute;
	left: 0;
	top: 40px;
	width: 100%;
	color: $white;

	@media ($hero-large) {
		top: 50%;
		transform: translateY(-50%);
	}
}

.c-hero__subheading,
.c-hero__heading,
.c-hero__text {
	text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
}

.c-hero__subheading {
	padding-left: 23px;
	margin: 0;
	font-size: 20px;
	font-weight: 400;

	@media ($desktop) {
		padding-left: 40px;
		font-size: 36px;
	}

	@media ($desktop-large) {
		padding-left: 56px;
		font-size: 52px;
	}
}

.c-hero__heading {
	margin: 5px 0 0;
	font-size: 40px;
	font-weight: 700;

	@media ($desktop) {
		font-size: 70px;
	}

	@media ($desktop-large) {
		font-size: 100px;
	}
}

.c-hero__text {
	display: none;
	font-size: 20px;
	font-weight: 600;

	@media ($desktop) {
		display: block;
		padding-left: 40px;
		font-size: 20px;
		font-weight: 600;
	}

	@media ($desktop-large) {
		padding-left: 56px;
	}
}

.c-hero__arrow {
	display: none;

	@media($tablet-plus) {
		border: 0;
		background: none;
		position: absolute;
		left: 50%;
		bottom: 40px;
		display: block;
		font-size: 28px;
		color: $white;
		cursor: pointer;
		transform: translateX(-50%);
	}
}

/*------------------------------------*\
    $THEME
\*------------------------------------*/

//--- Breakpoints
$below-small-mobile: 'max-width: 23.99em';
$small-mobile: 'min-width: 24em';
$below-large-mobile: 'max-width: 35.49em';
$large-mobile: 'min-width: 35.5em';
$all-mobile: 'max-width: 47.99em';
// $tablet may look weird, but it does work! This is how we
// can include multiple @media queries in a single
// breakpoint
$tablet: 'min-width: 48em) and (max-width: 63.99em';
$tablet-plus: 'min-width: 48em';
$desktop: 'min-width: 64em';
$below-desktop: 'max-width: 63.99em';
$desktop-large: 'min-width: 80em';
$below-desktop-large: 'max-width: 79.99em';

//--- Spacing
$base-spacing-unit: 10px;
$large-spacing-unit: 75px;

//--- Colors
//--- Branding
$brand-primary: #C4D600;
$brand-secondary: #a7b600;
$brand-tertiary: #fff;

//--- Redesign Sept 2019
$rj-red: #cb0000;
$rj-navy: #191F3A;

//--- Careers Branding
$careers-brand-primary: #00a8d7;
$careers-brand-secondary: darken(#00a8d7, 10%);
$careers-brand-tertiary: #051b40;

//--- Core Colors

$white: #ffffff;
$offwhite: #f9f9f9;
$black: #333333;
$charcoal: #5c5c5c;

$grey--ultralight: #f2f2f2;
$grey--light: #e6e6e6;
$grey: #cccccc;
// $grey--dark: #999999; // <- replaced in September 2021 rebrand
$grey--ultradark: #666666;


$green--light: #d0de33;
$green: $brand-primary;
$green--dark: #bacb00;
$green--ultradark: $brand-secondary;

$red: #ff0000;
$red--pale: #e66363;
$pink: #f7e6e6;

$blue--ultralight: #d9f2f8;
$blue--light-mid: #8dd2e2;
$blue--light: #23add1;
$blue: #00a9ce;
$blue--dark: #007693;

$blue2--light: #aad2d8;
$blue2: #96c5cb;
$blue2--dark: #86bbc1;

$blue--destinations: #b3d1ff;
$blue--destinations-light: #a4dcdd;

$orange: #f29200;

////////////////////////////////////
// September 2021 rebrand colours //
////////////////////////////////////
$charcoal--dark: #1D1D1B;
$grey--dark: #9C9C9C;

$green--glowworm: #008085;

$blue--mountain: #335a76;
$blue--lake: #356ea0;
$blue--ocean: #314672;

//--- Fonts
//--- Font Families
$font-family-sans: "Foundry Sterling", "Open Sans", sans-serif;

/*-------------------------------------*\
	$MODULE COLOURS -
	NOTE: Variables use BEM style and should use the following convention:
	$module__subitem--modifier-property. Examples:
	$lightbox__overlay-background
	$button--active-color
\*------------------------------------*/

//--- Links
$link-color: $blue;
$link--visited-color: $blue;
$link--hover-color: $blue--dark;
$link--active-color: $blue;



// Icons
$icomoon-font-path: '../fonts/font-icons/fonts';
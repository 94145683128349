﻿// RJ.COMPONENT.FOOTER

.c-footer {
	position: relative;
	padding-top: 35px;
	margin-top: 50px;
	padding-bottom: 50px;
	background: $charcoal--dark;
	color: $white;

	a {
		color: inherit;
	}

	.show-mobile {
		@media ($desktop) {
			display: none;
		}
	}
}

.c-footer__wrapper {
	padding-right: 20px;
	padding-left: 20px;

	@media ($tablet-plus) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			"footer-logo footer-links" "footer-nav footer-nav" "footer-base footer-base";
		gap: 40px;
	}

	@media ($desktop) {
		grid-template-columns: 200px repeat(2, 1fr);
		grid-template-rows: 70px 1fr;
		grid-template-areas:
			"footer-logo footer-links footer-links" "footer-logo footer-nav footer-nav" "footer-logo footer-base footer-base";
	}
}



// LOGO

.c-footer-logo {
	grid-area: footer-logo;
	margin-bottom: 20px;

	@media ($tablet-plus) {
		margin-bottom: 0;
		margin-bottom: 0;
	}

	@media ($desktop) {
		grid-row: span 2;
	}

	&__image {
		display: block;
		width: 100%;
		max-width: 120px;

		@media ($tablet-plus) {
			max-width: 200px;
		}
	}
}



// Social and CTA
.c-footer-links {
	grid-area: footer-links;
	display: block;
	margin-bottom: 20px;

	@media ($desktop) {
		margin-bottom: 0;
		display: flex;
		align-items: center;
	}

	&__image {
		display: block;
		width: 100%;
		max-width: 120px;

		@media ($tablet-plus) {
			max-width: 200px;
		}
	}

	&__social {
		.c-footer__social-links {
			justify-content: flex-start;
			margin-bottom: 20px;

			@media ($tablet-plus) {
				justify-content: flex-end;
			}

			@media ($desktop) {
				margin-right: 40px;
				margin-bottom: 0;
			}
		}

		.c-footer__social-item {
			padding: 0 10px 0 0;
		}
	}

	&__cta {
		.c-footer-links__cta-btn {
			border: 1px solid $white;
			color: $white;
			transition: all 0.3s ease;

			&:hover,
			&:focus,
			&:active {
				background-color: $white;
				color: $charcoal--dark;
			}
		}
	}
}

// NAVIGATION
.c-footer-navigation {
	grid-area: footer-nav;
	border-top: 1px $white solid;
	width: calc(100% + 40px);
	margin-left: -20px;
	margin-bottom: 40px;

	@media ($tablet-plus) {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;
		border: 0;
		width: 100%;
		margin-left: 0;
	}

	&__list {
		border-bottom: 1px $white solid;
		padding: 0 20px;

		@media ($tablet-plus) {
			border: 0;
			flex: 1 1 auto;
			padding: 0;
		}

		@media ($desktop) {
			&:nth-of-type(2) {
				flex: 2 2 auto;
				column-count: 2;
			}
		}
	}

	&__heading {
		font-size: 16px;
		font-weight: 800;
		margin: 0;
		cursor: pointer;
		padding: 20px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		*[class^='iconf-'] {
			transition: transform 0.2s;

			@media ($tablet-plus) {
				display: none;
			}
		}

		&.is-open *[class^='iconf-'] {
			transform: rotate(180deg);
		}

		@media ($tablet-plus) {
			padding: 0 0 10px;
			pointer-events: none !important;
			cursor: default !important;
			column-span: all;
		}
	}

	&__column {
		display: none;

		@include list-reset;
		padding-bottom: 20px;

		@media ($tablet-plus) {
			display: block !important;
		}
	}

	&__link {
		display: inline-block;
		padding: 5px 0;
		font-size: 16px;
		color: $white;

		&:hover,
		&:focus-visible,
		&:active {
			text-decoration: underline;
		}
	}
}
// NAVIGATION - contact

.c-footer-navigation__contact {
	display: block;
	padding: 5px 0;
	font-size: 15px;
	font-weight: 700;
	color: $white;

	a {
		color: $white;

		&:hover,
		&:focus-visible,
		&:active {
			text-decoration: underline;
		}
	}
}

.c-footer-navigation__contact-primary {
	font-size: 18px;
	line-height: 21px;

	@media ($tablet-plus) {
		font-size: 20px;
	}
}

.c-footer-navigation__contact-note {
	font-weight: 400;
}
// BASE


.c-footer-base {
	grid-area: footer-base;
	font-size: 15px;
	font-weight: 400;

	&__copyright {
		margin-right: 30px;
	}

	&__top-link {
		position: absolute;
		left: 10px;
		bottom: 15px;
		padding-left: 30px;
		color: $white;
		vertical-align: middle;

		@media ($desktop) {
			display: none;
		}

		.top-link-icon {
			position: absolute;
			left: 0;
			top: 50%;
			font-size: 24px;
			transform: translateY(-50%);
		}
	}
}

@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?rl7f1u') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rl7f1u') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rl7f1u') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rl7f1u##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconf-"], [class*=" iconf-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconf-tiktok {
  &:before {
    content: $iconf-tiktok; 
  }
}
.iconf-cms_tiktok {
  &:before {
    content: $iconf-cms_tiktok; 
  }
}
.iconf-line_tiktok {
  &:before {
    content: $iconf-line_tiktok; 
  }
}
.iconf-Tiktok {
  &:before {
    content: $iconf-Tiktok; 
  }
}
.iconf-star_half {
  &:before {
    content: $iconf-star_half; 
  }
}
.iconf-arrows_up_SIL {
  &:before {
    content: $iconf-arrows_up_SIL; 
  }
}
.iconf-arrows_right_SIL {
  &:before {
    content: $iconf-arrows_right_SIL; 
  }
}
.iconf-arrows_left_SIL {
  &:before {
    content: $iconf-arrows_left_SIL; 
  }
}
.iconf-arrows_down_SIL {
  &:before {
    content: $iconf-arrows_down_SIL; 
  }
}
.iconf-arrows_up_SIE {
  &:before {
    content: $iconf-arrows_up_SIE; 
  }
}
.iconf-arrows_down_SIE {
  &:before {
    content: $iconf-arrows_down_SIE; 
  }
}
.iconf-arrows_left_SIE {
  &:before {
    content: $iconf-arrows_left_SIE; 
  }
}
.iconf-arrows_right_SIE {
  &:before {
    content: $iconf-arrows_right_SIE; 
  }
}
.iconf-cms_flickr {
  &:before {
    content: $iconf-cms_flickr; 
  }
}
.iconf-flickr {
  &:before {
    content: $iconf-flickr; 
  }
}
.iconf-line_facebook {
  &:before {
    content: $iconf-line_facebook; 
  }
}
.iconf-line_flickr {
  &:before {
    content: $iconf-line_flickr; 
  }
}
.iconf-line_googleplus {
  &:before {
    content: $iconf-line_googleplus; 
  }
}
.iconf-error {
  &:before {
    content: $iconf-error; 
  }
}
.iconf-line_pinterest {
  &:before {
    content: $iconf-line_pinterest; 
  }
}
.iconf-line_twitter {
  &:before {
    content: $iconf-line_twitter; 
  }
}
.iconf-line_youtube {
  &:before {
    content: $iconf-line_youtube; 
  }
}
.iconf-arrows_down {
  &:before {
    content: $iconf-arrows_down; 
  }
}
.iconf-arrows_left {
  &:before {
    content: $iconf-arrows_left; 
  }
}
.iconf-arrows_right {
  &:before {
    content: $iconf-arrows_right; 
  }
}
.iconf-arrows_up {
  &:before {
    content: $iconf-arrows_up; 
  }
}
.iconf-close {
  &:before {
    content: $iconf-close; 
  }
}
.iconf-cross {
  &:before {
    content: $iconf-cross; 
  }
}
.iconf-tick {
  &:before {
    content: $iconf-tick; 
  }
}
.iconf-cart {
  &:before {
    content: $iconf-cart; 
  }
}
.iconf-marker {
  &:before {
    content: $iconf-marker; 
  }
}
.iconf-search {
  &:before {
    content: $iconf-search; 
  }
}
.iconf-enlarge {
  &:before {
    content: $iconf-enlarge; 
  }
}
.iconf-calendar {
  &:before {
    content: $iconf-calendar; 
  }
}
.iconf-chat {
  &:before {
    content: $iconf-chat; 
  }
}
.iconf-delete {
  &:before {
    content: $iconf-delete; 
  }
}
.iconf-edit {
  &:before {
    content: $iconf-edit; 
  }
}
.iconf-download {
  &:before {
    content: $iconf-download; 
  }
}
.iconf-drivingDistance {
  &:before {
    content: $iconf-drivingDistance; 
  }
}
.iconf-info {
  &:before {
    content: $iconf-info; 
  }
}
.iconf-plus {
  &:before {
    content: $iconf-plus; 
  }
}
.iconf-minus {
  &:before {
    content: $iconf-minus; 
  }
}
.iconf-cms_image {
  &:before {
    content: $iconf-cms_image; 
  }
}
.iconf-image {
  &:before {
    content: $iconf-image; 
  }
}
.iconf-cms_photo {
  &:before {
    content: $iconf-cms_photo; 
  }
}
.iconf-photo {
  &:before {
    content: $iconf-photo; 
  }
}
.iconf-print {
  &:before {
    content: $iconf-print; 
  }
}
.iconf-share {
  &:before {
    content: $iconf-share; 
  }
}
.iconf-email {
  &:before {
    content: $iconf-email; 
  }
}
.iconf-speechBubble {
  &:before {
    content: $iconf-speechBubble; 
  }
}
.iconf-cms_star {
  &:before {
    content: $iconf-cms_star; 
  }
}
.iconf-star {
  &:before {
    content: $iconf-star; 
  }
}
.iconf-cms_tripAdvisor {
  &:before {
    content: $iconf-cms_tripAdvisor; 
  }
}
.iconf-tripAdvisor {
  &:before {
    content: $iconf-tripAdvisor; 
  }
}
.iconf-Tripadvisor_blank {
  &:before {
    content: $iconf-Tripadvisor_blank; 
  }
}
.iconf-Tripadvisor_full {
  &:before {
    content: $iconf-Tripadvisor_full; 
  }
}
.iconf-cms_twitter {
  &:before {
    content: $iconf-cms_twitter; 
  }
}
.iconf-twitter {
  &:before {
    content: $iconf-twitter; 
  }
}
.iconf-cms_facebook {
  &:before {
    content: $iconf-cms_facebook; 
  }
}
.iconf-facebook {
  &:before {
    content: $iconf-facebook; 
  }
}
.iconf-cms_googleplus {
  &:before {
    content: $iconf-cms_googleplus; 
  }
}
.iconf-googleplus {
  &:before {
    content: $iconf-googleplus; 
  }
}
.iconf-cms_pinterest {
  &:before {
    content: $iconf-cms_pinterest; 
  }
}
.iconf-pinterest {
  &:before {
    content: $iconf-pinterest; 
  }
}
.iconf-cms_youtube {
  &:before {
    content: $iconf-cms_youtube; 
  }
}
.iconf-youtube {
  &:before {
    content: $iconf-youtube; 
  }
}
.iconf-cms_blog {
  &:before {
    content: $iconf-cms_blog; 
  }
}
.iconf-blog {
  &:before {
    content: $iconf-blog; 
  }
}
.iconf-cms_linkedin {
  &:before {
    content: $iconf-cms_linkedin; 
  }
}
.iconf-linkedin {
  &:before {
    content: $iconf-linkedin; 
  }
}
.iconf-line_print {
  &:before {
    content: $iconf-line_print; 
  }
}
.iconf-cms_youku {
  &:before {
    content: $iconf-cms_youku; 
  }
}
.iconf-youku {
  &:before {
    content: $iconf-youku; 
  }
}
.iconf-line_youku {
  &:before {
    content: $iconf-line_youku; 
  }
}
.iconf-line_tumblr {
  &:before {
    content: $iconf-line_tumblr; 
  }
}
.iconf-cms_articCenter {
  &:before {
    content: $iconf-cms_articCenter; 
  }
}
.iconf-articCenter {
  &:before {
    content: $iconf-articCenter; 
  }
}
.iconf-cms_events {
  &:before {
    content: $iconf-cms_events; 
  }
}
.iconf-events {
  &:before {
    content: $iconf-events; 
  }
}
.iconf-cms_expeditions {
  &:before {
    content: $iconf-cms_expeditions; 
  }
}
.iconf-expeditions {
  &:before {
    content: $iconf-expeditions; 
  }
}
.iconf-line_linkedin {
  &:before {
    content: $iconf-line_linkedin; 
  }
}
.iconf-cms_rentalSkoter {
  &:before {
    content: $iconf-cms_rentalSkoter; 
  }
}
.iconf-rentalSkoter {
  &:before {
    content: $iconf-rentalSkoter; 
  }
}
.iconf-cms_rentalCycle {
  &:before {
    content: $iconf-cms_rentalCycle; 
  }
}
.iconf-rentalCycle {
  &:before {
    content: $iconf-rentalCycle; 
  }
}
.iconf-link {
  &:before {
    content: $iconf-link; 
  }
}
.iconf-cms_rentalCar {
  &:before {
    content: $iconf-cms_rentalCar; 
  }
}
.iconf-rentalCar {
  &:before {
    content: $iconf-rentalCar; 
  }
}
.iconf-rentals {
  &:before {
    content: $iconf-rentals; 
  }
}
.iconf-cms_OvernightCruise {
  &:before {
    content: $iconf-cms_OvernightCruise; 
  }
}
.iconf-OvernightCruise {
  &:before {
    content: $iconf-OvernightCruise; 
  }
}
.iconf-overnightCruise {
  &:before {
    content: $iconf-overnightCruise; 
  }
}
.iconf-cms_Cruise {
  &:before {
    content: $iconf-cms_Cruise; 
  }
}
.iconf-Cruise {
  &:before {
    content: $iconf-Cruise; 
  }
}
.iconf-cruise {
  &:before {
    content: $iconf-cruise; 
  }
}
.iconf-ferry {
  &:before {
    content: $iconf-ferry; 
  }
}
.iconf-cms_Coach {
  &:before {
    content: $iconf-cms_Coach; 
  }
}
.iconf-Coach {
  &:before {
    content: $iconf-Coach; 
  }
}
.iconf-coach {
  &:before {
    content: $iconf-coach; 
  }
}
.iconf-cms_HeliQ {
  &:before {
    content: $iconf-cms_HeliQ; 
  }
}
.iconf-HeliQ {
  &:before {
    content: $iconf-HeliQ; 
  }
}
.iconf-heli {
  &:before {
    content: $iconf-heli; 
  }
}
.iconf-cms_Flight {
  &:before {
    content: $iconf-cms_Flight; 
  }
}
.iconf-Flight {
  &:before {
    content: $iconf-Flight; 
  }
}
.iconf-flight {
  &:before {
    content: $iconf-flight; 
  }
}
.iconf-fly {
  &:before {
    content: $iconf-fly; 
  }
}
.iconf-plane {
  &:before {
    content: $iconf-plane; 
  }
}
.iconf-cms_GlowwormCaves {
  &:before {
    content: $iconf-cms_GlowwormCaves; 
  }
}
.iconf-GlowwormCaves {
  &:before {
    content: $iconf-GlowwormCaves; 
  }
}
.iconf-glowwornCaves {
  &:before {
    content: $iconf-glowwornCaves; 
  }
}
.iconf-caves {
  &:before {
    content: $iconf-caves; 
  }
}
.iconf-cms_Tour {
  &:before {
    content: $iconf-cms_Tour; 
  }
}
.iconf-Tour {
  &:before {
    content: $iconf-Tour; 
  }
}
.iconf-tour {
  &:before {
    content: $iconf-tour; 
  }
}
.iconf-cms_Dining {
  &:before {
    content: $iconf-cms_Dining; 
  }
}
.iconf-Dining {
  &:before {
    content: $iconf-Dining; 
  }
}
.iconf-dining {
  &:before {
    content: $iconf-dining; 
  }
}
.iconf-bbq {
  &:before {
    content: $iconf-bbq; 
  }
}
.iconf-picnic {
  &:before {
    content: $iconf-picnic; 
  }
}
.iconf-cms_Accommodation {
  &:before {
    content: $iconf-cms_Accommodation; 
  }
}
.iconf-Accommodation {
  &:before {
    content: $iconf-Accommodation; 
  }
}
.iconf-accommodation {
  &:before {
    content: $iconf-accommodation; 
  }
}
.iconf-cms_Rafting {
  &:before {
    content: $iconf-cms_Rafting; 
  }
}
.iconf-Rafting {
  &:before {
    content: $iconf-Rafting; 
  }
}
.iconf-rafting {
  &:before {
    content: $iconf-rafting; 
  }
}
.iconf-cms_Cycling {
  &:before {
    content: $iconf-cms_Cycling; 
  }
}
.iconf-Cycling {
  &:before {
    content: $iconf-Cycling; 
  }
}
.iconf-cycling {
  &:before {
    content: $iconf-cycling; 
  }
}
.iconf-cms_HorseTrek {
  &:before {
    content: $iconf-cms_HorseTrek; 
  }
}
.iconf-HorseTrek {
  &:before {
    content: $iconf-HorseTrek; 
  }
}
.iconf-horseTrek {
  &:before {
    content: $iconf-horseTrek; 
  }
}
.iconf-horse {
  &:before {
    content: $iconf-horse; 
  }
}
.iconf-horsetrek {
  &:before {
    content: $iconf-horsetrek; 
  }
}
.iconf-cms_DayWalk {
  &:before {
    content: $iconf-cms_DayWalk; 
  }
}
.iconf-DayWalk {
  &:before {
    content: $iconf-DayWalk; 
  }
}
.iconf-dayWalk {
  &:before {
    content: $iconf-dayWalk; 
  }
}
.iconf-walk {
  &:before {
    content: $iconf-walk; 
  }
}
.iconf-walks {
  &:before {
    content: $iconf-walks; 
  }
}
.iconf-cms_FarmTour {
  &:before {
    content: $iconf-cms_FarmTour; 
  }
}
.iconf-FarmTour {
  &:before {
    content: $iconf-FarmTour; 
  }
}
.iconf-farmTour {
  &:before {
    content: $iconf-farmTour; 
  }
}
.iconf-farmtour {
  &:before {
    content: $iconf-farmtour; 
  }
}
.iconf-cms_kayak {
  &:before {
    content: $iconf-cms_kayak; 
  }
}
.iconf-kayak {
  &:before {
    content: $iconf-kayak; 
  }
}
.iconf-kayaking {
  &:before {
    content: $iconf-kayaking; 
  }
}
.iconf-cms_weibo {
  &:before {
    content: $iconf-cms_weibo; 
  }
}
.iconf-weibo {
  &:before {
    content: $iconf-weibo; 
  }
}
.iconf-line_weibo {
  &:before {
    content: $iconf-line_weibo; 
  }
}
.iconf-cms_link-external {
  &:before {
    content: $iconf-cms_link-external; 
  }
}
.iconf-link-external {
  &:before {
    content: $iconf-link-external; 
  }
}
.iconf-cms_tumblr {
  &:before {
    content: $iconf-cms_tumblr; 
  }
}
.iconf-tumblr {
  &:before {
    content: $iconf-tumblr; 
  }
}
.iconf-line_wechat {
  &:before {
    content: $iconf-line_wechat; 
  }
}
.iconf-cms_wechat {
  &:before {
    content: $iconf-cms_wechat; 
  }
}
.iconf-wechat {
  &:before {
    content: $iconf-wechat; 
  }
}
.iconf-cms_instagram {
  &:before {
    content: $iconf-cms_instagram; 
  }
}
.iconf-line_instagram {
  &:before {
    content: $iconf-line_instagram; 
  }
}
.iconf-cms_bike {
  &:before {
    content: $iconf-cms_bike; 
  }
}
.iconf-bike {
  &:before {
    content: $iconf-bike; 
  }
}
.iconf-cms_kiwi {
  &:before {
    content: $iconf-cms_kiwi; 
  }
}
.iconf-kiwi {
  &:before {
    content: $iconf-kiwi; 
  }
}
.iconf-map {
  &:before {
    content: $iconf-map; 
  }
}
.iconf-phone {
  &:before {
    content: $iconf-phone; 
  }
}
.iconf-star1 {
  &:before {
    content: $iconf-star1; 
  }
}
.iconf-star-line {
  &:before {
    content: $iconf-star-line; 
  }
}
.iconf-cms_usp-clock {
  &:before {
    content: $iconf-cms_usp-clock; 
  }
}
.iconf-usp-clock {
  &:before {
    content: $iconf-usp-clock; 
  }
}
.iconf-cms_usp-Family {
  &:before {
    content: $iconf-cms_usp-Family; 
  }
}
.iconf-usp-Family {
  &:before {
    content: $iconf-usp-Family; 
  }
}
.iconf-cms_usp-romantic {
  &:before {
    content: $iconf-cms_usp-romantic; 
  }
}
.iconf-usp-romantic {
  &:before {
    content: $iconf-usp-romantic; 
  }
}
.iconf-cms_usp-Expert {
  &:before {
    content: $iconf-cms_usp-Expert; 
  }
}
.iconf-usp-Expert {
  &:before {
    content: $iconf-usp-Expert; 
  }
}
.iconf-cms_usp-SSL {
  &:before {
    content: $iconf-cms_usp-SSL; 
  }
}
.iconf-usp-SSL {
  &:before {
    content: $iconf-usp-SSL; 
  }
}
.iconf-cms_usp-help {
  &:before {
    content: $iconf-cms_usp-help; 
  }
}
.iconf-usp-help {
  &:before {
    content: $iconf-usp-help; 
  }
}
.iconf-Flag_zh-cn {
  &:before {
    content: $iconf-Flag_zh-cn; 
  }
}
.iconf-Flag_jp {
  &:before {
    content: $iconf-Flag_jp; 
  }
}
.iconf-Flag_en {
  &:before {
    content: $iconf-Flag_en; 
  }
}
.iconf-cms_best-price {
  &:before {
    content: $iconf-cms_best-price; 
  }
}
.iconf-best-price {
  &:before {
    content: $iconf-best-price; 
  }
}
.iconf-cms_reservations {
  &:before {
    content: $iconf-cms_reservations; 
  }
}
.iconf-reservations {
  &:before {
    content: $iconf-reservations; 
  }
}
.iconf-cms_lock {
  &:before {
    content: $iconf-cms_lock; 
  }
}
.iconf-lock {
  &:before {
    content: $iconf-lock; 
  }
}
.iconf-cms_familyOwned {
  &:before {
    content: $iconf-cms_familyOwned; 
  }
}
.iconf-familyOwned {
  &:before {
    content: $iconf-familyOwned; 
  }
}
.iconf-cms_awards {
  &:before {
    content: $iconf-cms_awards; 
  }
}
.iconf-awards {
  &:before {
    content: $iconf-awards; 
  }
}
.iconf-cms_refund {
  &:before {
    content: $iconf-cms_refund; 
  }
}
.iconf-refund {
  &:before {
    content: $iconf-refund; 
  }
}
.iconf-cms_pioneers {
  &:before {
    content: $iconf-cms_pioneers; 
  }
}
.iconf-pioneers {
  &:before {
    content: $iconf-pioneers; 
  }
}
.iconf-cms_xiaohongshu {
  &:before {
    content: $iconf-cms_xiaohongshu; 
  }
}
.iconf-Xiaohongshu {
  &:before {
    content: $iconf-Xiaohongshu; 
  }
}
.iconf-line_xiaohongshu {
  &:before {
    content: $iconf-line_xiaohongshu; 
  }
}


//SITE-SPECIFIC FORM STYLES

.form-fields {
	background: $grey--ultralight;
	border-bottom: 4px solid $grey--ultradark;

	padding: 30px 30px;

	.error, .error-text {
		color: $red;
	}
	label.error, label.error-text {
		margin-bottom: 5px;
	}

	&.has-badge {
		padding-top: 75px;
	}
}
.error-text {
	color: $red;
}

.form__field {
	@extend .cf;

	margin-top: 15px;
	&:first-child {
		margin-top: 0;
	}

	label {
		display: block;
	}

	input, select, textarea {
		display: block;
		width: 100%;
		border: 1px solid $grey;
		margin: 5px 0;
		padding: 5px 13px;

		&[disabled] {
			background-color: $offwhite;
		}

		&:invalid {
			box-shadow: none;
		}
	}

	input[type=number] {
		-moz-appearance: textfield;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input, select {
		height: 40px;
	}

	textarea {
		padding: 13px;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		&::-ms-expand {
			display: none;
		}

		background: $white url(/Content/images/interface/ico/ico-arrow-down.png) no-repeat right 10px center;
		padding-right: 30px;
	}

	&.has-error, .has-error {
		input, select, textarea {
			border-color: $red;
		}
	}
	input, select, textarea {
		&.error, &.error-text {
			border-color: $red;
		}
	}
}

.form__field--disabled {
	&, label, .required-indicator {
		color: $grey--dark;
	}
}

.form-fields--tight {
	padding: 5px 30px 30px;
}

.form__fields--inline {
	margin-left: -15px;
	.form__field {
		padding-left: 15px;
	}
}

.form__field-separator {
	height: 40px;
	line-height: 40px;
	text-align: center;
	padding: 0 10px;
}

.input--hidden {
	// Hide, but still allow access through tab
	position: absolute;
	border: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
	padding: 0;
	height: 1px;
	width: 1px;
	margin: -1px;
}

.fake-radio__wrapper {
	@extend .cf;
	position: relative;
	margin-top: 6px;
	margin-bottom: 5px;
	margin-left: 1px;
}
.fake-radio {
	float: left;
	margin-top: -1px;
	margin-left: -1px;

	.fake-radio__radio {
		@extend .input--hidden;

		// Would use + but that gets broken by JS validation
		~ .fake-radio__label {
			transition: 0.15s ease;

			margin: 0;
			cursor: pointer;

			background: $white;
			color: $grey--dark;
			border: 1px solid $grey;

			display: block;
			padding: 10px;
			text-align: center;
			line-height: 20px;
			height: 40px;
			width: 100%;
		}

		&:checked ~ .fake-radio__label {
			background: $blue;
			color: $white;
			border-color: $blue;

			// Inset shadow on top only
			box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
		}

		&:active, &:focus {
			~ .fake-radio__label {
				background: $blue--dark;
				color: $blue--ultralight;
			}
		}

		&.error, &.error-text {
			~ .fake-radio__label {
				border-color: $red;
			}

			~ .error, ~ .error-text {
				position: absolute;
				bottom: 0;
				min-width: 200px;
			}
		}
	}

	&.fake-radio--has-error .fake-radio__radio {
		&.error, &.error-text {
			~ .fake-radio__label {
				margin-bottom: 32px;
			}
		}
	}
}

.fake-checkbox {
	position: relative;
	margin-top: 20px;

	.fake-checkbox__checkbox {
		@extend .input--hidden;

		~ .fake-checkbox__label {
			transition: 0.15s ease;

			margin: 0 5px -5px;
			cursor: pointer;

			background: $white;
			color: $grey;
			border: 1px solid $grey;

			.has-error & {
				border-color: $red;
			}

			padding: 0;
			text-align: center;
			height: 20px;
			width: 20px;
			font-size: 18px;
			line-height: 20px;
			overflow: hidden;

			&:before {
				@include font-icon;
				content: $iconf-tick;

				transition: 0.15s ease;
				color: $black;
				opacity: 0;

				.has-error & {
					color: $red;
				}
			}

			&:hover:before {
				opacity: 0.2;
			}
		}
		&:focus ~ .fake-checkbox__label:before {
			opacity: 0.2;
		}

		&:checked ~ .fake-checkbox__label {
			&:before {
				opacity: 1;
			}
		}
	}
}

.form__combo {
	@extend .cf;
	position: relative;

	.form__combo-value {
		position: relative;
		float: left;
		width: calc(100% - 70px);

		&.error, &.error-text, &:active, &:focus {
			// Create separate stacking context so this element (and its outline) appears on top
			position: relative;
			z-index: 1;
		}

		&.error-text {
			margin-bottom: 32px;
		}

		~ .error, ~ .error-text {
			position: absolute;
			top: 50px;
		}
	}
	.form__combo-unit {
		&, &.error {
			// Override error styles from elsewhere
			position: relative;
			top: auto;
		}
		&:active, &:focus {
			position: relative;
		}

		float: left;
		width: 71px;
		margin-left: -1px;
	}
}

.form__error {
	@media ($tablet-plus) {
		padding: 5px 0 5px 30px;
	}

	color: $red;
}

.required-indicator {
	color: $red;
}

////////////////////////
// CREDIT CARD FIELDS //
////////////////////////

.card-type__list {
	@extend .cf;
	position: relative;
	list-style: none;
	margin: 20px 0 0 -10px;
	@media ($desktop) {
		margin: 20px 0 0 -20px;
	}
	padding: 0;
}

.card-type__list-item {
	float: left;
	padding-bottom: 10px;
	margin-left: 10px;
	width: calc(20% - 10px);
	@media ($desktop) {
		margin-left: 20px;
		width: calc(20% - 20px);
	}
	max-width: 85px;

	&.has-tooltip {
		&:focus {
			outline: none;
		}

		.touch &:active,
		.touch &:focus,
		.no-touch &:hover {
			.card-type__tooltip-arrow {
				position: relative;
				&:after {
					content: "";
					margin-left: calc(10% - 10px);
					@media ($desktop) {
						margin-left: calc(10% - 20px);
					}

					@include triangle(10px, $black);
					left: 50%;
					top: -10px;
				}
			}
		}
	}
}

@supports (-ms-ime-align:auto) { //hack for IE edge RJ-766
	.card-type__list-item {
		&.has-tooltip:hover:after {
			margin-left: -40px;
			@media ($desktop) {
				margin-left: -50px;
			}
		}
	}
}
 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { //hack for IE 11 RJ-766
	.card-type__list-item {
		&.has-tooltip:hover:after {
			margin-left: -40px;
			@media ($desktop) {
				margin-left: -50px;
			}
		}
	}
}

.card-type__image {
	height: 35px;
	@media ($large-mobile) {
		height: 55px;
	}
	width: 100%;
	background: $white;
	cursor: pointer;

	filter: grayscale(100%);
	transition: filter 0.3s;
	border: 2px solid $white;
	&.is-selected {
		filter: none;
		border-color: $green;
	}
}

.card-type__tooltip {
	display: none;

	color: $white;
	background: $black;

	.no-touch .card-type__list-item:hover &,
	.touch .card-type__list-item:active &,
	.touch .card-type__list-item:focus & {
		display: block;

		position: absolute;
		left: 20px;
		right: 0;
		top: 100%;
		margin-top: -10px;
		padding: 7px 20px;
	}
}

.cancellable-input {
	display: inline-block;
	position: relative;
	width: 272px;
	max-width: 100%;
}

.cancellable-input__btn {
	position: absolute;

	right: 5px;
	top: 50%;
	margin-top: -20px;

	width: 40px;
	height: 40px;
	background: $white;

	font-size: 12px;
	line-height: 1.2;
	text-align: center;
	padding: 3px;

	border: 1px solid $blue;

	cursor: pointer;

	.datepicker-input--check-out ~ &,
	.datepicker-btn--check-out ~ & {
		color: $orange;
		border-color: $orange;
	}

	&:hover {
		text-decoration: none;
		color: $grey--dark;
		border-color: $grey--dark;
	}

	&:before {
		content: "OR";

		position: absolute;
		right: 100%;
		padding-right: 10px;
		top: 50%;
		margin-top: -10px;
		line-height: 20px;

		color: $grey--dark;
		font-size: 14px;
		font-weight: normal;
	}
}

#payment-form {
	margin-top: 15px;
}

.ssl-logo-wrapper {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
}
// RJ.CONTROL.ACCORDION

.c-accordion__container {
	position: relative;
	width: 100%;
	margin: 0 auto;
	// 1310 - 50 == 1260px
	max-width: 1310px;
	padding: 0 15px;
	letter-spacing: normal;

	@media ($desktop) {
		max-width: 1400px;
		padding: 0 60px;
	}

	+ .c-accordion__container {
		margin-top: -81px;
	}
}

.c-accordion-wrap {
	margin-top: 40px;
	margin-bottom: 40px;

	&:last-child {
		margin-bottom: 40px;
	}

	& + & {
		margin-top: -40px;
		margin-bottom: 40px;

		.c-accordion {
			border-top-color: transparent;
		}
	}
}

.c-accordion {
	color: $black;
	border-top: 1px solid $charcoal--dark;
	border-bottom: 1px solid $charcoal--dark;
	cursor: pointer;
	position: relative;

	&.c-expandable {
		border-bottom: 1px solid $charcoal--dark;
		margin-top: 0;

		.expandable-wrapper {
			&:hover,
			&:focus-visible {
				background-color: $white;

				span, .expandable-title {
					&, p, h3 {
						color: $charcoal--dark;
					}
				}
			}
		}

		&.is-expanded {
			.expandable-arrow {
				transform: rotate(0);
				color: $black;

				.c-accordion__icon::before {
					content: "\e91a";
				}
			}
		}
	}

	& + & {
		border-top: 0;
	}
}

.c-accordion__wrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	width: 100%;
	font-size: 22px;
	font-weight: 700;
	background-color: $white;
	transition: 0.3s ease;
	cursor: pointer;
}

.c-expandable-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9;
}

.c-accordion__title {
	width: 90%;
	display: flex;
	padding: 20px 0;
	position: relative;


	&,
	p,
	h3 {
		margin: 0;
		font-size: 18px;
		font-weight: 700;
		line-height: 20px;
		color: $charcoal--dark;
		transition: 0.3s ease;

		@media ($tablet-plus) {
			font-size: 22px;
			line-height: 24px;
		}
	}
}

.c-accordion__arrow {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	text-decoration: none;
	white-space: no-wrap;
	transition: 0.3s ease;
}

.c-accordion__icon {
	position: absolute;
	left: 50%;
	top: 50%;
	letter-spacing: normal;
	transform: translate(-50%, -50%);
	color: $charcoal--dark;
}

.c-accordion__content-inner {
	.c-expandable.is-expanded &.expandable-content-inner {
		padding-top: 0;
	}

	> p {
		margin-top: 0;
	}

	.c-filtered-pods {
		margin-top: 20px;
	}
}

// RJ.CONTROL.CONTENT-HIGHLIGHTS
@use "sass:math";

.c-content-highlights {

	.slick-arrow {

		@media ($tablet-plus) {
			top: 50%;
			bottom: auto;
			transform: translateY(-50%);
		}
	}

	.slick-prev {
		left: 0;
		&:before {
			@include font-icon;
			content: $iconf-arrows_left;
		}
	}

	.slick-next {
		right: 0;

		&:before {
			@include font-icon;
			content: $iconf-arrows_right;
		}
	}
}

.c-content-highlights__item {
	display: flex;
	flex-direction: column;

	@media ($tablet-plus) {
		flex-direction: row;
	}
}

.c-content-highlights__figure {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: percentage(math.div(9, 16));
	}
}

.c-content-highlights__figure--small {
	width: 100%;

	&:before {
		padding-bottom: percentage(math.div(9, 16));
	}

	@media ($tablet-plus) {
		display: none;
	}
}

.c-content-highlights__figure--large {
	display: none;

	@media ($tablet-plus) {
		width: 50%;
		display: block;

		&:before {
			padding-bottom: 75%;
		}
	}
}

.c-content-highlights__body {
	padding: 40px;
	text-align: center;
	color: $black;
	background: $grey--ultralight;

	@media ($tablet-plus) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		min-height: 380px;
		width: 50%;
		padding: 20px 70px 20px 40px;
		text-align: left;
	}

	@media ($desktop) {
		padding: 40px 70px 40px 60px;
	}
}

.c-content-highlights__heading {
	position: relative;
	display: block;
	width: 100%;
	padding-bottom: 30px;
	margin-top: 0;
	margin-bottom: 30px;
	font-size: 20px;
	font-weight: 600;
	line-height: 26px;

	@media ($desktop) {
		font-size: 32px;
		line-height: 38px;
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 60px;
		border-bottom: 1px solid $grey--dark;
		transform: translateX(-50%);

		@media ($tablet-plus) {
			left: 0;
			transform: none;
		}
	}
}

.c-content-highlights__text {
	display: none;

	@media ($tablet-plus) {
		display: block;
		width: 100%;
		margin: 0 0 30px;
		font-size: 16px;
		line-height: 26px;
	}
}


.c-content-highlights__item--dark {
	.c-content-highlights__body {
		background: $charcoal--dark;
		color: $white;
	}
}

@media print {
	.c-content-highlights__item--dark {
		.c-content-highlights__body {
			background-color: $grey--ultralight;
		}
	}
}

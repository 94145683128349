.imglib-container {
}

.imglib-wrapper {
	transform: none;
	opacity: 1;
	transition: 0.5s ease;
}

.img-confirm {
	margin: 20px 0;

	.results--showing {
		display: none;
	}
}

.imglib-container, .img-confirm {

	.imglib-confirm-title {
		padding-top: 20px;
		border-top: 1px solid #ccc;
		margin: 0;
	}

	.listings-wrapper {
		padding: 10px 0;
	}
	.results--showing {
		letter-spacing: 0;
	}
	.imglib-entry-wrapper {
		padding: 10px;
		figure {
			margin: 0;
		}
	}

	.img-added {
		.btn-add {
			display: none;
		}
		.btn-remove {
			display: inline-block;
		}
	}

	img {
		max-width: 100%;
	}

	.imglib-meta {
		padding: 5px 20px 15px;
		h3 {
			margin: 0;
		}
	}

	.imglib-title {
		color: $charcoal--dark;
	}

	.imglib-file {
		margin-top: 10px;
		font-size: 14px;
		color: $grey--ultradark;
	}
	.imglib-controls {
		margin-top: 10px;
	}

	.btn-remove {
		display: none;
		padding-left: 14px;
		padding-right: 14px;
		outline: 2px solid $grey;
		background-color: white;
		color: $grey--dark;
	}
	.added {
		opacity: 0;
		float: right;
		color: $grey--ultradark;
		text-align: right;
		vertical-align: middle;
		font-size: 14px;
		font-weight: 700;
		line-height: 50px;
		text-transform: uppercase;
		transition: 0.3s ease;
	}

	.img-added {
		.added {
			opacity: 1;
		}
	}

	.imglib-entry {
		background-color: $grey--ultralight;
	}
}

.image-library {
	.selection-widget {
		position: relative;
		display: block;
		background-color: $grey--ultralight;
		padding: 15px 25px;
		overflow: hidden;
		color: $black;
		h2 {
			font-weight: 700;
			font-size: 26px;
			color: $black;
			margin-bottom: 5px;
		}
		.counter-text {
			font-weight: 400;
			font-size: 18px;
			margin-bottom: 25px;
			display: block;
		}
		.counter {
			font-weight: 700;
		}
		&.no-selection {
			display: none;
		}

		.selection-widget-info {
			position: relative;
			z-index: 1;
		}
	}
}

.form-active {

	.imglib-wrapper {
		display: none;
	}

	.imglib-form-wrapper {
		display: block;
	}

	.listings-wrapper {
		padding-top: 0;
		border-top: none;
	}
}

.imglib-form-wrapper {
	display: none;

	.gallery-return {
		position: relative;
		display: inline-block;
		padding-left: 24px;
		margin: 20px 0;
		font-size: 14px;
		font-weight: 800;
		line-height: 1;
		cursor: pointer;

		color: $charcoal--dark;
		&:hover,
		&:focus-visible {
			color: $blue--lake;
		}

		.iconf {
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.imglib-form-title {
		font-size: 60px;
		font-weight: 400;
		line-height: 64px;
		margin-top: 10px;
		margin-bottom: $base-spacing-unit * 1.5;
	}
	.imglib-form-meta {
		* {
			display: inline-block;
			vertical-align: middle;
		}
		.required {
			float: right;
			margin-top: 25px;
			font-weight: 700;
		}
	}
}
.imglib-form {
	// display: none;
	.form__field {
		label {
			font-weight: 700;
		}
		#TermsAndConditions {
			display: none;
		}
		label[for="TermsAndConditions"] {
			display: inline-block;
		}
		.fakecheckbox {
			position: relative;
			width: 20px;
			height: 20px;
			border-radius: 3px;
			border: 1px solid $grey;
			background-color: white;
			vertical-align: sub;
			margin-right: 5px;
		}
		input:checked + .fakecheckbox {
			&:before {
				content: '';
				width: 3px;
				height: 7px;
				top: 8px;
				left: 4px;
				transform: rotate(-40deg);
				display: inline-block;
				background-color: $grey--ultradark;
				position: absolute;
			}
			&:after {
				content: '';
				width: 3px;
				height: 13px;
				top: 3px;
				left: 9px;
				transform: rotate(40deg);
				display: inline-block;
				background-color: $grey--ultradark;
				position: absolute;
			}
		}
	}
}
/*!
Pure v0.6.0
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
https://github.com/yahoo/pure/blob/master/LICENSE.md
*/
/*csslint regex-selectors:false, known-properties:false, duplicate-properties:false*/

.pure-g {
    letter-spacing: -0.31em; /* Webkit: collapse white-space between units */
    *letter-spacing: normal; /* reset IE < 8 */
    *word-spacing: -0.43em; /* IE < 8: collapse white-space between units */
    text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */

    /*
    Use flexbox when possible to avoid `letter-spacing` side-effects.

    NOTE: Firefox (as of 25) does not currently support flex-wrap, so the
    `-moz-` prefix version is omitted.
    */

    display: -webkit-flex;
    -webkit-flex-flow: row wrap;

    /* IE10 uses display: flexbox */
    display: -ms-flexbox;
    -ms-flex-flow: row wrap;

    display: flex;
    flex-flow: row wrap;

    /* Prevents distributing space between rows */
    -ms-align-content: flex-start;
	-webkit-align-content: flex-start;
	align-content: flex-start;
}

.pure-u {
    display: inline-block;
    *display: inline; /* IE < 8: fake inline-block */
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

/*
Resets the font family back to the OS/browser's default sans-serif font,
this the same font stack that Normalize.css sets for the `body`.
*/
// .pure-g [class *= "pure-u"] {
//     font-family: sans-serif;
// }

.pure-u-0,
.pure-u-1,
.pure-u-1-1,
.pure-u-1-2,
.pure-u-1-3,
.pure-u-2-3,
.pure-u-1-4,
.pure-u-3-4,
.pure-u-1-5,
.pure-u-2-5,
.pure-u-3-5,
.pure-u-4-5,
.pure-u-5-5,
.pure-u-1-6,
.pure-u-5-6,
.pure-u-1-8,
.pure-u-3-8,
.pure-u-5-8,
.pure-u-7-8,
.pure-u-1-12,
.pure-u-5-12,
.pure-u-7-12,
.pure-u-11-12,
.pure-u-1-24,
.pure-u-2-24,
.pure-u-3-24,
.pure-u-4-24,
.pure-u-5-24,
.pure-u-6-24,
.pure-u-7-24,
.pure-u-8-24,
.pure-u-9-24,
.pure-u-10-24,
.pure-u-11-24,
.pure-u-12-24,
.pure-u-13-24,
.pure-u-14-24,
.pure-u-15-24,
.pure-u-16-24,
.pure-u-17-24,
.pure-u-18-24,
.pure-u-19-24,
.pure-u-20-24,
.pure-u-21-24,
.pure-u-22-24,
.pure-u-23-24,
.pure-u-24-24 {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

.pure-u-0 {
    width: 0;
}

.pure-u-1-24 {
    width: 4.1667%;
}

.pure-u-1-12,
.pure-u-2-24 {
    width: 8.3333%;
}

.pure-u-1-8,
.pure-u-3-24 {
    width: 12.5000%;
}

.pure-u-1-6,
.pure-u-4-24 {
    width: 16.6667%;
}

.pure-u-1-5 {
    width: 20%;
}

.pure-u-5-24 {
    width: 20.8333%;
}

.pure-u-1-4,
.pure-u-6-24 {
    width: 25%;
}

.pure-u-7-24 {
    width: 29.1667%;
}

.pure-u-1-3,
.pure-u-8-24 {
    width: 33.3333%;
}

.pure-u-3-8,
.pure-u-9-24 {
    width: 37.5000%;
}

.pure-u-2-5 {
    width: 40%;
}

.pure-u-5-12,
.pure-u-10-24 {
    width: 41.6667%;
}

.pure-u-11-24 {
    width: 45.8333%;
}

.pure-u-1-2,
.pure-u-12-24 {
    width: 50%;
}

.pure-u-13-24 {
    width: 54.1667%;
}

.pure-u-7-12,
.pure-u-14-24 {
    width: 58.3333%;
}

.pure-u-3-5 {
    width: 60%;
}

.pure-u-5-8,
.pure-u-15-24 {
    width: 62.5000%;
}

.pure-u-2-3,
.pure-u-16-24 {
    width: 66.6667%;
}

.pure-u-17-24 {
    width: 70.8333%;
}

.pure-u-3-4,
.pure-u-18-24 {
    width: 75%;
}

.pure-u-19-24 {
    width: 79.1667%;
}

.pure-u-4-5 {
    width: 80%;
}

.pure-u-5-6,
.pure-u-20-24 {
    width: 83.3333%;
}

.pure-u-7-8,
.pure-u-21-24 {
    width: 87.5000%;
}

.pure-u-11-12,
.pure-u-22-24 {
    width: 91.6667%;
}

.pure-u-23-24 {
    width: 95.8333%;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-5-5,
.pure-u-24-24 {
    width: 100%;
}
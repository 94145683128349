﻿// RJ.COMPONENT.SUB-NAV

.c-sub-nav {
	position: relative;
	width: 100%;
	overflow: hidden;
	border: 1px solid $grey--light;
	margin-bottom: 15px;
}

.c-sub-nav__heading {
	padding: 15px 30px;
	border: 1px solid $charcoal--dark;
	margin: 0;
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
	color: $white;
	background-color: $charcoal--dark;

	a {
		color: $white;
	}
}

.c-sub-nav__items {
	@include list-reset;
	position: relative;
	z-index: 1;

	.hide {
		display: none;
	}

	.unhidden {
		display: block !important;
	}

}

.c-sub-nav__item {
	border-bottom: 1px solid $grey--light;

	&.current-page {
		position: relative;
		background-color: $grey--ultralight;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 5px;
			height: 100%;
			background-color: $charcoal--dark;
		}
	}

	&:last-child {
		border-bottom: none;
	}
}

.c-sub-nav__current,
.c-sub-nav__link,
.c-sub-nav__child-link {
	display: block;
	padding: 15px 30px;
	font-weight: 700;
	font-size: 16px;
	line-height: 17px;
}

.c-sub-nav__link,
.c-sub-nav__child-link {
	color: $charcoal--dark;

	&:hover,
	&:active,
	&:focus {
		color: $blue--lake;
	}
}

.c-sub-nav__child-link {
	padding: 10px 30px 10px 40px;
}

.c-sub-nav__current {
	background-color: $grey--ultralight;
}

.c-sub-nav__child-items {
	@include list-reset;
}

.c-sub-nav__child-item {

	&:last-child {
		padding-bottom: 10px;
	}
}

// Single Item Subnav (when current = only second level page)
// Adjust stylings so the current page is treated like a parent
.c-sub-nav__item--single {

	&,
	&.current-page {
		background-color: $white;

		&:after {
			display: none;
		}
	}

	.c-sub-nav__current {
		padding: 15px 30px;
		border: 1px solid $charcoal--dark;
		border-bottom: none;
		background-color: $charcoal--dark;
		color: $white;
	}

	.c-sub-nav__child-item {
		border-bottom: 1px solid $grey--light;

		&:last-child {
			padding-bottom: 0;
			border-bottom: none;
		}
	}

	.c-sub-nav__child-link {
		display: block;
		padding: 15px 30px;
	}
}

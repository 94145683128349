//LISTING STYLES

.c-listing__content-title-link {
	&,
	.body--content & {
		color: $charcoal--dark !important;

		&:hover,
		&:focus-visible {
			color: $blue--lake !important;
			text-decoration: none !important;
		}
	}
}

.listings--count {
	padding: 0 20px 10px;
	font-size: 14px;
	color: #666;
	border-bottom: 1px solid #ccc;
}

.listings-wrapper {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;

	.tab & {
		margin-top: 20px;
	}

	.listing {
		padding: 15px 0;
		border-bottom: 1px solid #ccc;
		display: flex;
		width: 100%;
		color: $black;
		@media ($large-mobile) {
			padding: 20px 0;
		}
		&:last-child {
			border-bottom: 0;
		}
		.listing-image {
			max-width: 100%;
			margin-bottom: 15px;
			@media ($large-mobile) {
				margin-bottom: 0px;
			}
			@media ($large-mobile) {
				max-width: unset;
				padding-right: 30px;
			}
			img {
				width: 100%;
				max-width: 350px;
				display: block;
				vertical-align: middle;
			}
			a {
				display: block;
				vertical-align: middle;
			}
		}
		.listing-content {
			line-height: 21px;
			h3 {
				margin-top: 0;
				font-size: 20px;
				@media ($large-mobile) {
					font-size: 24px;
				}
				a {
					text-decoration: none;
				}
			}
			.content-wrapper {
				display: inline-block;
				width: 100%;
				@media ($small-mobile) {
					width: 82%;
				}
				@media ($desktop) {
					width: 86.5%;
				}
				@media ($desktop-large) {
					width: 90%;
				}
				h2 {
					margin-top: 0;
				}
			}
		}
		.date {
			font-size: 14px;
			padding: 10px 0 5px;
			color: #666;
			display: inline-block;
			line-height: 1;
		}
		.author {
			font-size: 14px;
			text-decoration: none;
			font-weight: 700;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.expand-all {
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	float: right;
	color: $charcoal--dark;
	cursor: pointer;
	margin: 10px 0;
	&:hover,
	&:focus-visible {
		color: $blue--lake;
	}
	.open {
		display: inline-block;
	}
	.close {
		display: none;
	}
	&.all-expanded {
		.open {
			display: none;
		}
		.close {
			display: inline-block;
		}
	}
}
.review-icon {
	border-radius: 80px;
	display: inline-block;
	width: 50px;
	height: 50px;
	margin-right: 15px;
	margin-bottom: 15px;
	background-color: $black;
	color: white;
	vertical-align: top;
	font-size: 22px;
	font-weight: 400;
	@media ($tablet-plus) {
		width: 50px;
		height: 50px;
		font-size: 24px;
		margin-bottom: 0;
	}
	&:before {
		position: relative;
		display: inline-block;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
	&.iconf-trip_advisor {
		background-color: #54953b;
		font-size: 28px;
		@media ($tablet-plus) {
			font-size: 32px;
		}
	}
	&.iconf-twitter, &.iconf-line_twitter {
		background-color: #55acee;
	}
	&.iconf-share {
		background-color: #71c4cc;
	}
	&.iconf-speech_bubble {
		background-color: #f49018;
	}
	&.iconf-facebook, &.iconf-line_facebook {
		background-color: #3b5998;
	}
	&.iconf-google_Plus, &.iconf-line_googleplus {
		background-color: #d34836;
	}
	&.iconf-youtube, &.iconf-line_youtube {
		background-color: #e52d27;
	}
	&.iconf-pinterest, &.iconf-line_pinterest {
		background-color: #cb2027;
	}
	&.iconf-linkedin {
		background-color: #007bb5;
	}
}
.tooltip {
	display: inline-block;

	.tooltip__trigger {
		text-decoration: none;
		color: $white;
		background: $blue;
		&:hover {
			background: $blue--dark;
		}
		border-radius: 100%;

		display: inline-block;
		font-size: 16px;
		width: 16px;
		height: 16px;
		line-height: 1;

		&:before {
			@include font-icon;
			content: $iconf-info;
		}
	}

	.tooltip__body {
		display: none;

		padding: 25px 30px;
		background: $black;
		color: $white;

		position: absolute;
		z-index: 10;

		transform: translateX(-50%);
		margin-left: 7px;
		margin-top: 10px;

		@media ($desktop) {
			margin-left: 31px;
			margin-top: -11px;
			transform: translateY(-50%);
			width: 320px;
		}

		&:before {
			content: "";
			position: absolute;

			width: 0;
			height: 0;

			border-bottom: 10px solid $black;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 0;

			left: 50%;
			margin-left: -10px;
			top: -10px;

			@media ($desktop) {
				left: -10px;
				top: 50%;
				margin-top: -10px;
				margin-left: 0;

				border-right: 10px solid $black;
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 0;
			}
		}

		.tooltip__caption {
			margin: 0;
			font-size: 14px;
		}

		.tooltip__close + * {
			margin-top: 0;
		}
		> :last-child {
			margin-bottom: 0;
		}

		img {
			max-width: 100%;
		}
	}

	&.is-open {
		.tooltip__body {
			display: block;
		}
	}

	.tooltip__close {
		position: absolute;
		top: 10px;
		right: 10px;
		color: $grey;

		&:hover {
			color: $white;
			text-decoration: none;
		}
	}

	@media ($tablet-plus) {
		&.tooltip--left {
			.tooltip__body {
				margin-left: -335px;

				&:before {
					left: auto;
					right: -10px;
					border-right: 0;
					border-left: 10px solid $black;
				}
			}
		}
	}
}
// RJ.CONTROL.ICON-CAROUSEL

.c-icon-carousel {}

.c-icon-carousel__slides {
	margin: 40px 0;
	padding: 0 20px;

	@media ($desktop) {
		margin: 60px 0;
	}

	.slick-arrow {
		bottom: 50%;
		transform: translateY(50%);

		background: $charcoal--dark;
		color: $white;
		box-shadow: inset 0 0 0 1px $charcoal--dark;

		&::before {
			color: inherit;
			@include font-icon;
		}

		&.slick-prev:before {
			content: $iconf-arrows_left;
		}
		
		&.slick-next:before {
			content: $iconf-arrows_right;
		}

		&:hover,
		&:focus-visible {
			background: $white;
			color: $charcoal--dark;

			&::before {
				color: inherit;
			}
		}
	}
}

.c-icon-carousel__slide-wrapper {
	// Used to prevent Slick interfering directly with slide styles
}

.c-icon-carousel__slide {
	display: flex;
	flex-direction: column;
	align-items: center;

	.slick-slide & {
		// Setting this before Slick initialises breaks the layout somehow
		padding: 5px 40px;
	}

	border-left: 1px solid $grey--ultralight;
	&:last-child {
		border-right: 1px solid $grey--ultralight;
	}
}

.c-icon-carousel__slide--image {
	.slick-slide & {
		padding: 10px 5px;
	}
}

.c-icon-carousel__slide-icon {
	display: block;
	margin-bottom: 20px;

	font-size: 48px;
	color: $charcoal--dark;
}

.c-icon-carousel__slide-title {
	display: block;
	text-align: center;
	margin-bottom: 20px;

	font-size: 20px;
	line-height: 28px;
	font-weight: 800;
	color: $charcoal--dark;
}

.c-icon-carousel__slide-text {
	display: block;
	text-align: center;

	font-size: 16px;
	line-height: 22px;
	color: $charcoal;

	> :last-child {
		margin-bottom: 0;
	}
}

.c-icon-carousel__slide-figure {
	width: 100%;
}

.c-icon-carousel__slide-image {}

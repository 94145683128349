﻿.booking-errors {
	display: none;

	position: fixed;
	z-index: 10;

	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	background: rgba(0, 0, 0, 0.4);
}

.booking-errors__body {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);

	background: $white;
	padding: 10px;
	max-height: 90%;
	width: 90%;
	@media ($tablet-plus) {
		padding: 40px;
		width: auto;
		max-width: 90%;
	}

	.error {
		color: $red;
		font-size: 1.2em;
		@media ($tablet-plus) {
			font-size: 1.4em;
		}
		margin-top: 0;
		margin-bottom: 20px;
	}

	.btn {
		margin-top: 10px;
	}
}

.booking-errors__body-group {
	display: inline-block;
	padding: 10px;
	vertical-align: top;

	max-width: calc(100% - 60px);
}

.booking-errors__icon {
	font-size: 32px;
	color: $red;
}

.availability-error {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: rgba(0, 0, 0, 0.4);
	padding: 20px;

	.error {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		width: 90%;
		@media ($tablet-plus) {
			width: auto;
		}
		max-width: 90%;
		padding: 20px;

		background: $white;
		color: $red;
		font-size: 1.2em;
		line-height: 1.2;
	}
}
﻿.tablescroll {
	position: relative;
	z-index: 1;
	overflow: hidden;
	clear: both;
	margin:	25px 0;
	table {
		margin-bottom: 0;
	}
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		z-index: 2;
		top: 0;
		width: 1%;
		height: 100%;
		display: none;
	}
	&.is-scrolling {
		&:before,
		&:after {
			display: block;
		}
	}
	&:before {
		box-shadow: 5px 0px 20px rgba(50, 50, 50, 0.75);
		left: -1%;
	}
	&:after {
		box-shadow: -5px 0px 20px rgba(50, 50, 50, 0.75);
		right: -1%;
	}
	&.is-end {
		&:after {
			display: none;
		}
	}
	&.is-start {
		&:before {
			display: none;
		}
	}
	&.is-end {
		&:after {
			display: none;
		}
	}
	.tablescroll-inner {
		width: 100%;
	}
	&.is-scrolling .tablescroll-inner {
		overflow-x: scroll;
	}

}

// RJ.OBJECT.TABS-NAV

.o-tabs-nav {
	padding: 25px 0 0;
	border-bottom: 2px solid $charcoal--dark;
}

.o-tabs-nav__btn {
	position: relative;
	float: left;
	display: block;
	padding: 14px 20px;
	border: 1px solid $charcoal--dark;
	border-bottom: 0;
	border-radius: 3px 3px 0 0;
	margin: 0 6px 0 0;
	font-size: 20px;
	font-weight: 700;
	text-decoration: none;
	color: $charcoal--dark;
	background-color: $white;
	transition: 0.2s ease;

	&:active,
	&.tab-current {
		color: $white;
		background-color: $charcoal--dark;
	}

	&.tab-default {
		border-color: $blue--lake;
		color: $white;
		background-color: $blue--lake;
	}

	&:hover {
		border-color: $charcoal--dark;
		color: $white;
		background-color: $charcoal--dark;
	}
}
﻿// RJ.COMPONENT.DESTINATIONS
@use "sass:math";

.c-destinations {
	width: 100%;

	@media ($tablet-plus) {
		width: calc(50% - 20px);
	}
}

.c-destinations__map {
	position: relative;
	padding: 0;
	margin: 0;
	background: transparent url('/Content/images/destinations/destinations.png') 50% 50% no-repeat;
	background-size: contain;

	&:before {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: percentage(math.div(1020, 1240));
	}
}

.c-destinations__link {
	position: absolute;
	display: inline-block;
	padding: 4px 10px;
	border-radius: 3px;
	font-size: 10px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	color: $white;
	background-color: $charcoal--dark;
	transition: background-color 0.3s ease;

	&:focus,
	&:hover {

		&,
		&:after {
			background-color: $grey--dark;
		}
	}

	@media ($desktop) {
		font-size: 15px;
		padding: 5px 15px 6px;
	}

	&::after {
		content: '';
		position: absolute;
		right: -4px;
		top: 50%;
		display: block;
		width: 8px;
		height: 8px;
		background-color: $charcoal--dark;
		transform: translateY(-50%) rotate(45deg);
		transition: background-color 0.3s ease;
	}
}

.c-destinations__link--qt,
.c-destinations__link--ta {

	&::after {
		right: auto;
		left: -4px;
	}
}

.c-destinations__link--ms {
	right: 55.1%;
	top: 46.4%;
	transform: translate(-8px, -50%);
}

.c-destinations__link--ds {
	right: 64.9%;
	top: 62.6%;
	transform: translate(-8px, -50%);
}

.c-destinations__link--si {
	right: 59.5%;
	top: 91.4%;
	transform: translate(-8px, -50%);
}

.c-destinations__link--qt {
	left: 52.7%;
	top: 54.2%;
	transform: translate(8px, -50%);
}

.c-destinations__link--ta {
	left: 40.6%;
	top: 62%;
	transform: translate(8px, -50%);
}

@media print {
	.c-destinations__link {
		color: $white !important;
		&::after {
			display: none;
		}
	}
}

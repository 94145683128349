.search__form {
	margin-bottom: 20px;
}

.search--fieldset {
	border: 0;
	width: 100%;
	padding: 0;
	background-color: #f0f0f0;

	.search--field {
		display: flex;
		flex-direction: column;

		@media ($tablet-plus) {
			flex-direction: row;
		}
	}

	.search--filter {
		position: relative;
		height: 50px;
		width: 100%;
		border-top: 5px solid white;
		flex: 1;

		@media ($tablet-plus) {
			width: 20%;
			border-left: 1px solid $grey;
			border-top: 0;
			margin: 0;
		}

		&:after {
			position: absolute;
			right: 20px;
			top: 17px;
			font-family: 'font-icons';
			content: "\e907";
			pointer-events: none;
		}

		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			&::-ms-expand {
				display: none;
			}
			width: 100%;
			height: 50px;
			padding: 10px 15px;
			border: 0;
			background-color: #f0f0f0;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 14px;
			background-color: #f2f2f2;

			.default {
				font-weight: 700;
			}

			option {
				text-transform: none;
				font-size: 16px;

				[selected] {
					font-weight: 700;
				}
			}
		}
	}

	.search--keywords {
		width: 100%;
		display: inline-block;
		flex: 2;
		padding-left: 15px;
		padding-top: 10px;
		border-top: 5px solid white;

		@media ($tablet-plus) {
			padding-left: 20px;
			padding-top: 0;
			border-top: 0;
		}

		h5 {
			font-size: 14px;
			margin: 0;
			padding: 0;
			text-transform: uppercase;
		}

		h5, input {
			display: block;

			@media ($tablet-plus) {
				display: inline-block;
			}
		}

		.search--text-field {
			width: 65%;
			position: relative;
			display: inline-block;
			height: 50px;
			vertical-align: middle;

			input {
				display: inline-block;
				width: 90%;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				height: 30px;
				border: 1px solid $grey;
				margin-top: 10px;
				margin-left: 0;
				padding-left: 10px;
				vertical-align: middle;
			}

			@media ($tablet-plus) {
				width: 55%;

				input {
					margin-left: 10px;
				}
			}
		}
	}

	.search--submit {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		font-family: $font-family-sans;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		background-color: $charcoal--dark;
		box-shadow: inset 0 0 0 2px $charcoal--dark;
		color: $white;
		border: 0;
		height: 50px;
		padding: 0 25px;
		margin-top: 5px;
		border-top: 5px solid white;

		@media ($tablet-plus) {
			border: 0;
			margin-top: 0;
			float: right;
		}

		&:hover {
			background-color: $white;
			color: $charcoal--dark;
		}

		.iconf {
			margin-left: 10px;
		}

		.sr-only {
			display: none;
		}
	}
}

.search--results {
	list-style: none;
	padding-left: 0;
	margin: 0;

	.result {
		a {
			text-decoration: none;
			color: $charcoal--dark;
			&:hover, &:active, &:focus {
				color: $blue--lake;
			}
		}
		.result--title {
			margin: 0;
		}
		.result--description {
			margin: 5px 0 0;

			a {
				color: $blue--lake;
				word-break: break-word;
			}
		}
	}
}

.fake-select__wrapper {
	height: 50px;
	line-height: 50px;

	&.is-open .fake-select {
		box-shadow: 0px 4px 6px rgba(0,0,0,0.35);
	}

	.fake-select {
		position: relative;
		z-index: 1;
		background-color: #f2f2f2;
		padding: 0;
		margin: 0;
		cursor: pointer;
		text-transform: uppercase;

		&:after {
			position: absolute;
			right: 20px;
			top: 0;
			font-family: 'font-icons';
			content: "\e907";
		}
	}

	.fake-select__option {
		display: none;
		font-size: 14px;
		line-height: 50px;
		padding-left: 15px;

		&.is-selected {
			display: block;
			font-weight: 700;
		}
	}

	&.is-open {
		.fake-select__option {
			display: block;
			background-color: $grey--ultralight;
			line-height: 35px;
			&:first-child {
				line-height: 50px;
				font-weight: 700;
			}
			&:hover{
				background-color: $grey--ultralight;
			}
		}
	}
}
// RJ.OBJECT.PAGE

.o-page__controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;

	@media ($desktop) {
		margin-bottom: 40px;
	}
}

.o-page__header {
	width: 100%;
	margin-bottom: 20px;
	text-align: center;

	@media ($desktop) {
		margin-bottom: 40px;
	}
}

.o-page__heading {
	margin: 0 0 10px;
	font-size: 36px;
	font-weight: 800;

	&:last-child {
		margin-bottom: 0;
	}

	@media ($tablet-plus) {
		margin: 0 0 25px;
		font-size: 40px;
	}

	@media ($desktop) {
		font-size: 52px;
	}
}

.o-page__introduction {
	max-width: 1024px;
	margin: 0 auto;

	> p:last-child {
		margin-bottom: 0;
	}
}
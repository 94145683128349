﻿._form-wrapper {
	display: flex;
	align-items: center;
	z-index: 100000000 !important;

	._form {
		position: relative !important;
	}

	._close {
		top: -16px !important;
		right: -18px !important;
	}
}

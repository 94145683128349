﻿// RJ.CONTROL.CROWDRIFF

.c-crowdriff {
	margin-bottom: 40px;

	.last-control & {
		margin-bottom: 0;
	}
}

.c-crowdriff__icon-wrap {
	text-align: center;
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		width: 50%;
		border-top: 1px solid $grey;
	}

	&::before {
		left: -50px;
	}

	&::after {
		right: -50px;
	}
}

.c-crowdriff__icon {
	position: relative;
	padding: 0 10px;
	font-size: 28px;

	&.iconf-cms_xiaohongshu {
		color: $white;
		background: $charcoal--dark;
		border-radius: 6px;
		width: 48px;
		height: 48px;
		padding: 0;
		display: inline-flex;
		justify-content: center;
		align-items: center;
			font-size: 36px;

	}

	@media ($desktop) {
		font-size: 42px;
	}
}

﻿// RJ.COMPONENT.EXPANDABLE

.c-expandable {
	width: 100%;
	padding: 0;
	border-bottom: 0;
	margin-top: 2px;

	.map-wrap {
		position: relative;
		float: right;

		.link {
			position: absolute;
			top: 0;
			right: 0;
			display: inline-block;
			height: 50px;
			padding: 0 20px;
			line-height: 50px;
			background-color: $charcoal--dark;
			color: $white !important;
			box-shadow: inset 0 0 0 2px $charcoal--dark;
			text-decoration: none;

			&:hover,
			&:focus-visible {
				color: $charcoal--dark !important;
				background-color: $white;
				text-decoration: none !important;
			}

			.fullmap {
				font-weight: 700;
				text-transform: uppercase;
				font-size: 14px;
				vertical-align: top;
			}
		}

		.map {
			width: 100%;
		}
	}

	.expandable--toggle-more {
		display: inline-block;
	}

	.expandable--toggle-less {
		display: none;
	}

	&.is-expanded {
		.expandable--toggle-more {
			display: none;
		}

		.expandable-content {
			height: auto;
		}

		.expandable-content-inner {
			padding: 20px;
			transition: 0.3s ease;
		}

		.expandable-content--flush-sides {
			.expandable-content-inner {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.expandable-title {
			color: $black;

			p, h3 {
				color: $black;
			}
		}

		.expandable-arrow {
			transform: rotate(180deg);
			color: $black;
		}

		.expandable--toggle-less {
			display: inline-block;
		}
	}

	.expandable-wrapper {
		&:hover,
		&:focus-visible {
			background-color: $charcoal--dark;

			span, .expandable-title {
				&, p, h3 {
					color: $white;
				}
			}
		}
	}

	&.is-disabled {
		span, .expandable-title {
			&, p {
				color: $grey;
			}
		}

		.expandable-wrapper {
			&:hover,
			&:focus-visible {
				background-color: $grey--ultralight;
				color: $grey;
				cursor: default;

				span, .expandable-title {
					&, p {
						color: $grey;
					}
				}
			}
		}
	}
}

.c-expandable__wrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 20px;
	width: 100%;
	font-size: 22px;
	font-weight: 700;
	background-color: $grey--ultralight;
	transition: 0.3s ease;
	cursor: pointer;
}

.c-expandable__title {
	display: inline-block;
	width: 90%;

	&,
	p,
	h3 {
		margin: 0;
		font-size: 18px;
		font-weight: 700;
		line-height: 20px;
		color: $charcoal--dark;
		transition: 0.3s ease;

		@media ($tablet-plus) {
			font-size: 22px;
			line-height: 24px;
		}
	}
}

.c-expandable__arrow {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	text-decoration: none;
	white-space: no-wrap;
	transition: 0.3s ease;
}

.c-expandable__icon {
	position: absolute;
	left: 50%;
	top: 50%;
	letter-spacing: normal;
	transform: translate(-50%, -50%);

	color: $charcoal--dark;
}

.c-expandable__content {
	height: 0;
	overflow: hidden;
}

.c-expandable__content-inner {
	transition: 0.3s ease;
	padding: 0 20px;

	.c-expandable__content--flush-sides & {
		padding-left: 0;
		padding-right: 0;
	}
}
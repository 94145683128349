/* SHUTTLEROCK */

.shuttlerock-gallery {
	padding-left: 0;

	.pod figure {
		padding-bottom: 100%;
	}

	.pod-social .social-meta {
		padding: 15px 0;
		margin: 0;
	}
}
.filter-control--wrapper {
	display: none;
}

.location-map-wrap {
	transition: left 0.5s ease-in-out;
	position: absolute;
	display: none;
	width: 100%;

	&.is-expanded {
		position: fixed;
		left: 100%;
		top: 0;

		// Olark z-index + 1 | RJ-506
		z-index: 100000000;
		display: block;
	}

	&.is-visible {
		left: 0;
	}

	img {
		max-width: none;
	}
}

.location-map-canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	line-height: normal;

	.info-window {
		font-family: $font-family-sans;
		background-color: $black;
		color: white;
		padding: 10px;

		&.fixed-window {
			position: absolute;
			left: unset !important;
			bottom: unset !important;
			right: 0;
			top: 80px;
		}
	}
	.departure {
		font-size: 14px;
		font-family: $font-family-sans;
		background-color: $black;
		color: white;
		padding: 5px 10px;
		img {
			width: 16px;
			height: 16px;
			top: 4px;
			vertical-align: middle;
		}
		span {
			line-height: 24px;
		}
		&:before {
			content: '';
			top: 7px;
			left: -10px;
			@include triangle(10px, $black, 'left');
		}

		&.departure--left {
			&:before {
				left: auto;
				right: -10px;
				@include triangle(10px, $black, 'right');
			}
		}
	}
	.departure__link {
		&, &:hover {
			color: $white;
		}
	}

	.region-window {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: row;
		padding: 25px;
		box-shadow: 0px 3px 5px rgba(0,0,0, 0.3);
		a:visited {
			color: $blue;
		}
		> img {
			position: absolute !important;
			right: 15px;
			top: 15px;
			width: 16px;
			height: 16px;
		}
		.image-container {
			display: inline-block;
			margin-right: 25px;
			img {
				border: 3px solid white;
				outline: 1px solid $grey--light;
			}
		}
	}

	.map-control--wrapper {
		display: none;
		margin-left: 10px;
		margin-top: 10px;

		@media($tablet-plus) {
			display: block;
		}

		.control {
			display: inline-block;
			background-color: #fff;
			cursor: pointer;
			border-radius: 3px;
			text-align: center;
			margin-right: 10px;
			span {
				font-size: 12px;
				line-height: 34px;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	.control {
		box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
		font-family: 'Roboto','Arial',sans-serif;
	}
	.activeMapType {
		background-color: $blue !important;
		color: white !important;
		transition: 0.3s ease;
	}
	.filter-control--wrapper {

		@media ($tablet-plus) {
			display: inline-block;
		}

		text-align: left;
		width: 300px;
		height: 50px;
		background-color: $grey--ultralight;
		box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
		font-family: $font-family-sans;
		padding: 0 24px;
		transition: 0.3s ease;
		margin-right: 10px;
		&.is-open {
			height: 260px;
			.filter--arrow {
				transform: rotate(180deg);
			}
		}
		.filter--title-wrap {
			text-align: left;
			border-bottom: 1px solid $grey;
			margin-bottom: 10px;
			cursor: pointer;
		}
		.filter--title {
			line-height: 50px;
			font-size: 18px;
			font-weight: 700;
			text-align: left;
		}
		.filter--arrow {
			transition: 0.4s ease;
			font-size: 18px;
			font-weight: 700;
			float: right;
			line-height: 50px;
			transform: none;
		}
		.filter--wrapper {
			.filter {
				font-size: 16px;
				font-weight: 700;
				display: block;
				margin-right: 20px;
				line-height: 30px;
				input {
					display: none;
				}
				label {
					cursor: pointer;
				}
				.fakecheckbox {
					position: relative;
					vertical-align: sub;
					display: inline-block;
					width: 20px;
					height: 20px;
					border-radius: 3px;
					margin-right: 5px;
					background-color: white;
					border: 1px solid $grey;
				}
				input:checked + .fakecheckbox {
					&:before {
						content: '';
						width: 3px;
						height: 7px;
						top: 8px;
						left: 4px;
						transform: rotate(-40deg);
						display: inline-block;
						background-color: $grey--ultradark;
						position: absolute;
					}
					&:after {
						content: '';
						width: 3px;
						height: 13px;
						top: 3px;
						left: 9px;
						transform: rotate(40deg);
						display: inline-block;
						background-color: $grey--ultradark;
						position: absolute;
					}
				}
			}
		}
		.keys {
			padding: 0 0 10px 28px;
			border-bottom: 1px solid $grey;
			margin: 10px 0 12px;
			.key {
				margin-bottom: 6px;
				font-size: 14px;
			}
			.key-color {
				display: inline-block;
				width: 15px;
				margin-right: 5px;
				height: 5px;
				background-color: $grey;
				border-radius: 15px;
			}
			.key-coach .key-color {
				background-color: #060689;
			}
			.key-flight .key-color {
				background-color: #ffe205;
			}
			.key-cruise .key-color {
				background-color: #ff0404;
			}
			.key-cycling .key-color {
				background-color: #05f405;
			}
			.key-rafting .key-color {
				background-color: #05eaff;
			}
			.key-walking .key-color {
				background-color: #c004ff;
			}
		}
	}
}

.header-map-close {
	position: absolute;
	cursor: pointer;
	top: 0;
	right: 20px;
	@media ($desktop-large) {
		right: 100px;
	}
	display: inline-block;
	line-height: 50px;
	font-size: 14px;
	padding: 0 15px;
	color: white;
	background-color: rgba(33,33,33,0.9);
	text-transform: uppercase;
	vertical-align: middle;
	.iconf {
		margin-left: 10px;
		font-size: 18px;
		vertical-align: middle;
	}
}

.fixed-window {
	display: none;
	position: absolute;
	top: 70px;
	right: 20px;
	@media ($desktop-large) {
		right: 100px;
	}
	width: 550px;
	background-color: $black;
	color: white;
	padding: 25px;
	padding-top: 30px;
	box-shadow: 0px 3px 5px rgba(0,0,0, 0.3);
	.image-container {
		display: inline-block;
		width: 160px;
		margin-right: 25px;
		img {
			border: 3px solid white;
			outline: 1px solid $grey--light;
		}
	}
	.info {
		margin-bottom: 0;
	}
	.info-window-content {
		display: inline-block;
		width: calc(100% - 160px);
	}
	.close {
		cursor: pointer;
		font-size: 12px;
		text-transform: uppercase;
		position: absolute;
		top: 15px;
		right: 15px;
		vertical-align: middle;
		.iconf {
			font-size: 18px;
			vertical-align: middle;
			margin-left: 10px;
		}
	}
	a {
		color: white;
	}
}

.location-map-wrap.is-visible {
	.fixed-window {
		display: flex;
		position: absolute;
		align-items: center;
	}
}

.location-map-drivetimes {
	position: relative;
	display: inline-block;
	padding-left: 28px;
}

.drivetimes__icon {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 18px;
}

.drivetimes__text {
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
}
﻿// RJ.COMPONENT.HEADER

.c-header {
	position: fixed;
	top: 0;
	// Olark z-index + 1 | RJ-506
	z-index: 100000000;
	width: 100%;
	height: $rj-header-height--mobile;
	padding-bottom: 5px;
	background-color: $charcoal--dark;
	transition: top 0.3s ease;

	@media ($desktop) {
		height: $rj-header-height--desktop;
	}

	&.nav-up {
		top: -60px;

		@media ($desktop) {
			top: -71px;
		}
	}
}

.c-header__visible {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $charcoal--dark;
	width: 100%;
	padding-left: 10px;

	@media ($desktop) {
		padding: 0 20px;
	}
}

.c-header__logo {
	display: block;
	flex: 0 0 120px;
	width: 120px;
	height: 30px;
	background: transparent url('/Content/images/interface/logo/real-nz-white.svg') no-repeat center center;
	background-size: contain;

	@media (min-width: 1220px) {
		flex: 0 0 145px;
		width: 145px;
		height: 36px;
	}
}

.c-header__tools {
	display: flex;
	align-items: center;
}

.c-header__nav-toggle {
	@include btn-reset;
	position: relative;
	display: block;
	width: 54px;
	height: 54px;
	background-color: $charcoal--dark;
	color: $white;
	transition: all 0.3s ease;

	&:hover,
	&:focus-visible {
		background-color: $white;
		color: $charcoal--dark;
	}

	@media($desktop) {
		display: none;
	}

	i {
		position: absolute;
		left: 17px;
		top: 20px;
		display: block;
		width: 20px;
		height: 2px;
		background-color: currentColor;
		opacity: 1;
		transition-property: transform, top, opacity;
		transition-duration: 0.3s;
		transition-timing-function: ease;

		&:nth-of-type(2) {
			top: 26px;
		}

		&:nth-of-type(3) {
			top: 32px;
		}
	}

	&.active {

		i:nth-of-type(1) {
			transform: rotate(45deg);
			top: 25px;
		}

		i:nth-of-type(2) {
			opacity: 0;
		}

		i:nth-of-type(3) {
			transform: rotate(-45deg);
			top: 25px;
		}
	}
}

.c-header__search-form {
	display: flex;
	align-items: center;
	padding: 10px;
}

.c-header__search-input {
	order: 1;
	width: 100%;
	padding: 10px;
	border: none;
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	color: $black;
}

.c-header__search-button {
	@include btn-reset;
	position: relative;
	padding: 10px;
	font-size: 24px;
	color: $white;

	> .iconf-search {
		display: block;
	}
}

body.nav-is-showing {
	.YONDER_APP__wrapper {
		z-index: 1 !important; //to avoid chat widget overlapping mobile nav and hiding behind insta pod
	}
}

@media print {
	.c-header {
		position: absolute;

		* {
			color: $white !important;
		}

		a[href]::after {
			display: none;
		}
	}
}

@charset "UTF-8";

@import "../fonts/font-icons/variables.scss";

@import "themes/generic/_generic.helpers";
@import "themes/generic/_generic.mixins";

// Grab our foundations first
@import "common/theme";
@import "lib/lib";
/*@import "common/foundry-sterling";*/
@import "themes/base/_base.typography";
/*@import "common/iconf";*/
@import "../fonts/font-icons/style.scss";


// RJ specific variables
$rj-header-height--mobile: 59px;
$rj-header-height--desktop: 70px;

// Build the walls and then paint them.
@import "themes/base/_base.forms";

// Importing each of the old objects individually as some are not required any longer
@import "themes/base/_base.layout";
@import "themes/objects/_object.headermap";
@import "themes/objects/_object.body-text";
@import "themes/objects/_object.pod";
@import "themes/objects/_object.pagination";
/*@import "themes/objects/_object.plugins/rotator";*/
@import "themes/objects/_object.experiences";
@import "themes/objects/_object.filter";
@import "themes/objects/_object.listing";
@import "themes/objects/_object.macros";
@import "themes/objects/_object.search";
@import "themes/objects/_object.ajax-container";
@import "themes/objects/_object.imagelibrary";
@import "themes/objects/_object.datepicker";
@import "themes/objects/_object.tooltip";
@import "themes/objects/_object.call-to-action";
@import "themes/objects/_object.cookie-confirm";
@import "themes/objects/_object.modal";

// New objects
@import "themes/objects/_object.figure";
@import "themes/objects/_object.btn";
@import "themes/objects/_object.layout";
@import "themes/objects/_object.page";
@import "themes/objects/_object.control";
@import "themes/objects/_object.carousel";
@import "themes/objects/_object.pathway-row";
@import "themes/objects/_object.pathway";
@import "themes/objects/_object.social-links";
@import "themes/objects/_object.tab";
@import "themes/objects/_object.tabs-nav";
@import "themes/objects/_object.mobile-tabs";
@import "themes/objects/_object.filter";
@import "themes/objects/_object.fit";

// New controls
@import "themes/controls/_control.pathway-row";
@import "themes/controls/_control.awards";
@import "themes/controls/_control.key-selling-points";
@import "themes/controls/_control.content-highlights";
@import "themes/controls/_control.image-carousel";
@import "themes/controls/_control.pod-carousel";
@import "themes/controls/_control.experience-pods";
@import "themes/controls/_control.crowdriff";
@import "themes/controls/_control.social-links";
@import "themes/controls/_control.filtered-pods";
@import "themes/controls/_control.icon-carousel";
@import "themes/controls/_control.review-carousel";
@import "themes/controls/_control.accordion";

// Existing markup updated with namespacing / new components
@import "themes/components/_component.header";
@import "themes/components/_component.breadcrumbs";
@import "themes/components/_component.sub-nav";
@import "themes/components/_component.share";
@import "themes/components/_component.footer";
@import "themes/components/_component.hero";
@import "themes/components/_component.hero-rotator";
@import "themes/components/_component.toolbar";
@import "themes/components/_component.navigation";
@import "themes/components/_component.megamenu";
@import "themes/components/_component.homepage";
@import "themes/components/_component.welcome";
@import "themes/components/_component.finder";
@import "themes/components/_component.destinations";
@import "themes/components/_component.destination-detail";
@import "themes/components/_component.expandable";
@import "themes/components/_component.notice";
@import "themes/components/_component.fareharbor-cta";
@import "themes/components/_component.hero-gallery";
@import "themes/components/_component.rte-toggle";
@import "themes/components/_component.bookings";

// Booking
/*@import "themes/objects/_object.booking/booking";*/

// Plugins
@import "plugins/_plugins";

// The finale!
@import "themes/base/_base.print";

// Campaign Overrides
@import "campaign/campaign";
#ui-datepicker-div {
	z-index: 3 !important; // Otherwise will appear behind booking summary
}

.ui-datepicker {
	background: $white;
	border: 1px solid $grey;
	padding: 0;

	@media ($below-small-mobile) {
		width: 100%;
		left: 0 !important;
	}

	.ui-datepicker-header {
		padding: 0;
		height: 80px;
		background: $grey--ultradark;
		color: $white;
		font-weight: normal;

		.ui-datepicker-title {
			margin: 28px 25px;

			font-size: 24px;
			line-height: 24px;
		}

		.ui-datepicker-prev,
		.ui-datepicker-next {
			border: 1px solid $white;
			background: $grey--ultradark;

			width: 38px;
			height: 38px;

			&:hover {
				background: $white;
				border-color: $grey--ultradark;
				text-decoration: none;

				&:before {
					color: $blue;
				}
			}

			top: auto;
			bottom: 0;

			&:before {
				display: block;
				width: 100%;
				height: 100%;

				font-size: 14px;
				text-align: center;
				line-height: 38px;

				color: $white;
				cursor: pointer;
			}
		}

		.ui-datepicker-prev {
			@include font-icon;
			content: $iconf-arrows_left;

			left: 0;
		}

		.ui-datepicker-next {
			@include font-icon;
			content: $iconf-arrows_right;

			right: -1px;
		}
	}

	.ui-datepicker-group {
		border: 0 solid $grey;
		border-right-width: 1px;
		margin-right: -1px;

		&.ui-datepicker-group-last {
			border-left-width: 1px;
			border-right-width: 0;
			margin-right: 0;

			.ui-datepicker-title {
				text-align: left;
			}
		}

		.ui-datepicker-title {
			text-align: right;
		}
	}

	.ui-datepicker-calendar {
		tbody td {
			padding: 0;

			span, a {
				display: block;
				width: 35px;
				height: 35px;
				padding: 5px;
				line-height: 25px;
				font-weight: normal;
				border: 0;
				margin: 0 0 2px 0;

				text-align: center;
				color: $black;

				&.ui-state-active {
					background: $blue;
					font-weight: bold;

					.datepicker__calendar--range & {
						font-weight: normal;
						background: none;
					}
				}

				&.ui-state-hover {
					background: $blue;
				}
			}

			&.ui-datepicker-today {
				span, a {
					position: relative;
					font-weight: bold;

					&:after {
						content: "";
						position: absolute;
						bottom: 4px;
						left: 50%;
						margin-left: -2px;

						width: 4px;
						height: 4px;
						border-radius: 100%;
						background: $black;
					}
				}
			}

			&.ui-datepicker-unselectable {
				span, a {
					color: $grey;
					&:after {
						background-color: $grey;
					}
					background: url(/content/images/interface/bg/bg-calendar-disabled.png);
				}
			}

			.datepicker__calendar--range, & {
				&.datepicker__check-in {
					span, a {
						&, &.ui-state-active {
							color: $black;
							background: $blue;
							font-weight: bold;
						}
					}
				}
				&.datepicker__check-out {
					span, a {
						&, &.ui-state-active {
							color: $black;
							background: $orange;
							font-weight: bold;
						}
					}
				}
				&.datepicker__check-in.datepicker__check-out {
					&, &:hover {
						span, a {
							&, &.ui-state-active {
								background: linear-gradient(135deg, $blue 0%, $blue 50%, $orange 50%, $orange 100%);
							}
						}
					}
				}
				&.datepicker__checked-in {
					span, a, .ui-state-active {
						background: $blue--ultralight;
					}
				}


				&.datepicker__hover--check-in {
					span, a, .ui-state-active {
						&.ui-state-hover {
							background: $blue;
						}
					}
				}
				&.datepicker__hover--check-out {
					span, a, .ui-state-active {
						&.ui-state-hover {
							background: $orange;
						}
					}
				}
			}
		}
	}

	&.is-error {
		position: relative;
		&:before {
			content: attr(data-error-msg);
			position: absolute;
			z-index: 1;
			top: 80px;
			left: 0;
			right: 0;

			display: block;
			box-sizing: border-box;
			padding: 30px;
			background: $white;
			color: $red;
		}

		.ui-datepicker-calendar {
			position: relative;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: -0.4em;
				left: -0.4em;
				right: -0.4em;

				background: rgba(0, 0, 0, 0.1);
			}


		}
	}
}

.datepicker__calendar {
	display: inline-block;
	margin: 0 auto;
	@media ($desktop) {
		margin: 0 0 0 auto;
	}
	@media ($below-large-mobile) {
		width: 100%;
	}
}

.datepicker__wrapper {}

.datepicker__replacement {}

.datepicker-input {
	display: block;
	height: 50px;
	max-width: 100%;
	padding: 13px 72px 13px 13px;
	border: 2px solid $white;

	background: $white url(/content/images/interface/ico/ico-cal-blue.png) no-repeat right 17px top 17px;
	&, &:link, &:visited, &:hover, &:focus, &:active {
		color: $blue;
		text-decoration: none;
		font-weight: normal;
	}
}
.datepicker-input--hidden {
	@extend .visuallyhidden;
	position: relative !important;
	top: 50px;
}
.datepicker-btn {
	@extend .datepicker-input;
	border: 1px solid $grey;
	min-width: 232px;
	line-height: 20px;

	@media ($all-mobile) {
		width: 100%;
	}

	&.is-active {
		border-color: $blue;
	}
}

.datepicker-input--check-out, .datepicker-btn--check-out {
	background-image: url(/content/images/interface/ico/ico-cal-orange.png);
	&, &:link, &:visited, &:hover, &:focus, &:active {
		color: $orange;
	}

	&.is-active {
		border-color: $orange;
	}
}

.datepicker-btn--no-calendar {
	background-image: none;
}

.datepicker-input--outline, .datepicker-btn--outline {
	border: 1px solid $grey--dark;
}

.datepicker__calendar--hidden {
	display: none;
}

.datepicker__calendar--show {
	display: inline-block;
}
// COMPONENT.COOKIE-CONFIRM

.c-cookie-confirm {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	display: none;
	background: $black;
	box-shadow: 0 -2px 5px rgba($black, 0.2);
}

.c-cookie-confirm__body {
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 0;
	&, p {
		font-size: 14px;
	}

	p {
		margin-top: 0;
	}

	@media ($all-mobile) {
		flex-wrap: wrap;
	}
	@media ($tablet-plus) {
		padding-right: 210px;
		p:last-child {
			margin-bottom: 0;
		}
	}
}

.c-cookie-confirm__message {
	flex: 0 1 auto;
	margin: 0;
	color: $white;

	a {
		color: $white;
		text-decoration: underline;
	}

	@media ($all-mobile) {
		width: 100%;
	}
}

.c-cookie-confirm__button {
	flex: 0 1 auto;
	margin-left: 10px;
	padding: 3px 9px;
	min-height: 30px;
	font-size: 12px;

	@media ($all-mobile) {
		margin-left: 0;
	}
}

.c-cookie-confirm__close {
	@include font-icon;
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	background: none;
	border: 0;

	&::before {
		content: $iconf-close;
	}
}

//SITE SPECIFIC LAYOUT

.hidden-below-small-mobile {
	@media ($below-small-mobile) {
		display: none !important;
	}
}
.hidden-below-large-mobile {
	@media ($below-large-mobile) {
		display: none !important;
	}
}
.hidden-below-all-mobile {
	@media ($all-mobile) {
		display: none !important;
	}
}
.hidden-small-mobile {
	@media ($small-mobile) {
		display: none !important;
	}
}
.hidden-large-mobile {
	@media ($large-mobile) {
		display: none !important;
	}
}
.hidden-mobile {
	@media ($all-mobile) {
		display: none !important;
	}
}
.hidden-tablet {
	@media ($tablet) {
		display: none !important;
	}
}
.hidden-tablet-plus {
	@media ($tablet-plus) {
		display: none !important;
	}
}
.hidden-desktop {
	@media ($desktop) {
		display: none !important;
	}
}
.hidden-below-desktop {
	@media ($below-desktop) {
		display: none !important;
	}
}
.hidden-desktop-large {
	@media ($desktop-large) {
		display: none !important;
	}
}
.hidden-below-desktop-large {
	@media ($below-desktop-large) {
		display: none !important;
	}
}

.content {
	padding-top: $rj-header-height--mobile;

	@media ($desktop) {
		padding-top: $rj-header-height--desktop;
	}

	&.rotator-large--is-first {
		padding-top: 0;
	}
	// For booking summary box on smaller screens
	//overflow: hidden;
}

.homepage-section {
	padding-top: ($base-spacing-unit * 3.5);
	padding-bottom: ($base-spacing-unit * 3.5);
	&.title {
		padding-bottom: 0;
	}
}
.destinations {
	padding: 0;
}

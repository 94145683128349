//BUTTON SPECIFIC STYLES

.btn {
    display: inline-block;

    &, .body--content & {
        &,
        &:visited {
            color: $white;
        }
    }

    font-size: 14px;
    font-weight: 700;
    padding: 16px 2em 16px;
    border: none;
    background-color: $charcoal--dark;
    box-shadow: 0 0 0 2px inset $charcoal--dark;
    text-decoration: none;
    text-transform: uppercase;
    min-height: 50px;
    line-height: 1.2;
    text-align: center;
    transition: 0.3s ease;
    vertical-align: bottom;
    max-width: 100%;

    &:hover,
    &:focus-visible {
        cursor: pointer;
        background-color: $white;

        &, .body--content & {
            &,
            &:visited {
                color: $charcoal--dark;
            }

            text-decoration: none;
        }
    }

    &:visited {
        //color: inherit;
    }

    .iconf {
        margin-left: 5px;
        font-size: 12px;
        font-weight: 700;
        vertical-align: middle;
    }

    &.is-disabled {
        color: $grey--dark;
        background-color: $grey;
        pointer-events: none;
    }

    .btn-text {
        padding: 6px 0;
        line-height: 24px;
        display: inline-block;
        vertical-align: middle;
    }

    &.light {
        background-color: $white;
        color: $charcoal--dark;

        &:hover {
            color: $white;
            background-color: $charcoal--dark;
            box-shadow: 0 0 0 2px inset $charcoal--dark;
        }
    }
}

.btn--cancel {
    background-color: transparent;

    &, &:visited {
        color: $brand-primary;
    }

    &:hover {
        color: $black;
    }
}

.btn--low-key {
    background-color: $white;
    border: 2px solid $grey--dark;
    padding: 14px 2em;

    &, &:visited {
        color: $grey--dark;
    }

    &:hover {
        background: $grey--dark;
        color: $white;
    }
}

.btn--super-low-key {
    background-color: $white;
    border: none;
    padding-left: 0;
    padding-right: 0;
    text-decoration: underline;

    &, &:visited {
        color: $grey--dark;
    }

    &:hover {
        background: $white;
        color: $grey--dark;
    }
}

.btn--prev {
    position: relative;
    padding-left: 40px;
    float: left;
    margin-right: 10px;

    @media ($tablet-plus) {
        margin-right: 15px;
    }

    &:before {
        @include font-icon;
        content: $iconf-arrows_left;
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -9px;
    }

    @media ($below-large-mobile) {
        font-size: 0;
        padding-left: 20px;
        min-width: 50px;

        &:before {
            font-size: 14px;
        }
    }
}

.btn--next {
    position: relative;
    padding-right: 40px;
    float: right;

    .btn--prev ~ & {
        max-width: calc(100% - 60px);
    }

    @media ($tablet-plus) {
        margin-left: 15px;
    }

    &:after {
        @include font-icon;
        content: $iconf-arrows_right;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -9px;
    }
}

.btn--checkout {
    width: 100%;
    margin-bottom: 15px;

    @media ($tablet-plus) {
        width: auto;
        margin-bottom: 0;
    }
}

.btn--facebook {
    background-color: #4267b2; // Facebook blue
    &:hover {
        background-color: #3b5ca0; // Facebook's hover blue
    }

    &, &:visited, &:hover {
        color: $white;
    }

    text-transform: none;
    font-size: 16px;

    &, .content & {
        font-weight: 600;
    }

    min-height: 45px;
    padding: 11px;
    padding-right: 19px;

    [class^="iconf-"], [class*=" iconf-"] {
        font-size: 18px;
        margin-right: 3px;
    }
}

.text-btn {
    padding: 0;
    border: 0;
    margin: 0;
    background: transparent;
}

.btn-full {
    font-size: 18px;
    padding: 14px 3em;
}

.btn-rotator {
    span.iconf {
        font-weight: 700;
    }
}

.btn.btn-pathways {
    margin-top: 10px;
}

.btn.btn-photos {
    margin-top: 10px;
    border: 1px solid $black;
    text-decoration: none;

    &:hover {
        background-color: white;
        text-decoration: none;
    }

    .iconf {
        margin-left: 5px;
        font-weight: 700;
        font-size: 12px;
    }
}

.btn.btn-destinations {
    background-color: $blue--destinations;
    border: 2px solid $black;

    &:hover {
        background-color: white;
    }
}

.btn.btn-pods {
    display: block;
    margin: 5px;

    &.is-hidden {
        display: none;
    }
}

.btn.btn--full-width {
    width: 100%;
}

.btn.header-map-toggle {
    display: none;
    width: 100%;
    border: 1px solid $charcoal--dark;
    border-radius: 3px;
    font-size: 16px;
    color: $charcoal--dark;
    background-color: $white;
    transition: all 0.3s ease;

    &:hover,
    &:focus,
    &:active {
        background-color: $charcoal--dark;
        color: $white;
        text-decoration: none;
    }

    @media ($below-desktop) {
        &:first-child + * {
            margin-top: 0;
        }
    }

    @media ($desktop) {
        display: block;
    }

    @media ($desktop-large) {
        font-size: 18px;
    }

    .iconf {
        font-size: 22px;
        margin-right: 10px;
        vertical-align: sub;
    }
}

.listing-button {
    margin-top: 10px;
}


// RJ.OBJECT.BTN

.o-btn {
	display: inline-block;
	padding: 20px;
	border: 0;
	border-radius: 3px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	transition-property: background-color, color;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--primary {
	&, .body--content & {
		color: $white;
	}
	background-color: $charcoal--dark;
	border: 1px solid $white;

	&:visited {
		&, .body--content & {
			color: $white;
		}
	}

	&:hover,
	&:focus-visible {
		&, .body--content & {
			color: $charcoal--dark;
		}
		background-color: $white;
	}
}

.o-btn--secondary {
	border: 1px solid $charcoal--dark;
	&, .body--content & {
		color: $charcoal--dark;
	}
	background-color: $white;

	&:visited {
		&, .body--content & {
			color: $charcoal--dark;
		}
	}

	&:hover,
	&:focus {
		&, .body--content & {
			color: $white;
		}
		background-color: $charcoal--dark;
	}
}

.o-btn--tertiary {
	border: 1px solid $charcoal--dark;
	&, .body--content & {
		color: $charcoal--dark;
	}
	background-color: $white;

	&:visited {
		&, .body--content & {
			color: $charcoal--dark;
		}
	}

	&:hover,
	&:focus {
		border-color: darken($charcoal--dark, 10);
		&, .body--content & {
			color: darken($charcoal--dark, 10);
		}
		background-color: $white;
	}
}

.o-btn--transparent {
	border: 1px solid $white;
	&, .body--content & {
		color: $white;
	}
	background-color: transparent;

	&:visited {
		&, .body--content & {
			color: $white;
		}
	}

	&:hover,
	&:focus-visible {
		background-color: $white;
		&, .body--content & {
			color: $charcoal--dark;
		}
	}
}

.o-btn--transparent-inverted {
	border: 1px solid $charcoal--dark;
	&, .body--content & {
		color: $charcoal--dark;
	}
	background-color: transparent;

	&:visited {
		&, .body--content & {
			color: $charcoal--dark;
		}
	}

	&:hover,
	&:focus-visible {
		background-color: $charcoal--dark;
		&, .body--content & {
			color: $white;
		}
	}
}

// Font Icons
@mixin font-icon {
	// use !important to prevent issues with browser extensions that change fonts
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


// Breakpoint mixin
// Uses $breakpoints variable set in _config.scss file
@mixin bp($size) {

	@if (type-of($size) == "number") {
		@media (min-width: $size) {
			@content;
		}
	}
	@else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (min-width: $start) {
					@content;
				}
			}
		}
	}
	@else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

@mixin bp-below($size) {

	@if (type-of($size) == "number") {
		@media (max-width: #{em($size - 1px)}) {
			@content;
		}
	}
	@else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (max-width: #{$start - 1px}) {
					@content;
				}
			}
		}
	}
	@else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}


//SITE-SPECIFIC MIXINS
@mixin triangle ($size, $color, $direction: 'up') {
	position: absolute;
	width: 0;
	height: 0;
	content: "";

	@if $direction == 'up' {
		border-bottom: $size solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: 0;
	}
	@if $direction == 'down' {
		border-top: $size solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: 0;
	}
	@if $direction == 'left' {
		border-right: $size solid $color;
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: 0;
	}
	@if $direction == 'right' {
		border-left: $size solid $color;
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: 0;
	}
}

@mixin list-reset {
	padding: 0;
	margin: 0;
	list-style: none;
}

@mixin btn-reset {
	padding: 0;
	border: 0;
	background-color: transparent;
	outline: none;
}

@mixin brand-gradient {
	background: linear-gradient(143.98deg, #E7EB56 0%, #00A9D8 100%);
}

@mixin dropdown-shadow {
	box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.2);
}

@mixin brand-link {
	color: $blue--lake;

	&:hover,
	&:focus-visible {
		text-decoration: underline;
	}
}

// Reliable Flexbox fallback mixin
// 1. Unsupported
// 2. Old - iOS 6-, Safari 3.1-6
// 3. Old - Firefox 19- (buggy but mostly works)
// 4. Tweener - IE 10
// 5. New - Chrome
// 6. New, Spec - Opera 12.1, Firefox 20+
@mixin flex-extend {
	display: block;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	/* autoprefixer: ignore next */
	display: flex;
}

@mixin flex-wrap {
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	/* autoprefixer: ignore next */
	flex-wrap: wrap;
}
﻿// RJ.COMPONENT.TOOLBAR

.c-toolbar {
	display: flex;
}

.c-toolbar__item {

	@media ($desktop) {
		position: relative;
	}
}

.c-toolbar__item--contact {

	@media ($desktop) {
		display: none;
	}
}

.c-toolbar__item--map {
	display: none;

	@media ($desktop) {
		display: block;
	}
}

.c-toolbar__toggle {
	@include btn-reset;
	position: relative;
	display: block;
	width: 40px;
	height: 54px;
	color: $white;
	cursor: pointer;
	background-color: $charcoal--dark;
	transition: background-color 0.3s ease;

	@media('min-width: 400px') {
		width: 54px;
	}

	@media ($desktop) {
		height: 65px;
	}

	@media('min-width: 1220px') {
		width: 60px;
	}

	&:visited {
		color: $white;
	}

	&:hover,
	.c-toolbar__item.is-active & {
		background-color: $white;
		color: $charcoal--dark;
	}

	&:focus {

		@media ($desktop) {
			background-color: $white;
			color: $charcoal--dark;
		}
	}
}

.c-toolbar__icon {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 20px;
	transform: translate(-50%, -50%);
	transition: opacity 0.5s ease;

	.c-toolbar__item.is-active & {
		opacity: 0;
	}
}

.c-toolbar__icon--active {
	opacity: 0;

	.c-toolbar__item.is-active & {
		opacity: 1;
	}
}

.c-toolbar__icon--flag {
	width: 24px;
}

.c-toolbar__flag {
	display: block;
	width: 24px;
}



/* LANGUAGE BUTTONS */

.c-toolbar__item--language {
	display: flex;
	align-items: stretch;
}

.c-language-item {
	transition: all 0.3s;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	padding: 13px;
	font-size: 15px;
	font-weight: 700;
	text-align: center;
	color: $grey--dark;

	&:after {
		transition: all 0.3s;
		display: block;
		content: '';
		height: 2px;
		width: 30px;
		background: $white;
		position: absolute;
		bottom: 15px;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
	}


	@media (min-width: 400px) {
		width: 54px;
	}

	@media (min-width: 1220px) {
		width: 60px;
	}

	@media ($desktop) {
		width: 60px;
	}

	&:hover,
	&.is-active {
		color: $white;

		&:after {
			opacity: 1;
		}
	}
}

.c-language-item__label {
	font-size: 15px;
	font-weight: 700;
	white-space: nowrap;
	text-transform: uppercase;
}

.c-language-item__flag {
	width: 26px;
	height: 16px;
	/*margin-right: 15px;*/
	background: transparent url('/Content/images/interface/bg/bg-flag-sprite.png') no-repeat 0 0;
	background-size: 300%;
	filter: grayscale(100%);
}

.c-language-item__flag--zh-cn {
}

.c-language-item__flag--en {
	background-position: -26px 0;
}

.c-language-item__flag--jp {
	background-position: -52px 0;
}

@media print {
	.c-language-item__flag {
		filter: grayscale(100%) !important;
	}
}



.c-toolbar__dropdown {
	position: absolute;
	left: 0;
	top: $rj-header-height--mobile;
	z-index: 9999999;
	width: 100%;
	height: 0;
	overflow: auto;
	background-color: $charcoal--dark;
	transition: 0.5s ease;

	@media ($desktop) {
		left: 50%;
		top: $rj-header-height--desktop;
		width: 200px;
		transform: translateX(-50%);
	}

	.c-toolbar__item.is-active & {
		@include dropdown-shadow;
		height: calc(100vh - #{$rj-header-height--mobile});
		transition: 0.25s ease;

		@media ($desktop) {
			height: auto;
		}
	}
}

.c-toolbar__dropdown--language {
	background-color: $charcoal--dark;

	@media ($desktop) {
		background-color: $charcoal--dark;
	}
}

.c-toolbar__dropdown--search {

	@media ($desktop) {
		position: fixed;
		left: 0;
		right: 0;
		width: 100%;
		transform: none;
	}

	@media ('min-width: 1500px') {
		max-width: 1460px;
		left: 50%;
		transform: translateX(-50%);
	}

	.c-toolbar__item.is-active & {
		height: auto;
	}
}

.c-toolbar__phone-link {
	display: block;
	padding: 13px 30px;
	border-bottom: 1px solid $grey--dark;
	font-size: 15px;
	font-weight: 700;
	color: $white;
	transition: color 0.3s ease;

	&:hover,
	&:focus-visible {
		color: $grey--dark;
	}

	&:first-child {
		border-top: 1px solid $grey--dark;
	}
}

// CONTROL.SOCIAL-LINKS

.c-social-links__items {

	.body--content & {
		padding-left: 0;
		margin-left: -8px;
	}
}

.c-social-links__item {

	.body--content .social ul & {
		padding: 8px;
		line-height: normal;
	}
}

.c-social-links__link {

	.body--content & {
		color: $charcoal--dark;

		&:focus,
		&:hover {
			color: $white;
		}
	}
}

.c-social-links__icon {
}
﻿// RJ.COMPONENT.RTE-TOGGLE

.c-rte-toggle {
	[aria-hidden="true"] {
		display: none;
	}

	[aria-hidden="false"] {
		display: block;
	}

	&__preview[aria-hidden] {
		@media ($desktop) {
			display: none;
		}
	}

	&__complete[aria-hidden] {
		@media ($desktop) {
			display: block;
		}
	}

	&__trigger {
		color: $blue;
		font-weight: 700;
		cursor: pointer;
		transition: 0.3s all;
		padding: 0;
		margin: 0;
		background: none;
		border: none;

		&:hover,
		&:focus {
			color: $charcoal--dark;
		}

		@media ($desktop) {
			display: none;
		}
	}
}

// RJ.CONTROL.FILTERED-PODS

$filtered-pods-bp: $tablet-plus;

.c-filtered-pods {
	margin: 40px 0;
	@media ($desktop) {
		margin: 60px 0;
	}
}

.c-filtered-pods__filter {
	display: flex;
	flex-direction: column;
	align-items: center;
	@media($filtered-pods-bp) {
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
	}
}

.c-filtered-pods__filter-label {
	margin-bottom: 20px;

	font-family: $font-family-sans;
	font-size: 14px;
	line-height: 16px;

	@media($filtered-pods-bp) {
		font-size: 20px;
		line-height: 28px;
		font-weight: bold;

		margin-bottom: 0;
		margin-right: 20px;
		margin-top: 15px;
	}
}

.c-filtered-pods__filter-list {
	@include list-reset;
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	flex-wrap: wrap;

	margin-left: -10px;
	margin-top: -10px;

	justify-content: center;
	@media($filtered-pods-bp) {
		justify-content: flex-start;
	}
}

.c-filtered-pods__filter-item {
	padding-left: 10px;
	padding-top: 10px;
}

.c-filtered-pods__filter-button {
	&[aria-pressed="true"] {
		color: $white;
		background-color: $grey--dark;
		border-color: $grey--dark;
	}

	@media($below-large-mobile) {
		padding: 10px;
	}
}

.c-filtered-pods__body {
	margin: 40px 0;
}

.c-filtered-pods__pod-list {
	@include list-reset;
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-wrap: wrap;

	margin-left: -20px !important;
	margin-top: -40px !important;
}

.c-filtered-pods__filter ~ .c-filtered-pods__body .c-filtered-pods__pod-list {
	margin-top: 0;
}

.c-filtered-pods__pod-item {
	padding-left: 20px !important;
	padding-top: 40px !important;

	width: 100%;
	@media ($large-mobile) {
		width: 50%;
	}
	@media ($desktop) {
		width: 25%;
	}

	&[aria-hidden="true"] {
		display: none;
	}
}

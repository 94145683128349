// RJ.OBJECT.CONTROL

.o-control {
	clear: both;
	margin: 40px 0;

	@extend .pure-u-1;
}

.o-control__header {
	display: block;
	width: 100%;
}

.o-control__heading {
	margin: 0;
	font-size: 24px;
	font-weight: 800;
	text-align: center;
	line-height: 28px;
	color: $charcoal--dark;

	@media ($desktop) {
		font-size: 32px;
	}

	position: relative;
	padding-bottom: 20px;
	margin-bottom: 25px;
	&::after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 0;
		height: 1px;
		width: 50px;
		background-color: $grey--dark;
		transform: translateX(-50%);
	}
}

.o-control__subheading {
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	text-align: center;

	a {
		@include brand-link;
	}
}

.o-control__introduction {
	text-align: center;
	margin-bottom: 20px;

	@media ($desktop) {
		margin-bottom: 40px;
	}

	&,
	p {
		font-size: 20px;
		line-height: 30px;
	}

	a {
		@include brand-link;
	}

	> p:last-child {
		margin-bottom: 0;
	}

	li {
		list-style-position: inside;
	}
}

// RJ.COMPONENT.SHARE

.c-share {
	position: relative;
	flex: 0 0 auto;
	padding: 0 0 0 50px;
	cursor: pointer;

	&.active {
		.c-share__list {
			display: flex;
			z-index: 999;
			opacity: 1;

			@media($desktop) {
				display: inline-block;
			}
		}
	}
}

.c-share__mobile-icon {
	font-size: 20px;
	color: $black;
	transition: 0.3s ease;
	border-bottom: 0;
	text-align: right;
	padding-right: 10px;

	&:hover, &:active, &:focus {
		color: $charcoal--dark;
	}

	@media ($desktop) {
		display: none;
	}

	.c-experiences__gallery & {
		display: none;
	}
}

.c-share__list {
	display: none;
	position: absolute;
	right: 5px;
	top: 50px;
	width: auto;
	transition: 0.3s ease;
	opacity: 0;
	list-style: none;
	margin: 0;
	padding: 10px;
	box-shadow: 0px 2px 5px rgba(0,0,0,0.25);
	background-color: $grey--ultralight;

	@media ($large-mobile) {
		float: right;
	}

	@media ($desktop) {
		position: relative;
		display: inline-block;
		width: auto;
		opacity: 1;
		box-shadow: none;
		padding: 0;
		background-color: white;
		top: 0;
	}

	.c-experiences__gallery & {
		position: relative;
		display: inline-block;
		width: auto;
		opacity: 1;
		box-shadow: none;
		padding: 0;
		background-color: white;
		top: 0;
	}

	&:before {
		@include triangle(15px, $grey--ultralight);
		content: '';
		position: absolute;
		right: 0;
		top: -15px;

		@media ($desktop) {
			content: none;
		}

		.c-experiences__gallery & {
			display: none;
		}
	}
}

.c-share__item {
	display: inline-block;
	color: $grey;
	font-size: 16px;

	margin-left: 10px;
	&:first-child {
		margin-left: 0;
	}
}

.c-share__link {
	border: none;
	color: $black;
	transition: 0.15s ease;

	@media ($desktop) {
		color: $grey;
	}

	.c-experiences__gallery & {
		color: $grey;
	}

	&,
	&:hover,
	&:active {
		text-decoration: none;
	}

	&:hover,
	&:active,
	&:focus {
		color: $charcoal--dark;
	}
}
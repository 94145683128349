// RJ.OBJECT.LAYOUT

.o-layout__wrapper {
	width: 100%;
	max-width: 1460px;
	margin: 0 auto;
	padding-right: 10px;
	padding-left: 10px;

	@media ($tablet-plus) {
		padding-right: 20px;
		padding-left: 20px;
	}
}

.o-layout__heading {
	color: $rj-navy;
	font-size: 24px;
	font-weight: 600;
	line-height: 28px;
	text-align: center;
}

.o-layout__filter-pods {

	@media ($tablet-plus) {
		display: flex;
		justify-content: space-between;
	}
}

.o-layout__pods {
	width: 100%;

	@media ($tablet-plus) {
		width: calc(100% - 260px);
	}

	@media ($desktop) {
		width: calc(100% - 300px);
	}
}

.o-layout__pods-wrapper {
	margin-left: -20px;

	&.filtered-loading {
		min-height: 800px;
	}
}

.o-layout__filter {

	@media ($tablet-plus) {
		flex: 0 0 220px;
	}

	@media ($desktop) {
		flex: 0 0 260px;
	}
}
$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-tiktok: unquote('"\\e96c"');
$iconf-cms_tiktok: unquote('"\\e96c"');
$iconf-line_tiktok: unquote('"\\e96c"');
$iconf-Tiktok: unquote('"\\e96c"');
$iconf-star_half: unquote('"\\e96a"');
$iconf-arrows_up_SIL: unquote('"\\e94f"');
$iconf-arrows_right_SIL: unquote('"\\e950"');
$iconf-arrows_left_SIL: unquote('"\\e951"');
$iconf-arrows_down_SIL: unquote('"\\e952"');
$iconf-arrows_up_SIE: unquote('"\\e953"');
$iconf-arrows_down_SIE: unquote('"\\e954"');
$iconf-arrows_left_SIE: unquote('"\\e955"');
$iconf-arrows_right_SIE: unquote('"\\e956"');
$iconf-cms_flickr: unquote('"\\e94e"');
$iconf-flickr: unquote('"\\e94e"');
$iconf-line_facebook: unquote('"\\e900"');
$iconf-line_flickr: unquote('"\\e901"');
$iconf-line_googleplus: unquote('"\\e902"');
$iconf-error: unquote('"\\e903"');
$iconf-line_pinterest: unquote('"\\e904"');
$iconf-line_twitter: unquote('"\\e905"');
$iconf-line_youtube: unquote('"\\e906"');
$iconf-arrows_down: unquote('"\\e907"');
$iconf-arrows_left: unquote('"\\e908"');
$iconf-arrows_right: unquote('"\\e909"');
$iconf-arrows_up: unquote('"\\e90a"');
$iconf-close: unquote('"\\e90b"');
$iconf-cross: unquote('"\\e90c"');
$iconf-tick: unquote('"\\e90d"');
$iconf-cart: unquote('"\\e90e"');
$iconf-marker: unquote('"\\e90f"');
$iconf-search: unquote('"\\e910"');
$iconf-enlarge: unquote('"\\e911"');
$iconf-calendar: unquote('"\\e912"');
$iconf-chat: unquote('"\\e913"');
$iconf-delete: unquote('"\\e914"');
$iconf-edit: unquote('"\\e915"');
$iconf-download: unquote('"\\e916"');
$iconf-drivingDistance: unquote('"\\e917"');
$iconf-info: unquote('"\\e918"');
$iconf-plus: unquote('"\\e919"');
$iconf-minus: unquote('"\\e91a"');
$iconf-cms_image: unquote('"\\e91b"');
$iconf-image: unquote('"\\e91b"');
$iconf-cms_photo: unquote('"\\e91c"');
$iconf-photo: unquote('"\\e91c"');
$iconf-print: unquote('"\\e91d"');
$iconf-share: unquote('"\\e91e"');
$iconf-email: unquote('"\\e91f"');
$iconf-speechBubble: unquote('"\\e920"');
$iconf-cms_star: unquote('"\\e921"');
$iconf-star: unquote('"\\e921"');
$iconf-cms_tripAdvisor: unquote('"\\e922"');
$iconf-tripAdvisor: unquote('"\\e922"');
$iconf-Tripadvisor_blank: unquote('"\\e923"');
$iconf-Tripadvisor_full: unquote('"\\e924"');
$iconf-cms_twitter: unquote('"\\e925"');
$iconf-twitter: unquote('"\\e925"');
$iconf-cms_facebook: unquote('"\\e926"');
$iconf-facebook: unquote('"\\e926"');
$iconf-cms_googleplus: unquote('"\\e927"');
$iconf-googleplus: unquote('"\\e927"');
$iconf-cms_pinterest: unquote('"\\e928"');
$iconf-pinterest: unquote('"\\e928"');
$iconf-cms_youtube: unquote('"\\e929"');
$iconf-youtube: unquote('"\\e929"');
$iconf-cms_blog: unquote('"\\e92a"');
$iconf-blog: unquote('"\\e92a"');
$iconf-cms_linkedin: unquote('"\\e92b"');
$iconf-linkedin: unquote('"\\e92b"');
$iconf-line_print: unquote('"\\e92c"');
$iconf-cms_youku: unquote('"\\e92d"');
$iconf-youku: unquote('"\\e92d"');
$iconf-line_youku: unquote('"\\e92d"');
$iconf-line_tumblr: unquote('"\\e92e"');
$iconf-cms_articCenter: unquote('"\\e92f"');
$iconf-articCenter: unquote('"\\e92f"');
$iconf-cms_events: unquote('"\\e930"');
$iconf-events: unquote('"\\e930"');
$iconf-cms_expeditions: unquote('"\\e931"');
$iconf-expeditions: unquote('"\\e931"');
$iconf-line_linkedin: unquote('"\\e932"');
$iconf-cms_rentalSkoter: unquote('"\\e933"');
$iconf-rentalSkoter: unquote('"\\e933"');
$iconf-cms_rentalCycle: unquote('"\\e934"');
$iconf-rentalCycle: unquote('"\\e934"');
$iconf-link: unquote('"\\e935"');
$iconf-cms_rentalCar: unquote('"\\e936"');
$iconf-rentalCar: unquote('"\\e936"');
$iconf-rentals: unquote('"\\e936"');
$iconf-cms_OvernightCruise: unquote('"\\e937"');
$iconf-OvernightCruise: unquote('"\\e937"');
$iconf-overnightCruise: unquote('"\\e937"');
$iconf-cms_Cruise: unquote('"\\e938"');
$iconf-Cruise: unquote('"\\e938"');
$iconf-cruise: unquote('"\\e938"');
$iconf-ferry: unquote('"\\e938"');
$iconf-cms_Coach: unquote('"\\e939"');
$iconf-Coach: unquote('"\\e939"');
$iconf-coach: unquote('"\\e939"');
$iconf-cms_HeliQ: unquote('"\\e93a"');
$iconf-HeliQ: unquote('"\\e93a"');
$iconf-heli: unquote('"\\e93a"');
$iconf-cms_Flight: unquote('"\\e93b"');
$iconf-Flight: unquote('"\\e93b"');
$iconf-flight: unquote('"\\e93b"');
$iconf-fly: unquote('"\\e93b"');
$iconf-plane: unquote('"\\e93b"');
$iconf-cms_GlowwormCaves: unquote('"\\e93c"');
$iconf-GlowwormCaves: unquote('"\\e93c"');
$iconf-glowwornCaves: unquote('"\\e93c"');
$iconf-caves: unquote('"\\e93c"');
$iconf-cms_Tour: unquote('"\\e93d"');
$iconf-Tour: unquote('"\\e93d"');
$iconf-tour: unquote('"\\e93d"');
$iconf-cms_Dining: unquote('"\\e93e"');
$iconf-Dining: unquote('"\\e93e"');
$iconf-dining: unquote('"\\e93e"');
$iconf-bbq: unquote('"\\e93e"');
$iconf-picnic: unquote('"\\e93e"');
$iconf-cms_Accommodation: unquote('"\\e93f"');
$iconf-Accommodation: unquote('"\\e93f"');
$iconf-accommodation: unquote('"\\e93f"');
$iconf-cms_Rafting: unquote('"\\e940"');
$iconf-Rafting: unquote('"\\e940"');
$iconf-rafting: unquote('"\\e940"');
$iconf-cms_Cycling: unquote('"\\e941"');
$iconf-Cycling: unquote('"\\e941"');
$iconf-cycling: unquote('"\\e941"');
$iconf-cms_HorseTrek: unquote('"\\e942"');
$iconf-HorseTrek: unquote('"\\e942"');
$iconf-horseTrek: unquote('"\\e942"');
$iconf-horse: unquote('"\\e942"');
$iconf-horsetrek: unquote('"\\e942"');
$iconf-cms_DayWalk: unquote('"\\e943"');
$iconf-DayWalk: unquote('"\\e943"');
$iconf-dayWalk: unquote('"\\e943"');
$iconf-walk: unquote('"\\e943"');
$iconf-walks: unquote('"\\e943"');
$iconf-cms_FarmTour: unquote('"\\e944"');
$iconf-FarmTour: unquote('"\\e944"');
$iconf-farmTour: unquote('"\\e944"');
$iconf-farmtour: unquote('"\\e944"');
$iconf-cms_kayak: unquote('"\\e945"');
$iconf-kayak: unquote('"\\e945"');
$iconf-kayaking: unquote('"\\e945"');
$iconf-cms_weibo: unquote('"\\e946"');
$iconf-weibo: unquote('"\\e946"');
$iconf-line_weibo: unquote('"\\e946"');
$iconf-cms_link-external: unquote('"\\e947"');
$iconf-link-external: unquote('"\\e947"');
$iconf-cms_tumblr: unquote('"\\e948"');
$iconf-tumblr: unquote('"\\e948"');
$iconf-line_wechat: unquote('"\\e949"');
$iconf-cms_wechat: unquote('"\\e94a"');
$iconf-wechat: unquote('"\\e94a"');
$iconf-cms_instagram: unquote('"\\e94b"');
$iconf-line_instagram: unquote('"\\e94b"');
$iconf-cms_bike: unquote('"\\e94c"');
$iconf-bike: unquote('"\\e94c"');
$iconf-cms_kiwi: unquote('"\\e94d"');
$iconf-kiwi: unquote('"\\e94d"');
$iconf-map: unquote('"\\e958"');
$iconf-phone: unquote('"\\e959"');
$iconf-star1: unquote('"\\e95a"');
$iconf-star-line: unquote('"\\e95a"');
$iconf-cms_usp-clock: unquote('"\\e957"');
$iconf-usp-clock: unquote('"\\e957"');
$iconf-cms_usp-Family: unquote('"\\e95b"');
$iconf-usp-Family: unquote('"\\e95b"');
$iconf-cms_usp-romantic: unquote('"\\e95c"');
$iconf-usp-romantic: unquote('"\\e95c"');
$iconf-cms_usp-Expert: unquote('"\\e95d"');
$iconf-usp-Expert: unquote('"\\e95d"');
$iconf-cms_usp-SSL: unquote('"\\e95e"');
$iconf-usp-SSL: unquote('"\\e95e"');
$iconf-cms_usp-help: unquote('"\\e95f"');
$iconf-usp-help: unquote('"\\e95f"');
$iconf-Flag_zh-cn: unquote('"\\e960"');
$iconf-Flag_jp: unquote('"\\e961"');
$iconf-Flag_en: unquote('"\\e962"');
$iconf-cms_best-price: unquote('"\\e963"');
$iconf-best-price: unquote('"\\e963"');
$iconf-cms_reservations: unquote('"\\e964"');
$iconf-reservations: unquote('"\\e964"');
$iconf-cms_lock: unquote('"\\e965"');
$iconf-lock: unquote('"\\e965"');
$iconf-cms_familyOwned: unquote('"\\e966"');
$iconf-familyOwned: unquote('"\\e966"');
$iconf-cms_awards: unquote('"\\e967"');
$iconf-awards: unquote('"\\e967"');
$iconf-cms_refund: unquote('"\\e968"');
$iconf-refund: unquote('"\\e968"');
$iconf-cms_pioneers: unquote('"\\e969"');
$iconf-pioneers: unquote('"\\e969"');
$iconf-cms_xiaohongshu: unquote('"\\e96b"');
$iconf-Xiaohongshu: unquote('"\\e96b"');
$iconf-line_xiaohongshu: unquote('"\\e96b"');


// RJ.COMPONENT.HERO-GALLERY

$hero-gallery-bp: $desktop;

.c-hero-gallery {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: $white;
		display: block;
	}
}

.c-hero-gallery__viewport {
	// TODO
}

.c-hero-gallery__item {
	position: relative;

	&[aria-hidden="true"] {
		display: none;
	}
}

.c-hero-gallery__image {
	display: block;
	width: 100%;
	max-width: 100%;
}

// Important has to be used to override the JS rotator libray (Slick)
// TODO - Not using Slick
.c-hero-gallery__image--small {

	@media ($large-mobile) {
		display: none !important;
	}
}

.c-hero-gallery__image--large {
	display: none !important;

	@media ($large-mobile) {
		display: block !important;
	}
}

.c-hero-gallery__body {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 20px 10px;

	@media ($hero-gallery-bp) {
		padding-bottom: 120px;
		justify-content: flex-end;
	}

	.c-hero-gallery__item--right & {
		text-align: right;
	}
}

.c-hero-gallery__tag {
	position: static;
	margin-bottom: 10px;
}

.c-hero-gallery__subtitle,
.c-hero-gallery__title {
	width: 100%;

	@media ($hero-gallery-bp) {
		padding-right: 250px;

		.c-hero-gallery__item--right & {
			padding-right: 0;
			padding-left: 250px;
		}
	}
}

.c-hero-gallery__subtitle {
	display: inline-block;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	color: $white;
	text-shadow: 0 2px 4px rgba($black, 0.5);
}

.c-hero-gallery__title {
	display: block;

	font-size: 30px;
	font-weight: bold;
	text-shadow: 0 2px 4px rgba($black, 0.5);
	line-height: 32px;
	color: $white;

	@media ($hero-gallery-bp) {
		font-size: 68px;
		line-height: 68px;
	}
}

.c-hero-gallery__button {
	display: none;

	@media ($hero-gallery-bp) {
		display: inline-block;
		margin-top: 25px;
	}
	@media ($desktop-large) {
		margin-bottom: 160px;
	}
}

.c-hero-gallery__caption {
	display: none;

	@media ($hero-gallery-bp) {
		position: absolute;
		right: 60px;
		bottom: 6px;
		display: block;
		max-width: 240px;
		padding-left: 20px;
		color: $white;
		font-size: 12px;
		text-shadow: 0 2px 4px rgba($black, 0.5);
		line-height: 14px;

		.c-hero-gallery__item--right & {
			text-align: left;
			left: 60px;
			right: auto;
		}
	}
}

.c-hero-gallery__caption-icon {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	display: block;
	font-size: 12px;
}

.c-hero-gallery__caption-link {
	color: $white;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

////////////////
// Thumbnails //
////////////////
.c-hero-gallery__thumbnail-list {
	@include list-reset;
	display: flex;
	align-items: stretch;

	justify-content: center;
	@media ($hero-gallery-bp) {
		margin-top: -80px;
		justify-content: flex-end;
	}
}

.c-hero-gallery__thumbnail-item {
	background-color: $white;

	@media ($desktop) {
		width: 220px;
	}

	position: relative;
	isolation: isolate;
}

.c-hero-gallery__thumbnail-button {
	@include btn-reset;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;

	&::after {
		content: "";
		width: 10px;
		height: 10px;
		border-radius: 10px;

		background: $grey--dark;
	}
	&[aria-current="true"]::after {
		background: $charcoal--dark;
	}

	@media ($desktop) {
		padding: 0;
		display: block;

/*		width: auto;
		height: auto;*/width: 100%;
					   height: 100%;

		&::after {
			display: none;
		}
		&[aria-current="true"] {
			&::before {
				content: "";
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				box-shadow: inset 0 0 0 3px $white;
			}

			.c-hero-gallery__thumbnail-image {
				opacity: 0.5;
			}
		}
	}
}

.c-hero-gallery__thumbnail-figure {
	display: none;
	@media ($hero-gallery-bp) {
		display: block;
		width: 100%;
	}
}

.c-hero-gallery__thumbnail-image {
	display: block;
	width: 100%;
}

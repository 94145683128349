// CONTROL.IMAGE-CAROUSEL

.c-image-carousel__layout {
	padding: 0;
	margin: 0 20px;

	@media ($large-mobile) {
		padding: 0 100px;
	}
}

.c-image-carousel__list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -30px;
}

.c-image-carousel__item {
	padding-left: 30px;
}

.c-image-carousel__image {
	transition: transform 0.3s;
	// Don't change appearance of image, but make it transition smoothly to changing scale
	transform: scale(1.0001);
}

.c-image-carousel__slide-link {
	display: block;
	overflow: hidden;

	&:hover,
	&:focus {
		.c-image-carousel__image {
			transform: scale(1.05);
		}
	}
}

.c-image-carousel {

	.slick-arrow {
		bottom: auto;
		top: 50%;
		transform: translateY(-50%);
	}

	.slick-prev {
		// Add 30px to account for padding
		left: -60px + 30px;

		@media ($large-mobile) {
			left: -100px + 30px;
		}

		&:before {
			@include font-icon;
			content: $iconf-arrows_left;
		}
	}

	.slick-next {
		right: -60px;

		@media ($large-mobile) {
			right: -100px;
		}

		&:before {
			@include font-icon;
			content: $iconf-arrows_right;
		}
	}
}

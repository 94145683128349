// RJ.OBJECT.TAB

.o-tab {

	&.pure-container {
		padding: 0;

		&.pods {
			padding: 0 10px;
		}
	}
}

.o-tab__book-btn {
	display: block;
	padding: 14px 20px;
	border-radius: 3px;
	margin-top: 20px;
	font-size: 20px;
	font-weight: 700;
	text-decoration: none;
	color: $black;
	background-color: $green;
	transition: 0.2s ease;

	&,
	.body--content & {
		color: $black;

		&:focus,
		&:hover {
			color: $black;
			background-color: $green--dark;
		}
	}
}
﻿// RJ.COMPONENT.FINDER

.c-finder {

	@media ($desktop-large) {
		position: relative;
		display: flex;
		padding-right: 100px;
	}
}

.c-finder__field {
	padding: 13px 20px;
	border: 1px solid #8FA0A3;

	@media ($desktop-large) {
		width: 50%;
	}

	& + & {
		border-top: none;

		@media ($desktop-large) {
			border-top: 1px solid #8FA0A3;
			border-left: none;
		}
	}
}

.c-finder__label {
	display: block;
	margin-bottom: 10px;
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 17px;
}

.c-finder__dropdown {
	display: block;
	width: 100%;
	border: none;
	border-bottom: 1px solid $grey--ultralight;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	color: $black;
}

.c-finder__submit {
	@include btn-reset;
	width: 100%;
	padding: 7px;
	margin-top: 10px;
	font-size: 30px;
	background-color: $charcoal--dark;
	color: $white;

	@media ($desktop-large) {
		position: absolute;
		right: 0;
		top: 0;
		width: 100px;
		height: 100%;
		margin: 0;
	}
}
﻿// RJ.COMPONENT.WELCOME

.c-welcome {
	width: 100%;
	margin-bottom: 25px;

	@media ($tablet-plus) {
		width: calc(50% - 20px);
	}
}

.c-welcome__heading {
	margin: 0 0 0.5em;
	font-size: 40px;
	font-weight: 800;
	line-height: 1;
	color: $charcoal--dark;

	@media ($desktop) {
		font-size: 68px;
	}

	strong {
		color: $grey--dark;
	}
}

.c-welcome__text {
	&, > p {
		margin: 0 0 15px;
		font-size: 15px;
		font-weight: 400;
		line-height: 26px;

		@media ($desktop) {
			margin-bottom: 25px;
			font-size: 20px;
			line-height: 30px;
		}
	}

	> p {
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
// RJ.CONTROL.SELLING-POINTS

.c-key-selling-points__body {
	position: relative;
	width: 100%;
	border-top: 1px solid $grey--light;
	background: $grey--ultralight;

	@media ($desktop) {
		border-top: none;
		background: linear-gradient($white 0%, $grey--light 100%);
	}
}

.c-key-selling-points__list {
	max-width: 1800px;
	margin: 0 auto;

	@media ($desktop) {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 20px;
	}
}

.c-key-selling-points__item-wrapper {

	@media ($desktop) {
		width: 100%;
	}
}

	.c-key-selling-points__item {
		display: flex;
		align-items: center;
		justify-content: center;

		@media ($below-desktop) {
			position: relative;
			padding: 20px;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				border-right: 1px solid $grey--light;
			}
		}

		@media ($desktop) {
			flex-wrap: wrap;
			padding: 30px 20px;
		}

		@media($desktop-large) {
			flex-wrap: nowrap;
		}
	}

	.c-key-selling-points__icon {
		font-size: 50px;
		color: $charcoal--dark;
		margin-right: 20px;

		@media ($desktop) {
			margin-right: 0;
		}

		@media($desktop-large) {
			margin-right: 20px;
		}
	}

	.c-key-selling-points__text {
		font-size: 15px;
		line-height: 20px;

		@media($desktop-large) {
			width: 100%;
		}
	}

	.c-key-selling-points {
		@media ($below-desktop) {
			padding-bottom: 60px;

			.slick-dots {
				position: absolute;
				top: 100%;
				margin-top: 10px;
			}

			.slick-arrow {
				position: absolute;
				top: 100%;
				bottom: auto;
				margin-top: 10px;
				background: transparent;
			}
		}
	}

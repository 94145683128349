// RJ.CONTROL.POD-CAROUSEL

.c-pod-carousel {
	margin: 40px 0;
	@media ($desktop) {
		margin: 60px 0;
	}
}

.c-pod-carousel__layout {
	padding: 0;
	margin: 0 20px;

	@media ($large-mobile) {
		padding: 0 100px;
	}
}

.c-pod-carousel__introduction {
	margin-bottom: 20px;
}

.c-pod-carousel__list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -30px;
}

.c-pod-carousel__item {
	padding-left: 30px;
	margin: 20px; // So the shadow shows in slick
}

.c-pod-carousel__slide-link {
	display: block;
	overflow: hidden;
}

.c-pod-carousel {

	.slick-arrow {
		bottom: auto;
		top: 50%;
		transform: translateY(-50%);
	}

	.slick-prev {
		// Add 30px to account for padding
		left: -60px + 30px;

		@media ($large-mobile) {
			left: -100px + 30px;
		}
	}

	.slick-next {
		right: -60px;

		@media ($large-mobile) {
			right: -100px;
		}
	}
}

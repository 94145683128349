// RJ.OBJECT.PATHWAY

.o-pathway {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	&, .body--content & {
		color: $black;
	}
	background-color: $white;
	box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
}


a.o-pathway,
a.o-pathway__body {
	transition: all 0.3s ease;

	&:hover,
	&:focus {
		&, .body--content & {
			text-decoration: none;
		}
		@media ($desktop) {
			transform: translateY(-3px);
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
		}

		&,
		~ .o-pathway__cta-area {
			.o-pathway__cta:not(.o-pathway__cta--secondary) {
				background: $charcoal--dark;
				&, .body--content & {
					color: $white;
				}
			}
		}
	}
}

.o-pathway {
	&.c-filtered-pod {
		transition: all 0.3s ease;

		&:hover,
		&:focus {
			@media ($desktop) {
				transform: translateY(-3px);
				box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
			}

			.o-pathway__cta:not(.o-pathway__cta--secondary) {
				background: $charcoal--dark;

				&, .body--content & {
					color: $white;
				}
			}
		}
	}
}

.o-pathway__figure {
	width: 100%;
}

.o-pathway__category {
	display: inline-block;
	position: absolute;
	left: -10px;
	top:28px;
	padding: 5px;
	border-radius: 2px;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	color: $rj-red;
	background-color: $white;
	box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
}

.o-pathway__body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-bottom: 20px;
}

.o-pathway__body--exp {
	padding-bottom: 45px;
}

.o-pathway__body-text {
	padding: 10px 20px 20px;
	padding-bottom: 0;

	ul, ol {
		padding-left: 15px;
	}

	em {
		font-style: italic;
	}
}

.c-filtered-pod a.o-pathway__body-top {
	.body--content & {
		&:hover,
		&:focus-visible {
			text-decoration: none;
		}
	}
}

.o-pathway__heading {
	text-align: center;
	display: block;
	padding: 20px;
	padding-bottom: 0;
	font-size: 24px;
	font-weight: 600;
	line-height: 28px;
	color: $charcoal--dark;
}

.o-pathway__location {
	display: block;
	margin-bottom: 1em;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 11px;
	color: $black;
}

.o-pathway__pricing {
	font-size: 15px;
	line-height: 26px;
	color: $black;
	text-align: center;
}

.o-pathway__price {
	font-size: 28px;
	font-weight: 600;
}

.o-pathway__cta {
	position: absolute;
	left: 50%;
	bottom: -20px;
	display: inline-block;
	padding: 14px 20px;
	border: 1px solid $charcoal--dark;
	border-radius: 3px;
	font-size: 16px;
	line-height: 20px;
	white-space: nowrap;
	&, .body--content & {
		color: $charcoal--dark;
	}
	background-color: $white;
	box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.1);
	transform: translateX(-50%);
	transition: 0.3s all;
}

.o-pathway__cta-area {
	position: absolute;
	left: 50%;
	bottom: -20px;
	transform: translateX(-50%);

	display: flex;

	.o-pathway__cta {
		position: static;
		transform: none;

		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}

		&:hover,
		&:focus {
			background: $charcoal--dark;
			color: $white;
		}
	}
}

﻿// RJ.COMPONENT.BREADCRUMBS

.c-breadcrumbs {
	color: $charcoal--dark;
	font-weight: 600;
	font-size: 11px;
}

.c-breadcrumbs__link {
	color: $blue--lake;
	&:hover,
	&:focus-visible {
		text-decoration: underline;
	}
}
.ajax-container {
	position: relative;

	&.is-loading {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba($white, 0.8);
		}

		&:after {
			// TODO: Update content with loading gif
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 2;
			transform: translate(-50%, -50%);
			display: inline-block;
		}
	}

	.filters + & {
		min-height: 450px;
	}
}
